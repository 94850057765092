import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { Utility } from 'src/app/Utility/Utility';
import { RequestService } from 'src/app/service/requestService/request.service';
import { NameFunction } from './NameFunction';
import { NameParams } from './NameParams';

@Component({
  selector: 'app-access-web-sidi',
  templateUrl: './access-web-sidi.component.html',
  styleUrls: ['./access-web-sidi.component.scss']
})
export class AccessWebSidiComponent {

  //Variabile che contiene l'url del componente dove noi andremo a controllare quale funzione viene richiamata
  urlFunctionName : string = this.router.url;

  //Variabile che a seconda della funzione chiamata l'url di destinazione
  urlForRedirect : string;

  //Variabili user e token che serviranno a effettuare il login in SSO se la sessione è scaduta
  user : string;
  token : string;

  //Variabili utilizzate in base alla funzione chiamata da webSidi
  codReferente : number;
  codTipoReferente : number;
  numeroDocumento : number;
  annoDocumento : number;


  constructor(private activateRoute : ActivatedRoute, private requestService : RequestService, private router : Router) {

    this.urlFunctionName = this.router.url;
    this.activateRoute.paramMap.subscribe(params => {
      
      if(params.get(NameParams.USER) && params.get(NameParams.TOKEN))
      {
        this.user = params.get(NameParams.USER);
        this.token = params.get(NameParams.TOKEN);
      }

      /**
       * Viene controllato se nell'url è presente il nome della funzione (descritte nell'enum NameFunction)
       * cosi da capire quali parametri e quale url costruire per il redirect al componente
       */
      if(this.urlFunctionName.includes(NameFunction.DOC_REFERENTI))
      {
        if(params.get(NameParams.ID_SOGGETTO) && params.get(NameParams.ID_TIPO_SOGGETTO)){
          this.codReferente = Number.parseInt(params.get(NameParams.ID_SOGGETTO));
          this.codTipoReferente = Number.parseInt(params.get(NameParams.ID_TIPO_SOGGETTO));
          this.urlForRedirect = "#/modulo/ricerche/ricercaDocumentiPerReferente/" + this.codTipoReferente + "/" + this.codReferente;
        }
      }
      else if(this.urlFunctionName.includes(NameFunction.OPEN_DOCUMENT))
      {
        if(params.get(NameParams.ANNO_DOCUMENTO) && params.get(NameParams.NUMERO_DOCUMENTO)){
          this.numeroDocumento = Number.parseInt(params.get(NameParams.NUMERO_DOCUMENTO));
          this.annoDocumento = Number.parseInt(params.get(NameParams.ANNO_DOCUMENTO));
          this.urlForRedirect = "#/modulo/ricerche/ricercaDocumenti/" + this.numeroDocumento + "/" + this.annoDocumento;
        }
      }

      this.checkSessionAndRedirect();
    });
  }

  /**
   * Metodo che controlla se la sessione è presente.
   * Viene prima controllata se è presente un utente sessione nel localstorage, se presente viene fatta una
   * chiamata al checkSession per verificare se questa sessione è ancora valida.
   * Se la sessione è valida allora viene fatto un redirect al componente in base alla funzione chiamata,
   * in caso contrario non valida allora viene fatto il redirect al LoginComponent passando token, user e l'url
   * per il redirect al componente richiesto
   */
  checkSessionAndRedirect() : void {

      if(Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE))
      {
        this.requestService.postRequest("checkSession", {}, this.redirectFunction, this.redirectLoginSsoWebSidi);
      }
      else
      {
        this.redirectLoginSsoWebSidi();
      }
  }

  /**
   * Metodo che viene richiamato se il server alla chiamata checkSession risponde in modo affermativo
   * @param response
   */
  redirectFunction = (response : any) : void => {
    window.location.href = this.urlForRedirect;
  }

  /**
   * Metodo che salva nel localstorage l'url che serve poi al LoginComponent per fare il redirect
   * al componente desiderato dopo aver fatto il login in SSO
   */
  redirectLoginSsoWebSidi = () : void => {
    Utility.localStorageSet(StaticProperty.REDIRECT_AFTER_LOGIN, this.urlForRedirect );
   if(this.urlFunctionName.includes(NameFunction.CAS_SSO))
      window.location.href = "#/casSso/" + encodeURIComponent(this.token);
   else 
      window.location.href = "#/webSidi/sso/" + this.user + "/" + encodeURIComponent(this.token);
    
    
  }

}

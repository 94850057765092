import { AbstractControl, FormArray, FormControl } from "@angular/forms";
import { ElencoAgdUoModalComponent } from "../component/sub-component/elenco-agd-uo-modal/elenco-agd-uo-modal.component";
import { ElencoAgdUoPerUtenteComponent } from "../component/sub-component/elenco-agd-uo-per-utente/elenco-agd-uo-per-utente.component";
import { ModalComponent } from "../component/sub-component/modal/modal.component";
import { OptionModal } from "../component/sub-component/modal/OptionModal";
import { ObjCompetenza } from "../model/ObjCompetenza";
import { RequestriferimentiForm } from "../model/RequestRiferimentiForm";
import { GlobalService } from "../service/globalService/global.service";
import { RequestService } from "../service/requestService/request.service";
import { getLabelAgd, getLabelUo, Utility } from "./Utility";

export class UtilityCompetenze {

  getLabelAgd : () => string = getLabelAgd;
  getLabelUo : () => string = getLabelUo;

  dynamicModal: ModalComponent;
  optionModal: OptionModal;

  listAgdUoToSearch : any = [];
  requestService : RequestService

  constructor(dynamicModal: ModalComponent, optionModal: OptionModal, requestService : RequestService) {
    this.dynamicModal = dynamicModal;
    this.optionModal = optionModal;
    //this.listAgdUoScelti = listAgdUoScelti;
    this.requestService = requestService;

    this.getListAgdUo();
  }

  checkChangeDisableAgdUoCognome(agd : AbstractControl, uo : AbstractControl, cognome : AbstractControl) : void{
    agd.valueChanges
    .subscribe(value => {
      if(!agd.disabled)
      {
        if(value != "")
        {
          cognome.disable();
        }
        else if(uo.value == "")
        {
          cognome.enable();
        }
      }
    });


    uo.valueChanges
    .subscribe(value => {

      if(!uo.disabled)
      {
        if(value != "")
        {
          cognome.disable();
        }
        else if(agd.value == "")
        {
          cognome.enable();
        }
      }
    });
  }

  getListAgdUo()
  {
    this.requestService.postRequest("getElencoAgdUo", {},
      (response) => {
        this.listAgdUoToSearch = response.obj.list;
      }
    );
  }

  /**
   *
   * Metodo utilizzato per inizializzare e aprire la modale
   * con la lista degli agd uo da scegliere (questo avviene per utente)
   */
  openListAgdUoPerUtente(list: any, addAgdUoScelto : (agdUo : any, close?: boolean) => void): void {
    this.optionModal = new OptionModal();

    this.optionModal.titolo = "Scegli " + getLabelAgd() + " - " + getLabelUo();
    this.optionModal.setSizeModal("40");
    this.optionModal.showSaveButton = false;
    this.optionModal.setClassSizeMaxHeightModalBody("l");

    //Opzioni per settare il component da caricare dinamicamente
    //******************************************************* */
    this.optionModal.setLoadDynamicComponent(true);
    this.optionModal.dynamicComponnet = ElencoAgdUoPerUtenteComponent;
    this.optionModal.objParameterComponent = {
      listAgdUo: list,
      addAgdUoScelto: addAgdUoScelto,
    };

    this.dynamicModal.initModal(this.optionModal);
    this.dynamicModal.openModal();
  }

  checkListVerificaAgdUoCognome(listAgdUoScelti : any[], listTemp: any, competenza: string, competenze: FormArray,
    resetField: () => void,
    addAgdUoScelto : (agdUo : any, close?: boolean) => void
    ) {
    competenze.markAsDirty();
    if (listTemp.length == 1) {
      let elementoDaAggiungere = listTemp[0];

      elementoDaAggiungere[
        competenza == "competenza" ? "activeC" : "activeCC"
      ] = true;
      //devo controllare se è già presente nella lista

      let listElementoTrovato = listAgdUoScelti.filter((element) => {
        return (
          element.codicePadre == elementoDaAggiungere.codicePadre &&
          element.codice == elementoDaAggiungere.codice
        );
      });

      if (listElementoTrovato.length == 0) {
        listAgdUoScelti.push(elementoDaAggiungere);
      } else {
        this.optionModal = new OptionModal();
        this.optionModal.titolo = "Attenzione";
        this.optionModal.textBodyModal =
          "Elemento già presente tra le competenze";

        this.optionModal.showSaveButton = false;
        this.optionModal.showCloseButton = true;
        this.optionModal.setClassSizeMaxHeightModalBody("m");

        this.dynamicModal.initModal(this.optionModal);
        this.dynamicModal.openModal();
      }
    } else if (listTemp.length > 1) {
      //Apre modale con lista dei risultati trovati
      this.openListAgdUoPerUtente(listTemp, addAgdUoScelto);
    } else {
      this.optionModal = new OptionModal();
      this.optionModal.titolo = "Attenzione";
      this.optionModal.textBodyModal =
      "VALORE "+ getLabelAgd().toUpperCase() +" NON TROVATO OPPURE "+ getLabelUo().toUpperCase() +" NON ABILITATA AI FLUSSI DOCUMENTALI";

      this.optionModal.showSaveButton = false;
      this.optionModal.showCloseButton = true;
      this.optionModal.setClassSizeMaxHeightModalBody("m");

      this.dynamicModal.initModal(this.optionModal);
      this.dynamicModal.openModal();
    }

    this.convertListAgdUoSceltiToCompetenzeForm(listAgdUoScelti, competenze);

    resetField();
  }

  /**
   * Metodo che al submit converte la lista degli agduo scelti e li inserisce all'interno del form
   *
   * (viene fatto al submit per facilità di gestione della lista)
   */
  convertListAgdUoSceltiToCompetenzeForm(listAgdUoScelti : any[], competenze : FormArray): void {
    competenze.clear();
    listAgdUoScelti.forEach((element) => {
      let obj = Utility.deepCopyObject(element);
      if (element.hasOwnProperty("children")) {
        obj.codicePadre = element.codice;
        obj.codice = -1;
      }

      competenze.push(new FormControl(obj));
    });
  }




  /**
   *
   * Metodo utilizzato per inizializzare e aprire la modale
   * con la lista degli agd uo da scegliere
   */
    openListAgdUo(listAgdUoScelti : any[], addAgdUoScelto : (agdUo : any, close?: boolean) => void ) : void{
        this.optionModal = new OptionModal();

        this.optionModal.titolo = "Scegli " + getLabelAgd() + " - " + getLabelUo();
        this.optionModal.setSizeModal("lg");
        this.optionModal.showSaveButton = false;
        this.optionModal.setClassSizeMaxHeightModalBody("l");

        //Opzioni per settare il component da caricare dinamicamente
        //******************************************************* */
        this.optionModal.setLoadDynamicComponent(true);
        this.optionModal.dynamicComponnet = ElencoAgdUoModalComponent;
        this.optionModal.objParameterComponent = {
            addAgdUoScelto : addAgdUoScelto,
            listAgdUoScelti : listAgdUoScelti
        }
        //******************************************************* */


        this.dynamicModal.initModal(this.optionModal);
        this.dynamicModal.openModal();
    }

    cercaPerCognome(listAgdUoScelti : any[], competenza : string, cognome : AbstractControl, competenze : FormArray,
      resetField : ()=> void,
      addAgdUoScelto : (agdUo : any, close?: boolean) => void
      ){
        let listCognome = [];
        let listTemp = [];
        this.requestService.postRequest("getElencoAgdUoPerCognome", {cognome : cognome.value},
         (success) => {
           listCognome = success.obj.list;
           listCognome.forEach(element => {
            listTemp.push({
              abilitazioneFlussi: "",
              activeCC: competenza != "competenza",
              activeC: competenza == "competenza",
              codice: element.uoCodice,
              codicePadre: element.agdCodice,
              descrizionePadre: element.agdDescrizione + " (" + element.agdCodice +")",
              descrizioneRidotta: element.uoDescrizione,
              descrizioneUtente : element.utenti.length > 0 ? ((element.utenti[0].nome != null ? element.utenti[0].nome + " " : "") + (element.utenti[0].cognome != null ? element.utenti[0].cognome : "")) : null,
              principale: false
            })
          });


          listAgdUoScelti.forEach(element => {
            listTemp = listTemp.filter(x => {
              return !(x.codicePadre == element.codicePadre && x.codice == element.codice)
            });
          });
          this.checkListVerificaAgdUoCognome(listAgdUoScelti, listTemp, competenza, competenze, resetField, addAgdUoScelto);
         }
       );

      }




    /**
   * Metodo che verifica lato client se esiste un agd uo con i parametri selezionati
   * una volta fatta la ricerca se andata a buon segno, inserisce nella lista la competenza, altrimenti
   * manda un alert dicendo che non esiste
   */
     verificaCompetenzaConoscenza(listAgdUoScelti : any[], objRequest : RequestriferimentiForm, cognome : AbstractControl, competenze : FormArray,
      resetField : ()=> void,
      addAgdUoScelto : (agdUo : any, close?: boolean) => void
      ){

        let listTemp = [];

        if(objRequest.agd || objRequest.uo)
        {
          if(this.checkFieldIsEmptyOrZero(objRequest.uo)) //caso in cui solamente l'agd è settato
          {
            if(isNaN((objRequest.agd as number))){ //se il campo è una descrizione e non un number
              listTemp = this.filterDescrAgdUoFromList(this.listAgdUoToSearch, "descrizione", (objRequest.agd as string));
            }
            else
            {
              listTemp = this.filterAgdUoFromList(this.listAgdUoToSearch, (objRequest.agd as number));
            }
          }
          else if(this.checkFieldIsEmptyOrZero(objRequest.agd) && !this.checkFieldIsEmptyOrZero(objRequest.uo)){ //caso in cui invece solamente la uo è settata

            this.listAgdUoToSearch.forEach(element => {

              let temp = []
              if(isNaN((objRequest.uo as number))){ //se il campo è una descrizione e non un number
                temp = this.filterDescrAgdUoFromList(element.children, "descrizioneRidotta",  (objRequest.uo as string));
              }
              else
              {
                temp = this.filterAgdUoFromList(element.children, (objRequest.uo as number));
              }
              temp.forEach(x => {
                x.descrizionePadre = element.descrizione + " (" + element.codice + ")";
                x.codicePadre = element.codice;
                x[(objRequest.competenza == "competenza" ? "activeC" : "activeCC")] = true;
              });
              listTemp.push(...temp);
            });

            listAgdUoScelti.forEach(element => {
              listTemp = listTemp.filter(x => {
                return !(x.codicePadre == element.codicePadre && x.codice == element.codice)
              });
            });
          }
          else // caso in cui sono entrambi settati
          {
            let agdPadre = isNaN((objRequest.agd as number)) ?
            this.filterDescrAgdUoFromList(this.listAgdUoToSearch, "descrizione", (objRequest.agd as string))[0]
            : this.listAgdUoToSearch.filter(element=>{return element.codice == objRequest.agd})[0];

            if(isNaN((objRequest.uo as number)))
            {
              listTemp = this.filterDescrAgdUoFromList(agdPadre.children, "descrizioneRidotta",  (objRequest.uo as string));
            }
            else
            {
              listTemp = this.filterAgdUoFromList(agdPadre.children, (objRequest.uo as number));
            }

            listTemp.forEach(element => {
              element.descrizionePadre = agdPadre.descrizione + " (" + agdPadre.codice + ")";
              element.codicePadre = agdPadre.codice;
            })
          }
        }
        else if(objRequest.cognome != "")
        {
          this.cercaPerCognome(listAgdUoScelti, objRequest.competenza, cognome, competenze, resetField, addAgdUoScelto);
          return;
        }

        this.checkListVerificaAgdUoCognome(listAgdUoScelti, listTemp, objRequest.competenza, competenze, resetField, addAgdUoScelto);

      }


    /**
     * Funzione che controlla un determinato campo passato come parametro e ti dice se è 0 o stringa vuota
     *
     * @param field
     * @returns
     */
    checkFieldIsEmptyOrZero(field : string | number){
      return (field == 0 || field == "");
    }


  /**
   * Funzione che filtra per la descrizione di agd o uo a seconda dei campi passati
   *
   * @param list    : Lista agd uo
   * @param field   : Campo della lista per cu filtrare
   * @param descr   : parametro utilizzato per il filtro
   * @returns
   */
    filterDescrAgdUoFromList(list : any, field : string, descr : string){
      return list.filter(element => {return element[field].toLowerCase()?.includes(descr.toLowerCase())})
    }

    filterAgdUoFromList(list : any, codeToCheck : number) : any{
        return list.filter(element => {return element.codice == codeToCheck});
    }

    filterCognomeFromList(list : any, cognome : string) : any{
        return list.filter(element => {return element.descrizioneRidotta.toLowerCase().includes(cognome)});
    }

    eliminaAgdUoScelto(listAgdUoScelti : any[], element : any, competenze : FormArray){
        element.activeC = false;
        element.activeCC = false;
        listAgdUoScelti = listAgdUoScelti.filter((x) => {return !(x.codicePadre == element.codicePadre && x.codice == element.codice)});
        competenze.markAsDirty();
        this.convertListAgdUoSceltiToCompetenzeForm(listAgdUoScelti, competenze);

        return listAgdUoScelti;
    }


addListCompetenzeOriginarie(listAgdUoScelti : any[], competenze : FormArray, elencoCC : any[], objCompetenza : ObjCompetenza) : void{
  //per prima cosa inserisco l'elemento per competenza nella list creandolo

  let competenza : any = {activeC : true};

  //generale
  if(objCompetenza.codUo == 0)
  {
    competenza.codice = objCompetenza.codAgd;
    competenza.descrizione = objCompetenza.descrizioneAgd;
    competenza.children = [{codicePadre : objCompetenza.codAgd}]
  }
  else
  {
    competenza.descrizionePadre = objCompetenza.descrizioneAgd + " (" + objCompetenza.codAgd + ")";
    competenza.codicePadre = objCompetenza.codAgd;
    competenza.codice = objCompetenza.codUo;
    competenza.descrizioneRidotta = objCompetenza.descrizioneUo;
  }

  listAgdUoScelti.push(competenza);

  elencoCC.forEach(element => {

    let conoscenza : any = {
      activeCC : true,
      codicePadre : element.codiceAgd,
      codice : (element.codiceUo == 0 ? element.codiceAgd : element.codiceUo),
    };

    if(element.codiceUo == 0)
    {
      conoscenza.descrizione = element.descrAgd;
      conoscenza.children = [{codicePadre : element.codiceAgd}]
    }
    else
    {
      conoscenza.descrizionePadre = element.descrAgd + " (" + element.codiceAgd + ")";
      conoscenza.descrizioneRidotta = element.descrUo;
    }

    listAgdUoScelti.push(conoscenza);
  });
    //this.convertListAgdUoSceltiToCompetenzeForm();
  this.convertListAgdUoSceltiToCompetenzeForm(listAgdUoScelti, competenze);
  }
}

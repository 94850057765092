import { Component, Input, OnInit, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { RequestService } from './../../../../../../service/requestService/request.service';
import { FascicoliRequest } from './../../../../../../model/FascicoliRequest';
import { hasCompetenza, Utility } from './../../../../../../Utility/Utility';
import { ResizeService } from './../../../../../../service/resize/resize.service';
import { NavigazioneComponent } from '../../navigazione.component';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';
import { ModalComponent } from 'src/app/component/sub-component/modal/modal.component';
import { PrimaryKeyFascicolo } from 'src/app/model/PrimaryKeyFascicolo';
import { OptionModal } from 'src/app/component/sub-component/modal/OptionModal';
import { ElencoMovimentiComponent } from 'src/app/component/moduli/elenchi/elenco-movimenti/elenco-movimenti.component';


@Component({
  selector: 'app-fascicoli',
  templateUrl: './fascicoli.component.html',
  styleUrls: ['./fascicoli.component.scss']
})
export class FascicoliComponent  implements OnInit {

  //****************************************************** */
  //Variabili ulitilizzate per dichiarare e inizializzare la modale
  //****************************************************** */
  @ViewChild('dynamicModalComponent')
  dynamicModal : ModalComponent;
  optionModal : OptionModal = new OptionModal();

  hasCompetenza : (fasc : any) => boolean = hasCompetenza;

  showTabFilter : boolean = false;

  @Input()
  openFormFascicolo : (nuovo : boolean, fasc : any) =>void;

  @Input()
  apriListaDocumenti: (args: any) => void;

  @Input()
  titolarioSelezionato : any;

  @Input()
  fascicoloIsSelezionato: () => boolean;

  @Input() riportaFascicolo : (objTitolarioFascicolo:any)=>void;

  @Output() openMovimentiFascicolo : EventEmitter<any> = new EventEmitter();

  @Input() forcedRiporta : boolean = false;

  objRequest : FascicoliRequest = new FascicoliRequest();

  searchInProgress : boolean = false;

  size : SCREEN_SIZE;

  //Variabili utilizzate per la paginazione
  //************************************* */
  totRecords : number;
  numPage : number = 0;
  currentPage : number = 1;
  numRow : number = 10;
  listObjPage : any = [];
  elementA : number;
  elementDa : number;
  //************************************* */

  persistentPagination : any = {};

  listFasc : any = [];

  static persistentObj : any = {};

  GLOBALI : GlobalObj;

  @Input()
  titolarioFromForm : FascicoliRequest;

  constructor(private resizeSvc: ResizeService,
    private requestService : RequestService,
    private router : Router,
    private globalService : GlobalService)
  {

    this.GLOBALI = this.globalService.GLOBAL;
    this.size = this.resizeSvc.onResize();
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }


  apriListaDocumentiChild(fascicolo){
    this.apriListaDocumenti(fascicolo);
  }

  cleanIdNumAnnoProt(numAnnoProt : string) : string
  {
    return Utility.cleanForAccordionNumAnnoProt(numAnnoProt);
  }


  ngOnInit(): void {
    this.objRequest.setFascicoliRequestFromString(this.titolarioSelezionato.valore);

    if(this.fascicoloIsSelezionato())
    {
      this.getPersistentObj();
    }
    else
    {
      this.getListaFascicoliFiltrata();
    }

    this.buildPaginationObject();
  }

  /**
   * Metodo utilizzato per resettare le variabili con i valori precedenti passando da una pagina all'altra
   */
  getPersistentObj() : void {
    this.listFasc = FascicoliComponent.persistentObj.list;
    this.objRequest = FascicoliComponent.persistentObj.objRequest;

    this.persistentPagination = FascicoliComponent.persistentObj.persistentPagination;

    this.numPage = this.persistentPagination.numPage;
    this.listObjPage = this.persistentPagination.listObjPage;
    this.elementA = this.persistentPagination.elementA;
    this.elementDa = this.persistentPagination.elementDa;
    this.totRecords = this.persistentPagination.totRecords;
    this.currentPage = this.persistentPagination.currentPage;
  }

  /**
   * Metodo utilizzato dalla paginazione per richiedere al servere la lista
   *
   * @param page : numero della pagina cliccata
   */
    changePage(page : number)
    {
      this.currentPage = page;
      this.getListaFascicoliFiltrata();

    }

       /**
   * Questo Metodo viene utilizzata per calcolare il numero di pagine che la lista ha calcolata
   * in base al numero di record della lista e al opzione di numero di righe selezionata dall'utente
   */
  buildPaginationObject()
  {
    this.numPage = Utility.calculateNumberOfPages(this.totRecords, this.numRow);
    this.listObjPage = Utility.buildPaginationObject(this.numPage, this.currentPage);
    this.elementA = (this.currentPage * this.numRow) - (this.numRow - this.listFasc.length);
    this.elementDa = (this.currentPage * this.numRow) - (this.numRow - 1);

  }

    /**
     * Metodo utilizzato per settare nell'oggetto di richiesta solo i campi relativi alla paginazione
     * essendo una cosa esterna ai filtri
    */
     buildDocRequestPaginationField()
     {
       this.objRequest.setNumRow(this.numRow);
       this.objRequest.setCurrentPage(this.currentPage);
     }


  getListaFascicoliFiltrata(){

    if(Utility.checkObj(this.titolarioFromForm) && this.titolarioFromForm.annoFascicolo != 0 && this.titolarioFromForm.numeroFascicolo != 0)
    {
      this.objRequest.numFascVis = this.titolarioFromForm.numFascVis;
      this.objRequest.annoFascicolo = this.titolarioFromForm.annoFascicolo;
    }

    this.buildDocRequestPaginationField();
    this.searchInProgress = true;
    this.requestService.postRequest("searchFascicoli", this.objRequest,
      (response) => {
        this.listFasc = response.obj.list;
        this.totRecords = response.obj.totRecords;

        this.buildPaginationObject();
        this.searchInProgress = false;

        FascicoliComponent.persistentObj.list = this.listFasc;
        FascicoliComponent.persistentObj.objRequest = this.objRequest;

        this.persistentPagination.numPage = this.numPage;
        this.persistentPagination.listObjPage = this.listObjPage;
        this.persistentPagination.elementA = this.elementA;
        this.persistentPagination.elementDa = this.elementDa;
        this.persistentPagination.totRecords = this.totRecords;
        this.persistentPagination.currentPage = this.currentPage;

        FascicoliComponent.persistentObj.persistentPagination = this.persistentPagination;



        /**
         * Apro direttamente il fascicolo nel caso in cui i valori del titolario inseriti danno
         * una sola voce di titolario.
         */
         if(NavigazioneComponent.autoNext && Utility.checkObj(this.listFasc) && this.listFasc.length == 1)
         {
           this.apriListaDocumentiChild(this.listFasc[0]);
         }


      },
      () => {
        this.searchInProgress = false;
        alert("qualcosa non va");
      }
    );
  }

  apriMovimentiFascicolo(fasc : any) {

    if(!this.modalMode)
    {
      this.optionModal = new OptionModal();

      this.optionModal.titolo = "Movimenti fascicolo (" + fasc.titolario + " / " + fasc.numFascVis + " / " + fasc.fascPK.annoFascicolo + ")";

      this.optionModal.setSizeModal("90");
      this.optionModal.maxSizeHeightModal = true;
      this.optionModal.showSaveButton = false;

      //Opzioni per settare il component da caricare dinamicamente
      //******************************************************* */
      this.optionModal.setLoadDynamicComponent(true);
      this.optionModal.dynamicComponnet = ElencoMovimentiComponent;
      this.optionModal.objParameterComponent = {
        fascicoloMode : true,
        fascicolo : {
          numTitolo : fasc.fascPK.numTitolo,
          annoFascicolo : fasc.fascPK.annoFascicolo,
          numeroFascicolo : fasc.fascPK.numFascicolo
        }
      }

      this.dynamicModal.initModal(this.optionModal);
      this.dynamicModal.openModal();
    }
    else{
      this.openMovimentiFascicolo.emit(fasc);
    }

  }

  get modalMode() : boolean
  {
    return NavigazioneComponent.modalMode;
  }

  riportaFascicoloLocal(fasc){
    let obj = {fascicolo : fasc, titolario : this.titolarioSelezionato};
    this.riportaFascicolo(obj);
  }

  /**
   * Metodo che riporta alla form del fascicolo per la modifica
   * @param fasc
   */
  modificaFascicolo(fasc : any) : void{

    let obj : FascicoliRequest = new FascicoliRequest();
    obj.setFascicoliRequestFromString(this.titolarioSelezionato.valore);
    obj.annoFascicolo = fasc.fascPK.annoFascicolo;
    obj.numeroFascicolo = fasc.fascPK.numFascicolo;

    this.router.navigateByUrl('/modulo/gestioneFascicoli/modificaFascicolo', { state : obj});
    if(this.modalMode)
    {
      ModalComponent.closeModal();
    }
  }

  trasmettiFascicolo(fasc : any) : void{

    let obj : PrimaryKeyFascicolo = new PrimaryKeyFascicolo({
      numeroFascicolo : fasc.fascPK.numFascicolo,
      annoFascicolo : fasc.fascPK.annoFascicolo,
      numTitolo : fasc.fascPK.numTitolo
    });

    this.router.navigateByUrl('/modulo/gestioneFascicoli/trasmettiFascicolo', { state : obj});
  }

  trasmettiAgliAtti(fasc : any) : void{

    let obj : PrimaryKeyFascicolo = new PrimaryKeyFascicolo({
      numeroFascicolo : fasc.fascPK.numFascicolo,
      annoFascicolo : fasc.fascPK.annoFascicolo,
      numTitolo : fasc.fascPK.numTitolo
    });

    this.router.navigateByUrl('/modulo/gestioneFascicoli/trasmettiAgliAtti', { state : obj});

  }



}

import { Component, Input, OnInit } from '@angular/core';
import { Fascicolo } from 'src/app/model/Fascicolo';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { RequestService } from 'src/app/service/requestService/request.service';

@Component({
  selector: 'app-lista-estratti',
  templateUrl: './lista-estratti.component.html',
  styleUrls: ['./lista-estratti.component.scss']
})
export class ListaEstrattiComponent implements OnInit {

  @Input() objParameterComponent : any;

  searchInProgress : boolean = true;
  listaFascicoliEstratti : Fascicolo[] = [];

  constructor(private requestService : RequestService, public globalService : GlobalService) { }

  ngOnInit(): void {
    if(this.objParameterComponent)
    {
      this.getListaEstratti();
    }
  }

  getListaEstratti() : void {
    let payload = {
      numProt: this.objParameterComponent.numProt,
      annoProt: this.objParameterComponent.annoProt
    };

    this.requestService.postRequest("getListaEstratti", payload,
      (success) => {
        this.listaFascicoliEstratti = success.obj.list.map(element => new Fascicolo().buildFromEstratti(element));
        this.searchInProgress = false;
      }
    );

  }

}

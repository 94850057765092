<section class="d-flex flex-column w-100 h-100">


  <app-sezione [sectioneditable]="false">
    <span titleSezione class="d-flex align-items-center justify-content-between w-100">
      Pubblicazione
    </span>
    <section bodySezione class="w-100" >
      <div class="d-flex flex-column">
        <div class="d-flex spaced-input">
          <app-textarea class="w-100" [justChecked]="justChecked" [formField]="oggetto" [labelTextarea]="'Oggetto'"></app-textarea>
          <app-textarea class="w-25" [formField]="note" [labelTextarea]="'Note'"></app-textarea>
        </div>

        <div class="d-flex spaced-input">
          <app-select class="w-100" [formField]="tipoDocumento" [justChecked]="justChecked"
                  [labelSelect]="'Tipo documento'"
                  [listOption]="listOptionTipoDocumento"></app-select>

          <app-select class="w-100" [formField]="struttura" [justChecked]="justChecked"
                  [labelSelect]="'Struttura'"
                  [listOption]="listOptionStruttura"></app-select>

          <app-textinput class="w-100" [formField]="dataDal" [justChecked]="justChecked" [type]="'date'" [labelTextarea]="'Data inizio pubblicazione'"></app-textinput>
          <app-textinput class="w-100" [formField]="dataAl" [justChecked]="justChecked" [type]="'date'" [labelTextarea]="'Data fine pubblicazione'"></app-textinput>

        </div>
      </div>
    </section>
  </app-sezione>

  <app-sezione *ngIf="showSezioneAtto" [sectioneditable]="false" class="mt-4">
    <span titleSezione class="d-flex align-items-center justify-content-between w-100">
      Atto
    </span>
    <section bodySezione class="w-100" >
      <div class="d-flex spaced-input">
        <app-select class="w-100" [formField]="tipoAtto" [justChecked]="justChecked"
          [labelSelect]="'Tipo atto'"
          [listOption]="listOptionTipoAtto"></app-select>

          <app-textinput class="w-100" [formField]="numeroAtto" [justChecked]="justChecked" [type]="'number'" [labelTextarea]="'Numero'"></app-textinput>
          <app-textinput class="w-100" [formField]="dataAtto" [justChecked]="justChecked" [type]="'date'" [labelTextarea]="'Del'"></app-textinput>
      </div>
    </section>
  </app-sezione>

  <section class="d-flex w-100 mt-auto justify-content-end">
    <button (click)="onSubmit()" type="button" class="btn bg-primary-archipro text-white text-nowrap mt-3">Salva</button>
  </section>


</section>

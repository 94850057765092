<div class="d-flex flex-column w-100 bg-white pt-1" >

    <form class="d-flex spaced-input mt-3 w-100" (ngSubmit)="getList()">
        <div class="form-group mb-0 w-100">
            <input type="text" class="form-control" placeholder="Cerca" [(ngModel)]="docRequest.fullText" name="fullText">
        </div>

        <button type="submit" class="btn justify-content-center d-flex bg-primary-archipro text-white">
            <i class="icon-search"></i>Cerca
        </button>
    </form>



    <div *ngIf="listRiferimenti.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">

        <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
            <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
        </diV>
        <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end'}}"  *ngIf="numPage > 1" >
            <nav aria-label="Page navigation example">
                <ul class="pagination mb-0">
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                            <span *ngIf="size>=3" >Precedente</span>
                            <i *ngIf="size<3" class="icon-left-open"></i>
                        </div>
                      </li>
                    <li  *ngFor="let page of listObjPage" class="page-item">
                      <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                    </li>
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                            <span *ngIf="size>=3" >Prossimo</span>
                            <i *ngIf="size<3" class="icon-right-open"></i>
                        </div>
                    </li>
                </ul>
              </nav>
        </div>
    </div>
    <!--Lista-->
    <div class="d-flex w-100 h-100">
        <!--##############################################################################-->
        <!--################################ Tabella #####################################-->
        <!--##############################################################################-->

        <div *ngIf="listRiferimenti.length > 0; else nessunRisultato" class="mt-1 w-100">
            <table class="table table-striped border" *ngIf="size > 3; else mobile" > <!--*ngIf="size > 2; else mobile" *ngIf="false; else mobile"-->
                <thead class="">
                    <tr class="text-primary-archipro">
                        <th class="size-badge-notifica"></th>
                        <th scope="col" class="text-center" (click)="clickOrderColumn(docRequest.ordinamento == 1 ? 2 : 1)">
                          <span class="d-flex cursor-pointer">Numero <i [ngClass]="{'icon-up' : docRequest.ordinamento == 1, 'icon-down' : docRequest.ordinamento == 2}"></i></span>
                        </th>
                        <th scope="col" (click)="clickOrderColumn(docRequest.ordinamento == 3 ? 4 : 3)">
                            <span class="d-flex cursor-pointer">Data <i [ngClass]="{'icon-up' : docRequest.ordinamento == 3, 'icon-down' : docRequest.ordinamento == 4}"></i></span>
                        </th>
                        <th scope="col" (click)="clickOrderColumn(docRequest.ordinamento == 5 ? 6 : 5)">
                            <span class="d-flex cursor-pointer">Flusso <i [ngClass]="{'icon-up' : docRequest.ordinamento == 5, 'icon-down' : docRequest.ordinamento == 6}"></i></span>
                        </th>
                        <th scope="col" (click)="clickOrderColumn(docRequest.ordinamento == 7 ? 8 : 7)">
                            <span class="d-flex cursor-pointer">Oggetto <i [ngClass]="{'icon-up' : docRequest.ordinamento == 7, 'icon-down' : docRequest.ordinamento == 8}"></i></span>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="!searchInProgress; else loadingList">

                    <tr *ngFor="let doc of listRiferimenti; let i = index" class="lista-colonne" (click)="addToGeneralList(doc)">
                        <td class="size-badge-notifica">
                            <i *ngIf="doc.active" class="icon-ok text-success"></i>
                        </td>
                        <td class="numero-column">
                            {{doc.docNumString}}
                        </td>
                        <td>
                            {{doc.dataProt | dataStringTransform}}
                        </td>

                        <td>
                            {{doc.provDest}}
                        </td>

                        <td class="cut-text oggetto-column" title="{{doc.oggetto}}">{{doc.oggetto}}</td>

                    </tr>
                </tbody>
                <ng-template #loadingList>
                    <tbody>
                        <tr>
                            <td colspan="100%">
                                <app-loading class="w-100 h-100 d-flex justify-content-center align-items-center"></app-loading>
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>

            <div *ngIf="listRiferimenti.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">

                <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
                    <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
                </diV>
                <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end'}}"  *ngIf="numPage > 1" >
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-0">
                            <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                                <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                                    <span *ngIf="size>=3" >Precedente</span>
                                    <i *ngIf="size<3" class="icon-left-open"></i>
                                </div>
                              </li>
                            <li  *ngFor="let page of listObjPage" class="page-item">
                              <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                            </li>
                            <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                                <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                                    <span *ngIf="size>=3" >Prossimo</span>
                                    <i *ngIf="size<3" class="icon-right-open"></i>
                                </div>
                            </li>
                        </ul>
                      </nav>
                </div>
            </div>

            <ng-template #mobile>

                <div id="accordionMobileRicercaDocumentiRiferimenti" class="mt-3" *ngIf="!searchInProgress; else loading">
                    <div class="card card-list-mobile" *ngFor="let doc of listRiferimenti; let i = index">
                      <div class="card-header" [attr.id]="'ricerca_documenti_riferimenti_' + i + 'heading'">
                        <div class="mb-0 d-flex">

                          <button class="btn w-100 p-0" data-toggle="collapse" [attr.data-target]="'#' + 'ricerca_documenti_riferimenti_' + i" aria-expanded="true" [attr.aria-controls]="'ricerca_documenti_riferimenti_' + i">
                                <div class="d-flex w-100 justify-content-between">
                                    <h6 class="mt-1 d-flex align-items-center">{{doc.docNumString}}</h6>
                                    <div>

                                        <div style="margin-right: 4px;">
                                            <i *ngIf="doc.security.riservato" title="Riservato" class="icon-lock"></i>
                                            <i *ngIf="doc.isDocPrincipaleP7M || doc.firmatario != null" title="{{doc.firmatario == null || doc.firmatario == '' ? 'Firmato' : doc.firmatario}}" class="icon-{{doc.isDocPrincipaleP7M ? 'doc-certificate' : 'pencil'}} text-icon-disabled"></i>
                                            <span  *ngIf="false" title="Conoscenza" class="icona-conoscenza">Cc</span>
                                            <small class="ml-2">{{doc.dataProt | dataStringTransform}}</small>
                                        </div>

                                    </div>
                                </div>
                                <div class="d-flex mb-1 text-left">
                                    <span class="oggetto-mobile cut-text">
                                        {{doc.oggetto}}
                                    </span>
                                </div>
                                <div class="d-flex text-muted cut-text ">
                                    <small class="agd-uo-list-mobile w-100 d-flex justify-content-between">
                                        <b>{{doc.provDest}}</b>
                                    </small>
                                </div>

                          </button>
                          <div class="position-relative">
                            <div class="dropdown d-flex align-items-center justify-content-center h-100 position-absolute" style="right: -16px;">
                                <span [attr.id]="'action-dropdown-' + 'ricerca_documenti_riferimenti_' + i" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="icon-ellipsis-vert" title="Azioni"></i>
                                </span>

                                <div class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="'action-dropdown-' + 'ricerca_documenti_riferimenti_' + i">
                                    <h6 class="dropdown-header">Azioni</h6>

                                    <div class="dropdown-divider"></div>


                                </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div [attr.id]="'ricerca_documenti_riferimenti_' + i" class="collapse {{i == 0 ? 'show' : ''}}" [attr.aria-labelledby]="'ricerca_documenti_riferimenti_' + i + 'heading'" data-parent="#accordionMobileRicercaDocumentiRiferimenti">
                        <div class="card-body">

                            <div class="d-flex flex-column" style="font-size: 14px;">

                                <div class="d-flex column-element-mobile">
                                    <span class="w-25 font-weight-bold h-100 name-column-mobile">Numero :</span>
                                    <span class="w-100 text-left h-100">{{doc.docNumString}}</span>
                                </div>

                                <div class="d-flex column-element-mobile">
                                    <span class="w-25 font-weight-bold h-100 name-column-mobile">Oggetto :</span>
                                    <span class="w-100 text-left h-100">{{doc.oggetto}}</span>
                                </div>


                                <div class="d-flex column-element-mobile">
                                    <span class="w-25 font-weight-bold h-100 name-column-mobile">Data Invio :</span>
                                    <span class="w-100 text-left h-100">{{doc.dataProt | dataStringTransform}}</span>
                                </div>

                                <div class="d-flex column-element-mobile">
                                    <span class="w-25 font-weight-bold h-100 name-column-mobile">Flusso :</span>
                                    <span class="w-100 text-left h-100">{{doc.provDest}}</span>
                                </div>


                            </div>
                        </div>
                      </div>
                    </div>
                  </div>

            </ng-template>
        </div>

        <ng-template #nessunRisultato >

            <div class="h-100 w-100 d-flex justify-content-center align-items-center" *ngIf="!searchInProgress; else loading">
                <h2>Imposta i criteri di ricerca</h2>
            </div>

        </ng-template>
    </div>

    <ng-template #loading>
        <div class="d-flex justify-content-center align-items-center h-100 w-100">
            <app-loading></app-loading>
        </div>

    </ng-template>
</div>

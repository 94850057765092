import { CustomDateParserFormatterService } from "../service/CustomDateParserFormatter/custom-date-parser-formatter.service";
import { Utility } from "../Utility/Utility";
import { Referente } from "./Referente";

export class ReferentePersona extends Referente{
    nome : string;
    cognome : string;
    dataNascita : string;
    natoA : string;
    provinciaNascita : string;
    paese : string;
    viaDomicilio : string;
    numDomicilio : string;
    comuneDomicilio : string;
    capDomicilio : string;
    provinciaDomicilio : string;
    titolo : string;

    constructor(obj: any){
        super(obj);
        this.nome = obj.nome;
        this.cognome = obj.cognome;

        this.natoA = obj.natoA;
        this.provinciaNascita = obj.provinciaNascita;
        this.paese = obj.paese;
        this.viaDomicilio = obj.viaDomicilio;
        this.numDomicilio = obj.numDomicilio;
        this.comuneDomicilio = obj.comuneDomicilio;
        this.capDomicilio = obj.capDomicilio;
        this.provinciaDomicilio = obj.provinciaDomicilio;
        this.dataNascita = obj.dataNascita;
        this.titolo = obj.titolo;


    }
}

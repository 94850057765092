import { AbstractControl, FormControl } from "@angular/forms";

/**
 * Funzione utilizzata per verificare se un FormControl ha settato il validatore
 * "required" oppure no
 * @param formField : Campo da verificare se required
 * @returns         : torna un valore booleano, true se required, false se non lo è
 */
export const checkIfRequired = (formField : FormControl) : boolean => {
  if(formField.validator){
    const validator = formField.validator({} as AbstractControl);
    if (validator && validator["required"]) {
      return true;
    }
  }
  return false;
}


export const checkControlValidName = (checked : boolean, field : FormControl) : boolean => {
  return checked && !field.valid;
}

/**
 * Funzione che ritorna lo stato di una sezione (true aperto, false chiuso)
 *
 * @param idSection   : id della sezione da verificare
 * @returns           : torna il valore inserito nella mapSectionOpened
 */
export const checkSectionOpened = (idSection : string,  mapSectionOpened : any) : boolean => {
  return mapSectionOpened[idSection];
}


export const openSection = (idSection : string,  mapSectionOpened : any) : void => {
  mapSectionOpened[idSection] = !mapSectionOpened[idSection]
}

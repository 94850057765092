<div class="form-group w-100 mb-0" [ngClass]="{'text-danger' : checkControlName(), 'input-group-sm mb-1' : smallInput}">
    <label class="d-flex justify-content-between" *ngIf="labelTextarea && !labelInPlaceHolder">
      {{labelTextarea}}<ng-container *ngIf="requiredSymbol">*</ng-container>
      <span class="text-nowrap text-primary-archipro" *ngIf="labelInfoDx">{{labelInfoDx}}</span>
    </label>
    <input *ngIf="formField;else simpleInput" [disabled]="disabled" [type]="type" class="form-control input-size" [maxlength]="maxlength" [formControl]="formField" [placeholder]="labelTextarea" [ngClass]="{'border-danger' :  checkControlName(), 'disabled-select': disabled}">

    <ng-template #simpleInput>
      <input class="form-control input-size" [(ngModel)]="modelcustom" [ngModelOptions]="{standalone: true}"
        (ngModelChange)="modelcustomChange.emit(modelcustom)"
        [disabled]="disabled"
        [type]="type"
        [maxlength]="maxlength"
        [placeholder]="labelTextarea"
        [ngClass]="{'border-danger' :  checkControlName(), 'disabled-select': disabled}">
    </ng-template>

    <small *ngIf="checkControlName()" id="required" class="form-text">{{textErroreView}}</small>
</div>

/**
 * Classe utilizzata per la gestione del breadcrumb nella navigazione
 */
export class BreadcrumbObj{
    showAlberoTitolario : boolean = true;
    showFascicoli : boolean = false;
    showDocumenti : boolean = false;
    showDettaglioDocumento : boolean = false;
    showNuovoFascicolo : boolean = false;
    showModificaFascicolo : boolean = false;
    showMovimentiFascicolo : boolean = false;


    setAlberoTitolario(){
        this.showAlberoTitolario = true;
        this.showFascicoli = false;
        this.showDocumenti = false;
        this.showDettaglioDocumento = false;
        this.showNuovoFascicolo = false;
        this.showModificaFascicolo = false;
        this.showMovimentiFascicolo = false;
    }

    setFascicoli(){
        this.showAlberoTitolario = false;
        this.showFascicoli = true;
        this.showDocumenti = false;
        this.showDettaglioDocumento = false;
        this.showNuovoFascicolo = false;
        this.showModificaFascicolo = false;
        this.showMovimentiFascicolo = false;
    }

    setDocumenti(){
        this.showAlberoTitolario = false;
        this.showFascicoli = false;
        this.showDocumenti = true;
        this.showDettaglioDocumento = false;
        this.showNuovoFascicolo = false;
        this.showModificaFascicolo = false;
        this.showMovimentiFascicolo = false;
    }

    //Questo viene usato solo in caso di apertura della navigazione da modale essendo che il viewer è già in modale
    setDettaglioDocumento(){
        this.showAlberoTitolario = false;
        this.showFascicoli = false;
        this.showDocumenti = false;
        this.showDettaglioDocumento = true;
        this.showNuovoFascicolo = false;
        this.showModificaFascicolo = false;
        this.showMovimentiFascicolo = false;
    }

    setMovimentiFascicolo(){
      this.showAlberoTitolario = false;
      this.showFascicoli = false;
      this.showDocumenti = false;
      this.showDettaglioDocumento = false;
      this.showNuovoFascicolo = false;
      this.showModificaFascicolo = false;
      this.showMovimentiFascicolo = true;
    }

    setFormFascicolo(nuovo : boolean){
        if(nuovo){
            this.showNuovoFascicolo = true;
        }
        else
        {
            this.showModificaFascicolo = true;
        }
    }
}

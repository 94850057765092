import { Injectable } from '@angular/core';
import { interval, Subscription} from 'rxjs';
import { Alert } from './alert/Alert';
import { TypeAlert } from './alert/TypeAlert';

declare var $ : any;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alerts : Alert[] = [];
  ltAlert$ : Subscription;
  timeToLive : number = 5000;

  constructor() { }

  /**
   * Metodo che fa partire il timer per eliminare gli alert più vecchi dopo un tot di secondi
   */
  private startInterval() : void{

    if(this.alerts.length == 1)
    {
      this.ltAlert$ = interval(this.timeToLive).subscribe(
        () => {
          this.removeOldAlert(0);
        }
      )
    }
  }

  /**
   * Metodo che aggiunge un'alert tra la lista degli alert. L'id dell'alert viene stabilito con il Date.now()
   * @param text : testo dell'alert
   * @param type : tipo dell'alert che ne definisce il colore
   */
  addAlert(text : string, type: TypeAlert) : void{
    setTimeout(()=> {
      this.alerts.push({id: Date.now(), text : text, type : type});
      this.startInterval();
    }, 10);
  }

  /**
   * Rimuove dopo l'animazione l'alert dall'array degli alert
   * @param id : identificativo dell'alert da eliminare dalla lista
   */
  removeAlertById(id : number){
    setTimeout(()=>{
      this.alerts = this.alerts.filter((alert) => alert.id != id);

      if(this.alerts.length == 0)
      {
        this.ltAlert$.unsubscribe();
      }
    }, 280)
  }

  /**
   * Metodo che rimuove l'alert più vecchio visualizzato dopo un numero di secondi
   * stabilito dall'attributo timeToLive.
   *
   * NAZ - 11/07/2022 : la modifica consiste nel rendere gli alert di tipo "danger" eliminabili
   * sono manualmente e non con l'automatismo di ogni secondo, per far questo è stato aggiunto
   * l'index alla funzione per far in modo che se l'alert più vecchio è di tipo "danger", allora
   * il controllo e l'eventuale rimozione deve essere fatta all'elemento successivo a meno che l'index
   * di quest'ultimo non supera la dimensione dell'array degli alert
   *
   * NAZ - 20/04/2023 : commentata la parte del controllo spiegato nel commento "NAZ - 11/07/202"
   * per rendere tutti gli alert cancellabili con i timer
   */
  private removeOldAlert(index : number) : void{
    /*if(this.alerts[index].type != TypeAlert.danger){
      let idAlertToRemove: number = this.alerts[index].id;
      $(".alert-id-"+idAlertToRemove).alert('close');
      this.removeAlertById(idAlertToRemove);
    }
    else if(index+1 < this.alerts.length)
    {
      this.removeOldAlert(index+1);
    }*/

    let idAlertToRemove: number = this.alerts[index].id;
    $(".alert-id-"+idAlertToRemove).alert('close');
    this.removeAlertById(idAlertToRemove);

  }
}

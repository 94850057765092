import { Component, Input, OnInit } from '@angular/core';
import { checkSectionOpened, openSection } from 'src/app/Utility/UtilityForm';

@Component({
  selector: 'app-sezione',
  templateUrl: './sezione.component.html',
  styleUrls: ['./sezione.component.scss']
})
export class SezioneComponent implements OnInit {

  @Input() fullWidth : boolean = false;
  @Input() mapSectionOpened : any = {};
  @Input() idSingleSection : string = "";
  @Input() sectioneditable : boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Funzione utilizzata per aprire e chiudere le sezioni della form
   *
   * @param idSection : id della sezione da chiudere/aprire (l'id deve essere lo stesso che viene inserito nella mapSectionOpened)
   */
  openSection(idSection : string) : void
  {
    openSection(idSection, this.mapSectionOpened);
  }

  /**
   * Funzione che ritorna lo stato di una sezione (true aperto, false chiuso)
   *
   * @param idSection   : id della sezione da verificare
   * @returns           : torna il valore inserito nella mapSectionOpened
   */
  checkSectionOpened(idSection : string) : boolean{
    return checkSectionOpened(idSection, this.mapSectionOpened);
  }

}

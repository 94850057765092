export class StaticProperty{
    static REDIRECT_AFTER_LOGIN = "redirectAfterLogin"
    static UTENTE_SESSIONE = "user";
    static MESSAGGI_SISTEMA = "message";
    static GLOBALI = "globali";

    //Fatte per il controllo che serve a costruire l'etichetta
    static CODICE_ENTE_CEI = "p_cei";
    static CODICE_ENTE_PADOVA = "dio_pd";
    static CODICE_ENTE_IZS = "izs";
    static CODICE_ENTE_DIOC_LATINA = "dio_lat";
    static CODICE_ENTE_PALERMO = "p_pa";

    /**
     * Nome del cookie (tutto maiuscolo)
     */
    static JSESSIONID_COOKIE = "JSESSIONID";

    static MAP_COD_TIPO_REFERENTE : any = {
        1 : "P", //Persona
        2 : "E", //Ente - Ditta
        3 : "D", //Dipendente
        4 : "UO",//Unità organizzativa
        5 : "PA" //Pubblica amministrazione
      }

    static MAP_FUNCTION_ICON_SIDEBAR : any = {
      elenchi : "icon-list",
      gestioneDocumenti : "icon-doc-text",
      gestioneFascicoli : "icon-folder-open-empty",
      ricerche : "icon-search",
      stampe : "icon-print",
      amministrazione : "icon-tools",
      pratiche : "icon-docs"
    }

    /**
     * Pattern per password che verifica se la stringa contiene:
     *    - Almeno 8 caratteri;
     *    - Almeno 1 carattere maiuscolo;
     *    - Almeno 1 carattere minuscolo;
     *    - Almeno 1 numero;
     *    - Almeno carattere speciale
     */
    static patternStrongPassword = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}';
}

<section class="d-flex flex-column w-100 p-3 bg-form-color form-protocollazione-container">
    <section id="header-page" *ngIf="!modalMode">
        <div class="d-flex text-primary-archipro justify-content-between align-items-center border-bottom pb-2">
            <h4 class="" *ngIf="!showNumeroAnnoFascicoloInput">Fascicoli / {{updateMode ? "Modifica "+ (isSottoFascicolo.value ? "sotto" : "") +"fascicolo (" + fascNumStringUpdate + ")" : "Nuovo "+ (isSottoFascicolo.value ? "sotto" : "") +"fascicolo"}}</h4>
            <h4 class="" *ngIf="showNumeroAnnoFascicoloInput">Fascicoli / Inserisci fascicolo</h4>
        </div>
    </section>
    <section id="body-page" class="d-flex flex-column">
        <form *ngIf="!showAlberoCollocazione" class="w-100" [formGroup]="fascicoloForm">
            <fieldset class="w-100" [disabled]="disalbeForm">
                <div class="d-flex flex-column w-100">
                    <label class="title-section  d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <span *ngIf="!GLOBALI.PRO_COLLOCAZIONE_FISICA; else titcompleto">Classificazione</span>
                            <ng-template #titcompleto>
                                <span >Classificazione e collocazione fisica</span>
                            </ng-template>
                        </div>
                        <i (click)="openSection('sectionClassificazione')" class="cursor-pointer icon-{{checkSectionOpened('sectionClassificazione') ? 'up' : 'down'}}-open"></i>

                    </label>
                    <div class="d-flex flex-column max-with-1400" *ngIf="checkSectionOpened('sectionClassificazione')">

                        <div class="d-flex spaced-input align-items-center pt-2 pb-2" *ngIf="GLOBALI.PRO_SOTTOFASCICOLO && !updateMode">
                          <div class="form-check w-100 align-items-center">
                              <input type="checkbox" class="form-check-input" id="isSottoFascicolo" formControlName="isSottoFascicolo">
                              <label class="form-check-label" for="isSottoFascicolo">Crea Sottofascicolo</label>
                          </div>
                        </div>
                        <div class="d-flex spaced-input">

                            <div class="d-flex flex-column w-100">
                              <app-classificazione-fascicolazione #classificazioneElement class="w-100"
                                  [hiddenFascicolazione]="!isSottoFascicolo.value"
                                  [hiddenAction]="updateMode"
                                  [hiddenOpenNavigazione]="modalMode"
                                  [classificazioneRequired]="true"
                                  [titolo1]="titolo1"
                                  [titolo2]="titolo2"
                                  [titolo3]="titolo3"
                                  [titolo4]="titolo4"
                                  [titolo5]="titolo5"
                                  [titolo6]="titolo6"
                                  [numeroFascicolo]="numFascVis"
                                  [annoFascicolo]="annoPadre"
                                  [dynamicModal]="dynamicModal"
                                  [optionModal]="optionModal"
                                  [justChecked]="justChecked"
                                  [titolarioSelezionato]="titolarioSelezionato"
                                  [fascicoloSelezionato]="fascicoloSelezionato"
                                  (checkClassificazioneClicked)="setCheckClassificazioneClicked($event)"
                                  (exportTitolarioSelezionato)="setTitolarioSelezionato($event)"
                                  (exportFascicoloSelezionato)="setFascicoloSelezionato($event)"
                              ></app-classificazione-fascicolazione>

                            </div>


                            <div class="d-flex flex-column w-75"  *ngIf="GLOBALI.PRO_COLLOCAZIONE_FISICA;else collocazionevuota">
                                <div class="d-flex spaced-input" >
                                    <div class="form-group w-100 min-width-330px">
                                        <label>Collocazione fisica</label>
                                        <div class="d-flex input-classificazione spaced-input">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione1">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione2">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione3">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione4">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione5">
                                            <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" formControlName="collocazione6">
                                        </div>
                                    </div>

                                    <div class="d-flex pt-2 action-icon-protocol spaced-input w-100">
                                        <div class="d-flex spaced-input" *ngIf="!disalbeForm;else showPulsantiClassificazione">
                                            <div class="border container-icon">
                                                <i *ngIf="!waitCheckCollocazione && !showEditCollocazione" class="cursor-pointer icon-target" title="Verifica" (click)="checkCollocazione()"></i>
                                                <i *ngIf="showEditCollocazione" class="cursor-pointer icon-edit" title="Modifica" (click)="editCollocazione()"></i>
                                                <div *ngIf="waitCheckCollocazione" class="loader-mini-spinner"></div>
                                            </div>
                                            <div class="border container-icon">
                                                <i class="cursor-pointer icon-search" (click)="openNavigazioneCollocazione()" title="Cerca"></i>
                                            </div>
                                        </div>
                                        <ng-template #showPulsantiClassificazione>
                                            <div class="container-icon empty"></div>
                                            <div class="container-icon empty"></div>
                                        </ng-template>

                                        <div class="container-icon empty"></div>
                                        <div class="container-icon empty"></div>
                                        <div class="container-icon empty"></div>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="collocazioneSelezionata != null">
                                    <div class="info-titolario d-flex flex-column card-shadow border p-3 mb-2 bg-white w-100">
                                        <div class="d-flex" *ngIf="collocazioneSelezionata != null">
                                            <span class="mr-2">
                                                <b>Collocazione: </b>{{collocazioneSelezionata.viewDescription ? collocazioneSelezionata.viewDescription : collocazioneSelezionata.description}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <ng-template #collocazionevuota>
                                <div class="d-flex w-100">

                                </div>
                            </ng-template>


                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column w-100">
                    <label class="title-section  d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            Dettaglio fascicolo
                        </div>
                        <i (click)="openSection('sectionInfo')" class="cursor-pointer icon-{{checkSectionOpened('sectionInfo') ? 'up' : 'down'}}-open"></i>

                    </label>
                    <div class="d-flex flex-column max-with-1400" *ngIf="checkSectionOpened('sectionInfo')">

                      <div *ngIf="showNumeroAnnoFascicoloInput" class="d-flex spaced-input">
                        <div class="form-group" [ngClass]="{'text-danger' : checkControlName('numFascVisForInsert')}">
                          <label class="text-nowrap">N° fascicolo*</label>
                          <input [readonly]="showEditClassificazione" maxlength="6" type="text" class="form-control" [formControl]="numFascVisForInsert" aria-describedby="numFascVisForInsertRequired" placeholder="" [ngClass]="{'border-danger' : checkControlName('numFascVisForInsert')}">
                          <small *ngIf="checkControlName('numFascVisForInsert')" id="numFascVisForInsertRequired" class="form-text">Campo obbligatorio</small>
                        </div>

                        <div class="form-group" [ngClass]="{'text-danger' : checkControlName('dataApertura') || (justChecked && dataApertura.errors?.yearGreaterCurrentYear)}">
                          <label class="text-nowrap">Data apertura fascicolo(gg/mm/aaaa)*</label>
                          <input type="text" id="dataAperturaPicker" class="form-control" [ngClass]="{'border-danger' : checkControlName('dataApertura') || (justChecked && dataApertura.errors?.yearGreaterCurrentYear) || (justChecked && dataApertura.errors?.dateFormatIncorrect)}" formControlName="dataApertura" #dataAperturaPicker="ngbDatepicker" ngbDatepicker (click)="dataAperturaPicker.toggle()">
                          <small *ngIf="checkControlName('dataApertura') && !dataApertura.errors?.yearGreaterCurrentYear && !dataApertura.errors?.dateFormatIncorrect" id="dataAperturaRequired" class="form-text">Campo obbligatorio</small>
                          <small *ngIf="justChecked && dataApertura.errors?.dateFormatIncorrect" class="form-text">Il formato della data non è corretto (gg/mm/aaaa)</small>
                          <small *ngIf="justChecked && dataApertura.errors?.yearGreaterCurrentYear" class="form-text">L'anno del fascicolo deve essere precedente all'anno corrente</small>
                        </div>
                      </div>

                        <div class="d-flex spaced-input" *ngIf="GLOBALI.PRO_PG_INIZIO_FASCICOLO">
                          <div class="form-group">
                              <label>Primo protocollo</label>
                              <input type="text" class="form-control" id="primoProtocollo" formControlName="primoProtocollo" aria-describedby="primoProtocolloRequired" placeholder="">
                          </div>
                        </div>

                        <div class="d-flex spaced-input" *ngIf="GLOBALI.PRO_SOGGETTO">
                            <div class="form-group w-100" [ngClass]="{'text-danger' : checkControlName('soggetto')}">
                                <label>Soggetto</label>
                                <textarea class="form-control" placeholder="" formControlName="soggetto" id="oggetto" rows="3" maxlength="200" [ngClass]="{'border-danger' : checkControlName('soggetto')}"></textarea>
                                <small *ngIf="checkControlName('soggetto')" id="oggettoRequired" class="form-text">Campo obbligatorio</small>
                            </div>
                        </div>


                        <div class="d-flex spaced-input"   [ngClass]="{'text-danger' : checkControlName('oggetto')}">
                            <div class="form-group w-100">
                                <label>Oggetto*</label>
                                <textarea class="form-control" placeholder="" formControlName="oggetto" id="oggetto" rows="3" maxlength="1000" [ngClass]="{'border-danger' : checkControlName('oggetto')}"></textarea>
                                <small *ngIf="checkControlName('oggetto')" id="oggettoRequired" class="form-text">Campo obbligatorio</small>
                            </div>
                        </div>



                        <div class="d-flex spaced-input">
                            <div class="form-group w-100">
                                <label>Note</label>
                                <textarea class="form-control" placeholder="" formControlName="note" id="oggetto" rows="3" maxlength="1000"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

            </fieldset>
            <div class="d-flex spaced-input max-with-1400 justify-content-end">
                <button *ngIf="showSave" type="button" (click)="onSubmit()" class="btn bg-primary-archipro text-white mt-3">Salva</button>
                <button *ngIf="showNewFascicolo" (click)="resetForm()" type="button" class="btn bg-primary-archipro text-white mt-3">{{updateMode ? "Abilita modifica" : "Nuovo fascicolo"}}</button>
            </div>
        </form>



        <div *ngIf="modalMode && showAlberoCollocazione" class="w-100 h-100">
            <span (click)="showAlberoCollocazione = false" class="text-primary-archipro cursor-pointer font-weight-bold"><i class="icon-left-big "></i>Torna al form</span>
            <app-albero-collocazione [objParameterComponent]="objParameterComponent"></app-albero-collocazione>
        </div>
    </section>



</section>

<app-modal #dynamicModalComponent></app-modal>

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { checkIfRequired } from 'src/app/Utility/UtilityForm';
import { OptionInterface } from './OptionInterface';



@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() textErroreView : string = "Campo obbligatorio";
  @Input() labelInPlaceHolder : boolean = false;
  @Input() listOption : OptionInterface[] = [];
  @Input() labelSelect : string = "";
  @Input() formField : FormControl;
  @Input() justChecked : boolean = true;
  @Input() emptyValueEnabled : boolean = false;
  @Input() disabled : boolean = false;

  requiredSymbol : boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    this.realoadValidation();
  }

  /**
   * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
   *
   * field : nome del campo da verificare
   *
   * @returns il valore true se il campo non è valido
   */
  checkControlName() : boolean{
    return this.justChecked && !this.formField.valid;
  }

  realoadValidation(){
    this.requiredSymbol = checkIfRequired(this.formField);
  }

}

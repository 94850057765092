import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionInterface } from '../../shared/component/select/OptionInterface';
import { RequestService } from 'src/app/service/requestService/request.service';
import { compareIntervalDate } from 'src/app/Utility/Utility';
import { AlertService } from 'src/app/service/alertservice/alert.service';
import { TypeAlert } from 'src/app/service/alertservice/alert/TypeAlert';


@Component({
  selector: 'app-form-albo-pretorio',
  templateUrl: './form-albo-pretorio.component.html',
  styleUrls: ['./form-albo-pretorio.component.scss']
})
export class FormAlboPretorioComponent implements OnInit {

  idTipoDocumentoForShowSezioneAtto = [36, 37, 35];
  showSezioneAtto : boolean = false;

  justChecked : boolean = false;
  listOptionTipoDocumento : OptionInterface[] = [];
  listOptionStruttura : OptionInterface[] = [];
  listOptionTipoAtto : OptionInterface[] = [];

  formObj : FormGroup;

  //Sezione pubblicazione
  oggetto : FormControl = new FormControl("", Validators.required);
  note : FormControl = new FormControl("");
  tipoDocumento : FormControl = new FormControl("", Validators.required);
  struttura : FormControl = new FormControl("", Validators.required);
  dataDal  : FormControl = new FormControl("", Validators.required);
  dataAl  : FormControl = new FormControl("", Validators.required);

  //Sezione atto
  tipoAtto : FormControl = new FormControl("");
  numeroAtto : FormControl = new FormControl("");
  dataAtto : FormControl = new FormControl("");

  @Input() documentoInfo : any;
  @Output() returnEvent : EventEmitter<any> = new EventEmitter();

  constructor(private requestService : RequestService, private fb : FormBuilder, private alertService : AlertService) {
    this.formObj = this.fb.group({
      oggetto : this.oggetto,
      note : this.note,
      tipoDocumento : this.tipoDocumento,
      struttura : this.struttura,
      dataDal : this.dataDal,
      dataAl : this.dataAl,
      tipoAtto : this.tipoAtto,
      numeroAtto : this.numeroAtto,
      dataAtto : this.dataAtto
    });
  }

  ngOnInit(): void {
    this.getOptionTipoDocumento();
    this.getOptionStruttura();
    this.setValueChanges();
  }

  setValueChanges(){
    this.tipoDocumento.valueChanges.subscribe(value => {
      this.showSezioneAtto = this.idTipoDocumentoForShowSezioneAtto.includes(value);

      if(this.showSezioneAtto)
      {
        this.dataAtto.setValidators(Validators.required);
        this.numeroAtto.setValidators(Validators.required);
        this.tipoAtto.setValidators(Validators.required);
        this.formObj.updateValueAndValidity();
      }
      else
      {
        this.dataAtto.clearValidators();
        this.numeroAtto.clearValidators();
        this.tipoAtto.clearValidators();
        this.dataAtto.updateValueAndValidity();
        this.numeroAtto.updateValueAndValidity();
        this.tipoAtto.updateValueAndValidity();
      }
    });

    this.dataDal.valueChanges.subscribe(value => {

      if(value && this.dataAl.value){

        let dataDal = new Date(value);
        let dataAl = new Date(this.dataAl.value);
        dataAl.setDate(dataAl.getDate() - 10);

        if(compareIntervalDate(value, this.dataAl.value)){
          this.alertService.addAlert("La data di inizio pubblicazione non può essere superiore della data di fine", TypeAlert.danger)
        }
        else if(dataAl <= dataDal)
        {
          this.alertService.addAlert("La data di fine pubblicazione deve essere almeno 10 giorno dopo della data di inizio pubblicazione", TypeAlert.danger)
        }
      }
    });

    this.dataAl.valueChanges.subscribe(value => {
      if(value && this.dataDal.value){

        let dataDal = new Date(this.dataDal.value);
        let dataAl = new Date(value);
        dataAl.setDate(dataAl.getDate() - 10);

        if(compareIntervalDate(this.dataDal.value, value)){
          this.alertService.addAlert("La data di inizio pubblicazione non può essere superiore della data di fine", TypeAlert.danger)
        }
        else if(dataAl <= dataDal)
        {
          this.alertService.addAlert("La data di fine pubblicazione deve essere almeno 10 giorno dopo della data di inizio pubblicazione", TypeAlert.danger)
        }
      }
    });

  }

  getOptionTipoDocumento(){
    this.requestService.postRequest("getOpzioniTipoDocumentoAlboPretorio", "",
      (response) => {
        this.listOptionTipoDocumento = response.obj.map(element => element = {value : element.value, description : element.label});
      }
    );
  }

  getOptionStruttura() {
    this.requestService.postRequest("getOpzioniStrutturaProponenteAlboPretorio", "",
      (response) => {
        this.listOptionStruttura = response.obj.map(element => element = {value : element.value, description : element.label});
      }
    );
  }

  getOptionAtto() {
    this.requestService.postRequest("getOpzioniAttoAlboPretorio", "",
      (response) => {
        this.listOptionTipoAtto = response.obj.map(element => element = {value : element.value, description : element.label});
      }
    );
  }

  onSubmit(){
    this.justChecked = true;

    if(this.formObj.valid)
    {
      let obj : any = this.formObj.getRawValue();

      obj.numProt = this.documentoInfo.pk.numProt;
      obj.annoProt = this.documentoInfo.pk.annoProt


      this.requestService.postRequest("addAlboPretorio", obj ,
        success => {
          this.alertService.addAlert("Pubblicazione avvenuta con successo", TypeAlert.success);
          this.returnEvent.emit();
        }
      )
    }

  }

}

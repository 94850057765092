import { Component, HostListener, Input, OnInit} from '@angular/core';
import { getFileClass, Utility } from 'src/app/Utility/Utility';
import { RequestService } from './../../../../../service/requestService/request.service';
import { MailDto } from './../../../../../model/MailDto';
import { environment } from './../../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { PrimaryKeyFlussoMail } from './../../../../../model/PrimaryKeyFlussoMail';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { ResizeService } from 'src/app/service/resize/resize.service';


@Component({
  selector: 'app-dettaglio-email',
  templateUrl: './dettaglio-email.component.html',
  styleUrls: ['./dettaglio-email.component.scss']
})
export class DettaglioEmailComponent implements OnInit {

  @Input()
  objParameterComponent : any;

  @Input()
  mail : MailDto = new MailDto();

  @Input()
  pkFlussoMail : PrimaryKeyFlussoMail;

  htmlProva : string = "";

  //Variabile che indica la visualizzazione del viewer dell'allegato
  showAllegato : boolean = false;

  //Allegato selezionato
  allegatoSelezionato : any = {};

  searchInProgress : boolean = false;

  showInfoMobile : boolean = true;

    //Variabile che indica la grandezza della finestra
    size : SCREEN_SIZE;


  constructor(private requestService : RequestService, private sanitizer : DomSanitizer, private resizeSvc : ResizeService) {
    this.size = this.resizeSvc.onResize();
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  ngOnInit(): void {

    let objRequest : any = null;

    if(this.objParameterComponent != null)
    {
      if(Utility.checkObj(this.objParameterComponent.mail))
      {
        objRequest = {idmail : this.objParameterComponent.mail.id};
      }
    }
    else if(Utility.checkObj(this.pkFlussoMail))
    {
      objRequest = this.pkFlussoMail;
    }

    if(Utility.checkObj(objRequest))
      this.getMail(objRequest);
    else
      alert("non ci sono email da visualizzare"); //da gestire con messaggio nel component del dettaglio mail



  }

  /**
   * Funzione utilizzata nell'html per prendere il nome dell'icona
   *
   * @param filename  : nome del file
   * @returns         : ritorna il valore preso dalla funzione Utility.getFileClass();
   */
  getIconByName(filename : string) : string{
    return getFileClass(filename);
  }

  /**
   * Metodo che apre uno degli allegati della mail selezionati
   *
   * @param allegato : oggetto dell'allegato da aprire
   */
  selectAllegato(allegato : any) : void{
    this.showAllegato = false;
    this.disableAllegati();
    allegato.active = true;
    this.allegatoSelezionato = allegato;
    setTimeout(() => {this.showAllegato = true} ,0);
  }

  /**
   * Metodo che disabilita tutti gli allegati
   */
  disableAllegati() : void{
    this.mail.listaAllegati.map(element => {
      element.active = false;
    })
  }

  showDettaglio(){
    this.disableAllegati();
    this.allegatoSelezionato = {};
    this.showAllegato = false
  }

  /**
   * Metodo che recupera i dettagli della mail dal server
   *
   * @param idMail : id della mail selezionata
   */
  getMail(objRequest : any) : void{
    this.mail = new MailDto();
    this.searchInProgress = true;
    this.requestService.postRequest("leggiEmlInArrivo", objRequest,
      (success) => {
        let objMail : any = success.obj;

        this.mail.dataInvio30 = objMail.data30;
        this.mail.oggetto = objMail.oggetto;
        this.mail.destinatario = objMail.to.address;
        this.mail.mittente = objMail.from.address;

        if(objMail.allegati != null && objMail.allegati.length > 0)
        {
          this.mail.setListaAllegatiByListaAllegatiWeb(objMail.allegati);
        }

        this.getBodyMail(objMail.corpo?.webFile);

      },
      () => {
        this.searchInProgress = false;
      }
    )
  }


  /**
   * Funzione che tramite un url restituisce il body della mail
   *
   * @param url   : url del file html da chiamare
   * @returns     : html del body per fare l'innerhtml
   */
  getBodyMail(url : string){
    if(!url) this.searchInProgress = false
    //In questo caso la richiesta la gestisco nella funzione "errorRequest" perchè questa chiamata
    //non avrà un oggeto json di risposta con il parametro status, per cui entrerà subito nell'errorRequest
    this.requestService.getRequest(url, null,
      null,
      (html) => {
        if(url.toLowerCase().endsWith(".html"))
        {
          this.mail.bodyMail = this.sanitizer.bypassSecurityTrustHtml(html);
        }
        else{
          this.mail.bodyMail = html.replace(/(\r\n|\n|\r)/gm, "<br>");
        }
        this.searchInProgress = false;
      },
      true
    );
  }


}

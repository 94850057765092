export class ArchiPROInfoModel{
    titolo: string ;
    versione: string;
    server: string;
    message : any;
    mostraLoghi : boolean = true;
    mostraLogoSidi : boolean = true;

    constructor(){}

    setAllInfo(objInfo? : any){
      if(objInfo)
      {
        this.titolo = objInfo.titolo;
        this.versione = objInfo.versione;
        this.server = objInfo.server;
        this.message = objInfo.message;
        this.mostraLoghi = objInfo.mostraLoghi == undefined ? true : objInfo.mostraLoghi;
        this.mostraLogoSidi = objInfo.mostraLogoSidi == undefined ? true : objInfo.mostraLogoSidi;
      }

    }



}

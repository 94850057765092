import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../globalService/global.service';
import { RequestService } from '../requestService/request.service';
import { EtichettaObj } from './EtichettaObj';
import { TipoPrinter } from './TIpoPrinter';

declare var dymo : any;

@Injectable({
  providedIn: 'root'
})
export class StampaEtichettaService {

  private modelloEtichettaXml : string;
  private etichettaObj : EtichettaObj;

  constructor(private http : HttpClient, private globalService : GlobalService, private requestService : RequestService) {}

  /**
   * Metodo che prende il modello xml e successivamente stampa l'etichetta
   * @param etichettaObj
   */
  async avviaStampa(etichettaObj : EtichettaObj)
  {
    let value = null;
    this.etichettaObj = etichettaObj;




    if(this.globalService.GLOBAL.PRO_TIPO_PRINTER == TipoPrinter.GENERICA)
    {
      await this.requestService.postRequestAsync("stampaEtichettaGenerica", etichettaObj,
        success => {
          value = success.obj?.url;
        }
      )
    }
    else if(this.globalService.GLOBAL.PRO_TIPO_PRINTER == TipoPrinter.ZEBRA){

      let intestazione = "";
      if(this.globalService.GLOBAL.PRO_CODICE_ENTE_2 == "dio_mi")
        intestazione = "ARCIDIOCESI / S.U.A. ";
      else if(this.globalService.GLOBAL.PRO_CODICE_ENTE_2 == "dio_ubc")
        intestazione =  "ARCIDIOCESI / UBC ";

      let titolo = this.etichettaObj.getEtichettaZebra();

      let riferimenti : string[] = this.etichettaObj.riferimenti?.map((riferimento, index) => "n. Prat. " + riferimento.numPratica + "-" + riferimento.annoPratica)
                                                  .slice(0, 3);

      if(this.etichettaObj.riferimenti.length > 3){
        riferimenti[riferimenti.length - 1] += " ...altre pratiche..."
      }

      while(riferimenti.length < 3){
        riferimenti.push("");
      }


      this.avviaStampaZebra(intestazione, titolo, riferimenti[0], riferimenti[1], riferimenti[2]);

    }
    else //Vuol dire che TipoPrinter == DYMO
    {
      this.getModelloEtichettaXml(this.stampaEtichetta);
    }

    return value;
  }

  /**
   * Metodo per avviare la stampa alla zebra
   *
   * @param data1 : Intestazione dell'etichetta che dipende dal codice ente (se dio_mi oppure no)
   * @param data2 : Titolo dell'etichetta composto dal flusso del protocollo, seguito dal numero, anno e data
   * @param data3 : Riferimento alla prima pratica
   * @param data4 : Riferimento alla seconda pratica
   * @param data5 : Riferimento alla terza pratica con aggiunta della dicitura di "altre pratiche" se ci sono più di 3 riferimenti
   */
  private avviaStampaZebra(data1, data2, data3, data4, data5)
  {

    let url = "http://localhost:3000/printlabel?printer=OBLITERATRICE&template=etichetta" +
            "&data_1=" + data1 +
            "&data_2=" + data2 +
            "&data_3=" + data3 +
            "&data_4=" + data4 +
            "&data_5=" + data5;

    this.http.post(url, {}).subscribe(success => {});
  }

  /**
   * Metodo che non fa altro che prendere dal server il modello xml per l'etichetta e avvia la stampa con stampaetichett()
   */
  private getModelloEtichettaXml(f : Function) : void{

    let optionsRequest : any = {
      params: {},
      withCredentials : true,
      responseType: "text"
    };

    let formato = "XML/ArchiPRO_36x89.xml";

    /*if(StaticProperty.CODICE_ENTE_CEI == this.globalService.GLOBAL.PRO_CODICE_ENTE || StaticProperty.CODICE_ENTE_DIOC_LATINA == this.globalService.GLOBAL.PRO_CODICE_ENTE){
      formato = "XML/ArchiPRO_28x89.xml";
    }*/

    if(this.globalService.GLOBAL.PRO_TIPO_PRINTER_LABEL)
    {
      formato = "XML/" + this.globalService.GLOBAL.PRO_TIPO_PRINTER_LABEL;
    }

    this.http.get(environment.url + formato, optionsRequest).subscribe(
      (response) => {
        this.modelloEtichettaXml = response as unknown as string;
        f();
      }
    );
  }

  /**
   * Metodo che avvia la stampa della dymo
   * @param etichettaObj
   */
  private stampaEtichetta = () : void => {
    try{

      let f = dymo.label.framework;

      this.modelloEtichettaXml = this.etichettaObj.getEtichetta(this.modelloEtichettaXml);

      let label = f.openLabelXml(this.modelloEtichettaXml);



      let printers = dymo.label.framework.getPrinters();
      if (printers.length == 0)
          throw "Nessuna stampante DYMO rilevata. Installare una stampante DYMO.";

      let printerName = "";
      let connected : boolean = false;
      for (let i = 0; i < printers.length; ++i)
      {
          let printer = printers[i];
          if (printer.printerType == "LabelWriterPrinter")
          {
              printerName = printer.name;
              if(printer.isConnected){
                  connected = true;
                  break;
              }    
          }
      }

      if (printerName == "")
          throw "Nessuna stampante Labelwriter rilevata. Installare una stampante Labelwriter.";

      if (!connected)
          throw "Nessuna stampante Labelwriter collegata. Collegare una stampante Labelwriter.";

      // finally print the label
      label.print(printerName);

    }
    catch(e)
    {
        alert(e.message || e);
    }


  }


}

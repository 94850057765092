import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class NavigateService implements RouteReuseStrategy {

  private storedHandles: { [key: string]: DetachedRouteHandle } = {};
  private filterStates: { [key: string]: any } = {};

  // Indica se la rotta deve essere memorizzata
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.storeRoute;
  }

  // Memorizza la rotta e lo stato dei filtri
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    let key = this.getFullPath(route);
    this.storedHandles[key] = handle;
    const component = handle && (handle as any).componentRef.instance;

    if (component && component.docRequest) {
      this.filterStates[key] = component.docRequest;
    }

  }

  // Indica se la rotta deve essere riutilizzata
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    let key = this.getFullPath(route);
    return !!this.storedHandles[key];
  }

   // Recupera la rotta memorizzata
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    let key = this.getFullPath(route);
    this.executeOnReuse(route);
    return this.storedHandles[key];
  }

  // Non riutilizzare mai la rotta, forzando la ricostruzione del componente
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  getFilterState(path: string): any {
    return this.filterStates[path];
  }

  public getFullPath(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join("/"))
      .join("/")
      .trim()
      .replace(/\/$/, ""); // Remove trailing slash
  }

  public executeOnReuse(route: ActivatedRouteSnapshot): void {

    const component = (this.storedHandles[this.getFullPath(route)] as any)?.componentRef?.instance;

    if (component && component.reuseFunctionComponent) {
      component.reuseFunctionComponent(this.filterStates[this.getFullPath(route)]);
    }
  }








}

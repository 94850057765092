<section class="w-100 h-100">
    <section class="w-100 overflow-auto dynamic-height-calc-footer">
        <app-loading *ngIf="loading" [text]="''"></app-loading>

        <app-elenco-movimenti *ngIf="!loading && showObjViewer.showMovimenti"
        [objParameterComponent]="objParameterComponent"
        ></app-elenco-movimenti>


        <div *ngIf="!loading && showObjViewer.showAllegato" style="height: 99%;">
            <ng2-pdfjs-viewer
                pdfSrc="{{allegatoVisualizzato.url}}"
            ></ng2-pdfjs-viewer>
            <div *ngIf="errorDocument" class="h-100 w-100 d-flex justify-content-center align-items-center">
                <h4 class="text-center">{{textError}}</h4>
            </div>
        </div>


        <app-dettaglio-documento-viewer *ngIf="!loading && showObjViewer.showDettaglioDoc"
        [documentoInfo]="documentoInfo"
        [objCompetenza]="objCompetenza"
        [elencoCC]="elencoCC"
        [idViewer]="'dynamicModal'"
        ></app-dettaglio-documento-viewer>

        <app-firmatario *ngIf="!loading && showObjViewer.showDatiFirma"
        [listaFirmatari]="allegatoVisualizzato.signer"
        ></app-firmatario>

        <!--<app-dettaglio-email *ngIf="!loading && showObjViewer.showFlussi" [mail]="mail"></app-dettaglio-email>-->
        <app-interoperabilita *ngIf="!loading && showObjViewer.showFlussi && !showObjViewer.showDettaglioEmail"
        [openMailViewer]="openMailViewer"
        [pk]="documentoInfo.pk"
        ></app-interoperabilita>

        <app-dettaglio-email *ngIf="!loading && showObjViewer.showFlussi && showObjViewer.showDettaglioEmail" [pkFlussoMail]="pkFlussoMail"></app-dettaglio-email>



    </section>

    <section class="d-flex w-100 modal-footer justify-content-around icon-viewer" style="height: 90px;">


        <span (click)="showObjViewer.setDettaglioDoc()" title="Dettagli" class="d-flex flex-column text-center" [ngClass]="{'text-primary-archipro font-weight-bold' : showObjViewer.showDettaglioDoc}">
            <i class="icon-info"></i>
            <span *ngIf="size>2">Dettagli</span>
        </span>

        <!--Firmatario NEw-->
        <span (click)="allegatoVisualizzato.signer != null && showObjViewer.setDatiFirma()" *ngIf="isFirmaDigitale && size>2" title="Download" class="d-flex flex-column text-center"
            [ngClass]="{'disabled text-icon-disabled cursor-not-allowed' : allegatoVisualizzato.signer == null, 'text-primary-archipro font-weight-bold' : showObjViewer.showDatiFirma}">
            <i class="icon-{{isFirmaDigitale ? 'doc-certificate' : 'pencil'}}"></i>
            <span *ngIf="size>2">Firmatario</span>
        </span>

        <!--Firmatario-->
        <div class="dropdown" *ngIf="!isFirmaDigitale">
            <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{'disabled text-icon-disabled cursor-not-allowed' : allegatoVisualizzato.signer == null }">
                <div class="d-flex ">
                    <div class="d-flex flex-column text-center">
                        <i class="icon-{{isFirmaDigitale ? 'doc-certificate' : 'pencil'}}"></i>
                        <span  *ngIf="size>2">Firmatario</span>
                    </div>
                </div>
            </span>

            <div class="dropdown-menu" *ngIf="allegatoVisualizzato.signer != null">
                <!-- Dropdown menu links -->
                <h6 class="dropdown-header font-weight-bold pb-0">Info Firmatario</h6>
                <div class="dropdown-divider"></div>
                    <div class="dropdown-information d-flex flex-column" *ngFor="let firmatario of allegatoVisualizzato.signer; let i = index">
                        <div class="righe-info d-flex justify-content-between mt-1">
                            <span><b>Firmatario:</b> {{firmatario.descrizione}} </span>
                        </div>
                    </div>
            </div>
        </div>

        <!--Riferimenti-->
        <div class="dropdown" *ngIf="false">
            <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{'disabled text-icon-disabled' : (documentoInfo.riferimenti == null || documentoInfo.riferimenti.length == 0) }">
                <div class="d-flex ">
                    <div class="d-flex flex-column text-center">
                        <i class="icon-link"></i>
                        <span class="text-capitalize" *ngIf="size>2">{{globalService.GLOBAL.PRO_LABEL_RIFERIMENTI}}</span>
                    </div>
                </div>
            </span>

            <div class="dropdown-menu" *ngIf="!(documentoInfo.riferimenti == null || documentoInfo.riferimenti == undefined || documentoInfo.riferimenti.length == 0)">
                <!-- Dropdown menu links -->
                <h6 class="text-capitalize dropdown-header font-weight-bold pb-0">{{globalService.GLOBAL.PRO_LABEL_RIFERIMENTI}}</h6>
                <div class="dropdown-divider"></div>
                <div class="dropdown-information d-flex flex-column" *ngFor="let riferimento of  documentoInfo.riferimenti; let i = index">

                    <div class="righe-info d-flex justify-content-between mt-1">
                        <span><b>Tipo:</b> {{riferimento.tipoRiferimento == 'P' ? 'Pratica' : 'Ente'}}</span>
                    </div>

                    <div *ngIf="riferimento.tipoRiferimento == 'P'" class="righe-info d-flex justify-content-between mt-1">
                        <span><b>Pratica:</b> {{riferimento.numPratica}} / {{riferimento.annoPratica}} </span>
                    </div>

                    <div class="righe-info d-flex justify-content-between mt-1">
                        <span><b>Ente:</b> {{riferimento.enteRiferimento}}
                            <span *ngIf="riferimento.tipoRiferimento == 'E'">
                                {{riferimento.enteVia != null ? riferimento.enteVia : '' }}
                                {{riferimento.enteDecanato != null ? riferimento.enteDecanato : '' }}
                            </span>
                            ({{riferimento.enteComune != null ? riferimento.enteComune : '' }})
                        </span>
                    </div>

                    <div *ngIf="riferimento.tipoRiferimento == 'P'" class="righe-info d-flex justify-content-between mt-1">
                        <span><b>Referente:</b> {{riferimento.referentePratica}} </span>
                    </div>

                    <div *ngIf="riferimento.tipoRiferimento == 'P'" class="righe-info d-flex justify-content-between mt-1">
                        <span><b>Oggetto:</b> {{riferimento.oggettoPratica}} </span>
                    </div>

                    <div class="dropdown-divider mt-4 mb-0" *ngIf=" documentoInfo.riferimenti.length > 1 && i <  documentoInfo.riferimenti.length - 1"></div>
                </div>
            </div>
        </div>

        <!--Allegati -->
        <div class="dropdown" class="d-flex flex-column text-center">


            <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="d-flex ">
                    <div class="d-flex flex-column text-center">
                        <i class="icon-attach"></i>
                        <span  *ngIf="size>2">Allegati</span>
                    </div>
                </div>
            </span>

            <div class="dropdown-menu">
                <h6 class="dropdown-header font-weight-bold pb-0">Allegati</h6>
                <div class="dropdown-divider"></div>
            <!-- Dropdown menu links -->
                <a *ngFor="let allegato of listaAllegati" class="dropdown-item cut-text" [ngClass]="{'font-weight-bold text-dark' : allegato.pk.progr == allegatoVisualizzato.pk.progr}" (click)="showObjViewer.setAllegato();getAllegatoSelezionato(allegato)">{{allegato.descrizione}}</a>

            </div>
        </div>

        <span *ngIf="size>2" (click)="showObjViewer.setMovimenti()" title="Movimenti" class="d-flex flex-column text-center" [ngClass]="{'text-primary-archipro font-weight-bold' : showObjViewer.showMovimenti}">
            <i class="icon-switch"></i>
            <span *ngIf="size>2">Movimenti</span>
        </span>

        <span *ngIf="size > 2" (click)="(documentoInfo.flussiInterop || documentoInfo.flussiMail) && showObjViewer.setFlussi()" title="Flussi" class="d-flex flex-column text-center" [ngClass]="{'text-primary-archipro font-weight-bold' : showObjViewer.showFlussi, 'disabled text-icon-disabled cursor-not-allowed' : !(documentoInfo.flussiInterop || documentoInfo.flussiMail)}">
            <i class="icon-spin3"></i>
            <span *ngIf="size > 2">Flussi</span>
        </span>

        <span *ngIf="size>2" title="Download" class="d-flex flex-column text-center disabled text-icon-disabled cursor-not-allowed">
            <i class="icon-download"></i>
            <span *ngIf="size>2">Download</span>
        </span>


        <!--Azioni-->
        <div  *ngIf="size <= 2" class="dropdown" class="d-flex flex-column text-center">


            <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="d-flex ">
                    <div class="d-flex flex-column text-center">
                        <i class="icon-ellipsis-vert"></i>
                        <span  *ngIf="size>2">Azioni</span>
                    </div>
                </div>
            </span>

            <div class="dropdown-menu">
            <!-- Dropdown menu links -->
            <h6 class="dropdown-header font-weight-bold pb-0">Azioni</h6>
            <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="showObjViewer.setFlussi()"><i class="icon-{{showObjViewer.showFlussi ? 'doc' : 'spin3'}}"></i>{{showObjViewer.showMovimenti ? 'Documento' : 'Flussi'}}</a>
                <a class="dropdown-item" (click)="showObjViewer.setMovimenti()"><i class="icon-{{showObjViewer.showMovimenti ? 'doc' : 'switch'}}"></i>{{showObjViewer.showMovimenti ? 'Documento' : 'Movimenti'}}</a>
                <a class="dropdown-item disabled"><i class="icon-download"></i>Download</a>
            </div>
        </div>

    </section>
</section>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { ViewerExtComponent } from './component/viewer-ext/viewer-ext.component';
import { ViweDocComponent } from './component/viwe-doc/viwe-doc.component';
import { AccessWebSidiComponent } from './component/access-web-sidi/access-web-sidi.component';
import { CourtesyPageComponent } from './component/courtesy-page/courtesy-page.component';

//import { CanDeactivateGuardService } from './service/CanDeactivateService/can-deactivate-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },

  //****************************Sezione WEBSIDI****************************
  //***********************************************************************
  //https://protocollo.diocesiudine.it/ArchiPRO/#/ssoWebSidi/<username>/<token>

  { path: 'webSidi/sso/:user/:token', component: LoginComponent },
  { path: 'casSso/:token', component: LoginComponent },
  { path: 'webSidi/docReferenti/:user/:token/:idSoggetto/:idTipoSoggetto', component: AccessWebSidiComponent },
  { path: 'webSidi/apriDocumento/:user/:token/:numDoc/:annoDoc', component: AccessWebSidiComponent },

  //***********************************************************************
  //***********************************************************************
  { path: 'viewer', component: ViewerExtComponent },
  { path: 'errorPage/noAccess', component: CourtesyPageComponent },
  { path: 'errorPage/logoutSuccess', component: CourtesyPageComponent },

  { path: 'viewDoc/:token', component: ViweDocComponent },
  {
    path: 'modulo',
    //canDeactivate: [CanDeactivateGuardService],
    loadChildren: () => (import('./component/moduli/scrivania-module/scrivania-module.module')).then(c => c.ScrivaniaModuleModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true })],


  exports: [RouterModule]
})
export class AppRoutingModule { }

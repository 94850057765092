<section class="d-flex flex-column w-100 h-100">

  <!--Alert-->
  <div *ngIf="showAlert" class="w-100 alert alert-{{colorAlert}}" role="alert">
    {{messAlert}}
  </div>

  <!--Sezione destinatari e oggetto-->
  <div class="d-flex">

    <div class="bg-white d-flex flex-column border justify-content-center align-items-center h-100 p-3 mx-2 cursor-pointer" title="Invia" (click)="!disableAllField && invia()">
      <div class="composed-icon-pedice invia-button text-primary-archipro">
        <i class="icon-mail" ></i>
        <i class="icon-paper-plane pedice" style="font-size: 15px;"></i>
      </div>
      <span>Invia</span>
    </div>

    <div class="d-flex flex-column w-100">
      <div class="form-group d-flex w-100 m-1 align-items-center" [ngClass]="{'text-danger' : errorStringA || errorObbligatorio}">
        <label for="inputDestinatari" class="label-input-destinatari">A:</label>
        <div class="d-flex flex-column w-100">
          <input [disabled]="disableAllField" [ngClass]="{'border-danger' : errorStringA || errorObbligatorio}" type="text" class="form-control form-control-sm w-100" id="inputDestinatari" [(ngModel)]="mail.stringDestinatariA" placeholder="Destinatari">
          <small *ngIf="errorStringA" id="inputDestinatarierr" class="form-text">Email non valide: {{errorStringA}} </small>
          <small *ngIf="errorObbligatorio" id="inputDestinatarierr" class="form-text">Campo obbligatorio </small>
        </div>

      </div>

      <div class="form-group d-flex w-100 m-1 align-items-center" [ngClass]="{'text-danger' : errorStringCc}">
        <label for="inputCc" class="label-input-destinatari">CC:</label>
        <div class="d-flex flex-column w-100">
          <input [disabled]="disableAllField" [ngClass]="{'border-danger' : errorStringCc}" type="text" class="form-control form-control-sm w-100" id="inputCc"  [(ngModel)]="mail.stringDestinatariCc" placeholder="Cc">
          <small *ngIf="errorStringCc" id="inputCcerr" class="form-text">Email non valide: {{errorStringCc}} </small>
        </div>
      </div>

      <div class="form-group d-flex w-100 m-1 align-items-center">
        <label for="inputOggetto" class="label-input-destinatari">Oggetto:</label>
        <input [disabled]="disableAllField" type="text" class="form-control form-control-sm w-100" id="inputOggetto" [(ngModel)]="mail.oggetto" placeholder="Oggetto">
      </div>
    </div>

  </div>

  <!--Sezione allegati-->
  <div class="d-flex mb-2">
    <div class="d-flex bg-white flex-column border justify-content-center align-items-center p-3 m-2 cursor-pointer" title="Aggiungi allegati">
      <div class="composed-icon-pedice invia-button text-primary-archipro cursor-pointer">
        <i class="icon-folder-open-empty"></i>
        <i class="icon-attach pedice" style="font-size: 15px;"></i>
        <input [disabled]="disableAllField" (change)="onChangeFile($event)" class="input-file-hidden cursor-pointer" type="file"/>
      </div>
    </div>
    <div class="d-flex container-allegati-mail w-100 align-items-center">
      <div *ngFor="let allegato of mail.metadati; let i = index" class="position-relative container-file d-flex align-items-center m-2 allegato border cursor-pointer"
          [ngClass]="{'border-primary' : allegato.active}" (click)="selectAllegato(allegato)">
              <i class="class-icon-file {{getIconByName(allegato.fileOrig)}}"></i>
              <div class="d-flex flex-column">
                  <small class="cut-text" title="{{allegato.descrizione}}">{{allegato.descrizione}}</small>
                  <small class="text-icon-disabled">{{allegato.formato}}</small>
              </div>

              <!--Icona di eliminazione allegato-->
              <i *ngIf="!disableAllField" class="icona-delete-allegato text-icon-disabled icon-cancel position-absolute" (click)="deleteAllegato(allegato)"></i>
          </div>
    </div>
  </div>


  <!--Sezione corpo-->
  <div class="d-flex h-100 w-100">
    <div class="form-group w-100 h-100">
      <textarea [readOnly]="disableAllField" class="form-control h-100" placeholder="" id="corpo"  [(ngModel)]="mail.corpo"></textarea>
    </div>
  </div>
</section>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AllegatoObj } from 'src/app/model/AllegatoObj';
import { DatiEml } from 'src/app/model/DatiEml';
import { TipoDoc } from 'src/app/model/enum/TipoDoc';
import { RequestService } from 'src/app/service/requestService/request.service';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { getFileClass, Utility } from 'src/app/Utility/Utility';

@Component({
  selector: 'app-invio-email',
  templateUrl: './invio-email.component.html',
  styleUrls: ['./invio-email.component.scss']
})
export class InvioEmailComponent implements OnInit {

  @Input() listaAllegati : any[];
  @Input() documentoInfo : any;

  @Output() showAllegato : EventEmitter<AllegatoObj> = new EventEmitter<AllegatoObj>();

  errorStringA : string = "";
  errorStringCc : string = "";
  errorObbligatorio : boolean = false;

  disableAllField : boolean = false;
  colorAlert : string = "success";
  messAlert : string = "";
  showAlert : boolean = false;

  //Elenco dei file vera e propria che viene all'init riempito con file fake
  mappaFile : Map<number, File> = new Map<number, File>();

  mail : DatiEml = new DatiEml();

  constructor(private requestService : RequestService) { }

  /**
   * Costruisco qui la struttura della email
   */
  ngOnChanges(): void {
    this.mail = new DatiEml();
    this.listaAllegati = this.listaAllegati.filter(element => element.applic == TipoDoc.ELETTRONICO);
    let user =  Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE);

    this.mail.numProt = this.documentoInfo.pk.numProt;
    this.mail.annoProt = this.documentoInfo.pk.annoProt;
    this.mail.buildDefaultOggetto(this.documentoInfo.docNumString);
    this.mail.buildDefaultCorpo(this.documentoInfo.oggetto, user.cognome + " " + user.nome, this.documentoInfo.docNumString);
    this.mail.buildListAllegati(this.listaAllegati);
    this.mail.destinatariA = this.documentoInfo.emailCompetenza;
    this.mail.destinatariCc = [this.documentoInfo.emailProtocollatore];
    this.mail.buildStringDestinatari();

    this.buildElencoFile();

  }

  ngOnInit(){}

  buildElencoFile() : void{
    this.listaAllegati.forEach(element =>
      this.mappaFile.set(element.pk.progr, new File([""], element.fileOrig ))
    );
  }


  /**
   * Metodo che dato un allegato viene eliminato dalla lista degli allegati della mail
   * @param allegato
   */
  deleteAllegato(allegato : AllegatoObj) : void{
    if(allegato.pk){
      this.mail.metadati = this.mail.metadati.filter(element => element.pk.progr != allegato.pk.progr);
      this.mappaFile.delete(allegato.pk.progr);
    }
  }

  selectAllegato(allegato : AllegatoObj) : void{
    if(allegato != undefined && allegato.url != null && allegato.url != ""){
      this.showAllegato.emit(allegato);
    }else{
      let formData : FormData = new FormData();
      formData.append("file", this.mappaFile.get(allegato.pk.progr));
      formData.append("metadati", JSON.stringify(allegato ? allegato : new AllegatoObj()));
      this.requestService.postMultipartRequestClient("uploadFileToPreview", formData,
        (response) => {
          allegato.url = response.obj.url;
          allegato.converted = response.obj.converted;
          allegato.signer = response.obj.signer;
          this.showAllegato.emit(allegato);
        },
        ()=>{//in caso di errore vedere come far vedere l'errore
          this.showAllegato.emit(allegato);
        }
      );
    }
  }

  /**
   * Metodo che serve a riempire il campo file con il file selezionato
   * @param event
   */
    onChangeFile(event) : void{
      let files = event.target.files;
      if(files)
      {
        for (let file of files) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e: any) => {
            let progrGenerated = -(Math.floor(Math.random() * 999));
            this.mappaFile.set(progrGenerated, file);
            this.mail.metadati.push(
              new AllegatoObj({
                pk : {annoProt : null, numProt : null, progr : progrGenerated},
                fileOrig : file.name
              })
            )
          }
        }
      }
    }

  /**
   * Metodo che effettua i vari controlli per poi inviare la mail se tutto va bene
   */
  invia(){
    this.errorStringA = "";
    this.errorStringCc = "";
    this.errorObbligatorio = false;
    this.mail.buildDestinatri();
    let check = this.mail.checkEmail();

    if(this.mail.destinatariA.length == 0)
    {
      this.errorObbligatorio = true;
    }
    else if(!check)
    {
      let formData : FormData = new FormData();

      let obj : any = Utility.deepCopyObject(this.mail);

      delete obj.pattern;
      delete obj.stringDestinatariA;
      delete obj.stringDestinatariCc;
      formData.append("dati", JSON.stringify(obj));


      this.mappaFile.forEach((file: File, key: number) => {
        formData.append("files", file);
      });

      this.requestService.postMultipartRequestClient("scriviEml", formData,
        (success) => {
          if(success.obj){
            this.disableAllField = true;
            this.colorAlert = "success";
            this.messAlert = "Email inviata correttamente ai destinatari";
            this.showAlert = true;
          }
          else
          {
            this.colorAlert = "danger";
            this.messAlert = "Si è verificato un problema";
            this.showAlert = true;
          }
        }
      );


    }
    else
    {
      check?.stringDestA.forEach(element => {
        this.errorStringA += element.trim() + "; ";
      });

      check?.stringDestCc.forEach(element => {
        this.errorStringCc += element.trim() + "; ";
      });
    }
  }

  /**
   * Funzione utilizzata nell'html per prendere il nome dell'icona
   *
   * @param filename  : nome del file
   * @returns         : ritorna il valore preso dalla funzione Utility.getFileClass();
   */
  getIconByName(filename : string) : string{
    return getFileClass(filename);
  }

}

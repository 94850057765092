import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { checkIfRequired } from 'src/app/Utility/UtilityForm';



@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  @Input() textErroreView : string = "Campo obbligatorio";
  @Input() labelTextarea : string = "";
  @Input() justChecked : boolean = true;
  @Input() formField : FormControl;
  @Input() rows : number = 3;
  @Input() maxlength : number = 99999;
  @Input() disabled : boolean = false;

  requiredSymbol : boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.requiredSymbol = checkIfRequired(this.formField);
  }

  /**
   * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
   *
   * field : nome del campo da verificare
   *
   * @returns il valore true se il campo non è valido
   */
  checkControlName() : boolean{
    return this.justChecked && !this.formField.valid;
  }

}

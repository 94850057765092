import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from 'src/app/component/sub-component/modal/modal.component';
import { OptionModal } from 'src/app/component/sub-component/modal/OptionModal';
import { ReferenteEnteDitta } from 'src/app/model/ReferenteEnteDitta';
import { ReferentePersona } from 'src/app/model/ReferentePersona';
import { RequestService } from 'src/app/service/requestService/request.service';
import { ResizeService } from 'src/app/service/resize/resize.service';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { Utility } from 'src/app/Utility/Utility';
import * as _ from 'lodash';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatterService } from 'src/app/service/CustomDateParserFormatter/custom-date-parser-formatter.service';
import { DataStringTransformPipe } from 'src/app/pipe/dataStringTransform/data-string-transform.pipe';
import { Referente } from 'src/app/model/Referente';

@Component({
  selector: 'app-form-referente',
  templateUrl: './form-referente.component.html',
  styleUrls: ['./form-referente.component.scss']
})
export class FormReferenteComponent implements OnInit {

  //****************************************************** */
  //Variabili ulitilizzate per dichiarare e inizializzare la modale
  //****************************************************** */
  @ViewChild('dynamicModalComponent')
  dynamicModal : ModalComponent;
  optionModal : OptionModal = new OptionModal();
  //****************************************************** */
  //****************************************************** */

  @ViewChild('dataNascitaInput') dataNascitaInput : NgbInputDatepicker;

  showSave : boolean = true;
  showInit : boolean = false;

  loading : boolean = false;

  mapCodTipoReferente : any = {
    "P" : 1, //Persona
    "E" : 2, //Ente - Ditta
    "D" : 3, //Dipendente
    "UO" : 4,//Unità organizzativa
    "PA" : 5 //Pubblica amministrazione
  }

  inserimentoMode : boolean = true;


  disalbeForm : boolean = false;
  justChecked : boolean = false;

  //P = PERSONA, E = ENTE
  referenteTipo : string = "P";
  referenteCodice : any = null;
  referenteForm : FormGroup;

  size : SCREEN_SIZE;

  mapSectionOpened : any = {
    sectionDatiAnagrafici : true,
    sectionDatiFiscali : true,
    sectionResidenza : true,
    sectionDomicilio : true
  }

  @Input()
  objParameterComponent : any;

  riportaAnagrafica : (referente : Referente) => void;

  referenteCreato : Referente;


  //Variabile che serve a sapere se il component è invocato da una modale;
  modalMode : boolean = false;
  showAlert : boolean = false;
  messageAlert : string = "";

  //Variabile per capire se la form è in modalità update o insert
  updateMode : boolean = false;

  showRiporta : boolean = false;

  constructor(private formBuilder : FormBuilder, private resizeSvc : ResizeService, private requestService : RequestService ) {

    this.size = this.resizeSvc.onResize();
    this.initForm();

  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  initForm(){
    this.showSave = true
    this.showInit = false;
    this.disalbeForm = false;
    this.showAlert = false;
    this.referenteForm = this.formBuilder.group(this.buildObjForm(this.referenteTipo));
    this.openAllSections(true);
  }

  /**
   * Funzione che ritorna come oggetto i campi del form che deve essere inizializzato
   *
   * @param mode P = PERSONA, E = ENTE
   * @returns obj : oggetto con cui viene inizializzato il form
   */
  buildObjForm(mode : string) : any{
    let obj : any = {};

    //Se è PERSONA
    if(mode == "P")
    {
      obj = {
        nome : new FormControl(""),
        cognome : new FormControl(""),
        dataNascita : new FormControl(""),
        natoA : new FormControl(""),
        provinciaNascita : new FormControl("", Validators.maxLength(2)),
        paese : new FormControl(""),
        viaDomicilio : new FormControl(""),
        numDomicilio : new FormControl(""),
        comuneDomicilio : new FormControl(""),
        capDomicilio : new FormControl(""),
        provinciaDomicilio : new FormControl("", Validators.maxLength(2)),
        titolo : new FormControl("")
      };
    }
    else if (mode == "E")//SE è ENTE
    {
      obj = {
        ragioneSociale : new FormControl(""),
        partitaIva : new FormControl("", Validators.maxLength(12)),
        fax : new FormControl("")
      }
    }

    //Attributi comuni alle 2 form
    obj.cf = new FormControl("", Validators.maxLength(16));//Vedere se aggiungere un qualcosa per validare il codice fiscale
    obj.email = new FormControl("", Validators.email);
    obj.inviaSemprePerMail = new FormControl(false);
    obj.bloccaReferente = new FormControl(false);
    obj.viaResidenza = new FormControl("");
    obj.numResidenza = new FormControl("");
    obj.comuneResidenza = new FormControl("");
    obj.capResidenza = new FormControl("");
    obj.nazioneResidenza = new FormControl("");

    obj.provinciaResidenza = new FormControl("", Validators.maxLength(2));
    //Il telefono lo gestisco come per le scadenze nel form di
    //protocollazione e non come nell'interfaccia cosi possiamo inserire più numeri
    obj.telefono1 = new FormControl("");
    obj.telefono2 = new FormControl("");

    return obj;
  }

  //************GETTER DEL FORM****************
  //*******************************************

  get nome(){
    return this.referenteForm.get("nome");
  }

  get cognome(){
    return this.referenteForm.get("cognome");
  }

  get email(){
    return this.referenteForm.get("email");
  }

  get cf(){
    return this.referenteForm.get("cf");
  }

  get dataNascita(){
    return this.referenteForm.get("dataNascita");
  }

  get natoA(){
    return this.referenteForm.get("natoA");
  }

  get provinciaNascita(){
    return this.referenteForm.get("provinciaNascita");
  }

  get paese(){
    return this.referenteForm.get("paese");
  }

  get viaResidenza(){
    return this.referenteForm.get("viaResidenza");
  }

  get numResidenza(){
    return this.referenteForm.get("numResidenza");
  }

  get comuneResidenza(){
    return this.referenteForm.get("comuneResidenza");
  }

  get capResidenza(){
    return this.referenteForm.get("capResidenza");
  }

  get provinciaResidenza(){
    return this.referenteForm.get("provinciaResidenza");
  }

  get telefono1(){
    return this.referenteForm.get("telefono1");
  }

  get telefono2(){
    return this.referenteForm.get("telefono2");
  }


  get viaDomicilio(){
    return this.referenteForm.get("viaDomicilio");
  }

  get numDomicilio(){
    return this.referenteForm.get("numDomicilio");
  }

  get comuneDomicilio(){
    return this.referenteForm.get("comuneDomicilio");
  }

  get capDomicilio(){
    return this.referenteForm.get("capDomicilio");
  }

  get provinciaDomicilio(){
    return this.referenteForm.get("provinciaDomicilio");
  }

  get fax(){
    return this.referenteForm.get("fax");
  }

  get ragioneSociale(){
    return this.referenteForm.get("ragioneSociale");
  }

  get partitaIva(){
    return this.referenteForm.get("partitaIva");
  }

  get bloccaReferente(){
    return this.referenteForm.get("bloccaReferente");
  }

  checkAndInitIfModal(){

    if(this.objParameterComponent != null)
    {
      this.modalMode = this.objParameterComponent.modalMode;
      this.riportaAnagrafica = this.objParameterComponent.riportaAnagrafica;

      if(!this.objParameterComponent.addReferente){
        this.inserimentoMode = true;
        let tipo = this.objParameterComponent.referenteTipo;
        let codice = this.objParameterComponent.referenteCodice;

        this.updateMode = Utility.checkObj(tipo) && Utility.checkObj(codice);

        if(this.updateMode)
        {
          this.referenteTipo = tipo == 1 ? "P" : "E";
          this.initForm();
          this.referenteCodice = codice;
        }
        this.loadReferente();
      }
      else
      {
        this.inserimentoMode = false;
      }
    }
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() : void{
    this.checkAndInitIfModal();
    this.dataNascitaInput.registerOnChange((value) => {
      this.dataNascita.setValue(CustomDateParserFormatterService.convertNgbDateStructToString(value));
    });
  }

  openAllSections(expand: boolean) {
    Utility.openAllSections(expand, this.mapSectionOpened);
  }

  checkSectionOpened(idSection : string) : boolean{
    return Utility.checkSectionOpened(idSection, this.mapSectionOpened);
  }

  /**
   * Funzione utilizzata per aprire e chiudere le sezioni della form
   *
   * @param idSection : id della sezione da chiudere/aprire (l'id deve essere lo stesso che viene inserito nella mapSectionOpened)
   */
  openSection(idSection : string) : void
  {
    Utility.openSection(idSection, this.mapSectionOpened);
  }

  /**
   * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
   *
   * field : nome del campo da verificare
   *
   * @returns il valore true se il campo non è valido
   */
  checkControlName(field) : boolean{
    return this.justChecked && !this.referenteForm.controls[field].valid;
  }


  //Metodo che effettua il submit del form
  submit() : void{

    this.justChecked = true;
    if(this.referenteForm.valid)
    {
      let ref : any;
      this.disalbeForm = true;

      if(this.referenteTipo == "E")
      {
        ref = new ReferenteEnteDitta(this.referenteForm.getRawValue());
      }
      else
      {
        ref = new ReferentePersona(this.referenteForm.getRawValue());
      }

      ref.referenteTipo = this.mapCodTipoReferente[this.referenteTipo];
      ref.referenteCodice = this.referenteCodice != null ? this.referenteCodice : null;



      this.requestService.postRequest("insertReferente", ref,
        (success) => {

          let messaggio : string = "L'anagrafica " + (ref.referenteTipo == 1 ? ref.cognome + " " + ref.nome : ref.ragioneSociale) + " è stata " + (this.inserimentoMode ? "inserita" : "modificata" ) + " con successo";

          if(this.modalMode)
          {
            this.showAlert = true;
            this.messageAlert = messaggio;

            if(this.riportaAnagrafica) {
              this.showRiporta = true;
              //Qua bisogna salvare un referente

              this.referenteCreato = new Referente({
                referenteCodice : success.obj.codice,
                referenteTipo : success.obj.tipo.codTipoReferente,
                inviaSemprePerMail : success.obj.tipo1NotificaMail == 1,
                bloccaReferente : success.obj.tipo2Bloccato == 1,
                descrizione : success.obj.tipo2Description
              });


            }

          }
          else
          {
            this.optionModal = new OptionModal();
            this.optionModal.titolo = "Anagrafica "+ (this.inserimentoMode ? "inserita" : "modificata" ) +" con successo";
            this.optionModal.textBodyModal = messaggio;

            this.optionModal.showSaveButton = false;
            this.optionModal.showCloseButton = true;
            this.optionModal.setClassSizeMaxHeightModalBody("m");

            this.dynamicModal.initModal(this.optionModal);
            this.dynamicModal.openModal();
          }

          this.showSave = false
          this.showInit = true;

        },
        () => {

          this.optionModal = new OptionModal();
          this.optionModal.titolo = "Anagrafica non inserita";
          this.optionModal.textBodyModal = "C'è stato un problema con l'inserimento, riprova più tardi";

          this.optionModal.showSaveButton = false;
          this.optionModal.showCloseButton = true;
          this.optionModal.setClassSizeMaxHeightModalBody("m");

          this.dynamicModal.initModal(this.optionModal);
          this.dynamicModal.openModal();
          this.disalbeForm = false;
        }
      )
    }
  }

  riportaAnagraficaSuForm(){
    this.riportaAnagrafica(new Referente(this.referenteCreato));
  }

  /**
   * Metodo che viene chiamato solo quando il form è in modalità update.
   * Effettua una chiamata al server e prende i dati del referente per caricarli
   * all'interno del form
   */
  loadReferente() : void{

    let payload = {
      referenteCodice : this.referenteCodice,
      referenteTipo : this.mapCodTipoReferente[this.referenteTipo]
    };
    this.requestService.postRequest("loadReferente", payload,
      (success) => {


        setTimeout(() => {
          this.referenteForm.patchValue(_.pickBy(success.obj));
          this.dataNascitaInput.manualDateChange(CustomDateParserFormatterService.convertNgbDateStructToString(Utility.convertDateServerToNgbDate(success.obj.dataNascita)), true);
        }, 0);

      }
    )
  }

}

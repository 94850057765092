<div class="d-flex flex-column w-100">
    <label class="title-section align-items-center  d-flex justify-content-between">
        <div class="d-flex align-items-center w-100">
            <ng-content select="[titleSezione]"></ng-content>
            <!--<span *ngIf="justChecked || checkSezioniCompilate(idSingleSection)" class="exlamation-icon">
                <i *ngIf="!justChecked && checkSezioniCompilate(idSingleSection)" title="Campi compilati" class="icon-pencil"></i>
                <i *ngIf="justChecked && checkSezioniCompilate(idSingleSection)" title="Campi obbligatori compilati" class="icon-ok text-success"></i>
                <i *ngIf="justChecked && !checkSezioniCompilate(idSingleSection)" title="Campi obbligatori non compilati" class="icon-attention-alt text-danger"></i>
            </span>
        -->
        </div>
        <i *ngIf="sectioneditable" (click)="openSection(idSingleSection)" class="cursor-pointer icon-{{checkSectionOpened(idSingleSection) ? 'up' : 'down'}}-open"></i>
    </label>
    <div class="w-100 d-flex flex-column" [ngClass]="{'max-with-1400' : !fullWidth && false}" *ngIf="checkSectionOpened(idSingleSection) || !sectioneditable">
        <ng-content select="[bodySezione]"></ng-content>

    </div>
</div>

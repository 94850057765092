
<div class="d-flex flex-column h-100">
    <div *ngIf="checkUrl() && showPrevew;"  style="height: 100%;">
        <ng2-pdfjs-viewer *ngIf="!isZip(allegato?.url); else zipFile"
            pdfSrc="{{url}}"
            [download]="allegato?.applic == 'STAMPA'"
            [print]="allegato?.applic == 'STAMPA'"
            [openFile]="false"
            [viewBookmark]="false"
            locale='it-IT'
        ></ng2-pdfjs-viewer>

    </div>

    <div style="height: 100%;" *ngIf="checkSigner() && showDatiFirma">
        <app-firmatario [listaFirmatari]="listaFirmatari"></app-firmatario>
    </div>

    <ng-template *ngIf="loading">
        <app-loading class="h-100" *ngIf="!previewNotAvaible; else warningPreview" [text]="'Caricamento preview'"></app-loading>
    </ng-template>


    <ng-template #warningPreview>
        <div class="d-flex h-100 w-100 justify-content-center align-items-center">
            <h2>Anteprima non disponibile</h2>
        </div>

    </ng-template>

    <ng-template #zipFile>
      <div class="d-flex h-100 w-100 justify-content-center align-items-center">
        <h2 class="text-center">Documento in formato ZIP, eseguire il download per visualizzarne il contenuto.</h2>
      </div>
    </ng-template>

    <div class="w-100 d-flex justify-content-around  mt-3" *ngIf="allegato?.applic != 'STAMPA'">
        <div class="d-flex flex-column align-items-center text-primary-archipro cursor-pointer" style="font-size: 20px;" (click)="showFirmatario(false)">
            <i class="icon-doc-text"></i>
            <span>Preview</span>
        </div>

        <div class="d-flex flex-column align-items-center text-primary-archipro cursor-pointer" style="font-size: 20px;" (click)="checkSigner() && showFirmatario(true)" [ngClass]="{'disabled text-icon-disabled' : !checkSigner()}">
            <i class="icon-doc-certificate"></i>
            <span>Firmatario</span>
        </div>

        <a class="d-flex flex-column align-items-center text-primary-archipro cursor-pointer" style="font-size: 20px;" [href]="downloadUrl" download> <!--download="{{this.objParameterComponent.file.name}}"-->
            <i class="icon-download"></i>
            <span>Download</span>
        </a>

    </div>
</div>

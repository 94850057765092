import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Form, FormArray, FormControl } from '@angular/forms';
import { RequestriferimentiForm } from 'src/app/model/RequestRiferimentiForm';
import { RequestService } from 'src/app/service/requestService/request.service';
import { getLabelAgd, getLabelUo } from 'src/app/Utility/Utility';
import { UtilityCompetenze } from 'src/app/Utility/UtilityCompetenze';
import { ModalComponent } from '../../modal/modal.component';
import { OptionModal } from '../../modal/OptionModal';

@Component({
  selector: 'app-competenza-conoscenza',
  templateUrl: './competenza-conoscenza.component.html',
  styleUrls: ['./competenza-conoscenza.component.scss']
})
export class CompetenzaConoscenzaComponent implements OnInit {

  @Input() dynamicModal : ModalComponent;
  @Input() optionModal : OptionModal = new OptionModal();
  @Input() competenze = new FormArray([])
  @Input() justChecked: boolean = true;

  @Input() hiddenOpenSearch : boolean = false;
  @Input() updateCompetenze : EventEmitter<FormArray> = new EventEmitter<FormArray>();
  @Input() listAgdUoScelti : any = [];


  utilityCompetenze : UtilityCompetenze;


  agd : FormControl = new FormControl("");
  uo : FormControl = new FormControl("");
  cognome : FormControl = new FormControl("");
  competenza : FormControl = new FormControl("competenza");

  getLabelAgd : () => string = getLabelAgd;
  getLabelUo : () => string = getLabelUo;

  constructor(private requestService : RequestService) {

  }

  ngOnInit(): void {
    this.utilityCompetenze = new UtilityCompetenze(this.dynamicModal, this.optionModal, this.requestService);
    this.utilityCompetenze.checkChangeDisableAgdUoCognome(this.agd, this.uo, this.cognome);
  }

  /**
   * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
   *
   * field : nome del campo da verificare
   *
   * @returns il valore true se il campo non è valido
   */
     checkControlName(field) : boolean{
      return this.justChecked && !this[field].valid;
    }

  //************************************************************************** */
  //*************************** GESTIONE COMPETENZE ************************** */
  //************************************************************************** */

  /**
   *
   * Metodo utilizzato per inizializzare e aprire la modale
   * con la lista degli agd uo da scegliere (questo avviene per utente)
   */
  openListAgdUoPerUtente(list : any) : void{
    this.utilityCompetenze.openListAgdUoPerUtente(list, this.addAgdUoScelto);
  }

  checkListVerificaAgdUoCognome(listTemp : any, competenza : string)
  {
    this.utilityCompetenze.checkListVerificaAgdUoCognome(this.listAgdUoScelti, listTemp, competenza, this.competenze, this.resetField, this.addAgdUoScelto);
  }

  /**
   *
   * Metodo utilizzato per inizializzare e aprire la modale
   * con la lista degli agd uo da scegliere
   */
  openListAgdUo() : void{
    this.utilityCompetenze.openListAgdUo(this.listAgdUoScelti, this.addAgdUoScelto);
  }

  cercaPerCognome(competenza : string){
    this.utilityCompetenze.cercaPerCognome(this.listAgdUoScelti, competenza, this.cognome, this.competenze, this.resetField, this.addAgdUoScelto)
  }

  /**
   * Metodo che verifica lato client se esiste un agd uo con i parametri selezionati
   * una volta fatta la ricerca se andata a buon segno, inserisce nella lista la competenza, altrimenti
   * manda un alert dicendo che non esiste
   */
  verificaCompetenzaConoscenza(){
    let objRequest = new RequestriferimentiForm();

    objRequest.competenza = this.competenza.value;
    objRequest.agd = this.agd.value;
    objRequest.uo = this.uo.value;
    objRequest.cognome = this.cognome.value;

    this.utilityCompetenze.verificaCompetenzaConoscenza(this.listAgdUoScelti, objRequest, this.cognome, this.competenze, this.resetField, this.addAgdUoScelto);
  }

  resetField = () : void =>{
    this.agd.reset("");
    this.uo.reset("");
    this.cognome.reset("");

    this.agd.enable();
    this.uo.enable();
    this.cognome.enable();
  }

  /**
   * Elimina un elemento dalla lista degli agd uo scelti
   *
   * @param element : elemento da eliminare dalla lista
   */
  eliminaAgdUoScelto(element : any) : void {
    this.listAgdUoScelti = this.utilityCompetenze.eliminaAgdUoScelto(this.listAgdUoScelti, element, this.competenze);
  }

  /**
   * Metodo passato al component della modale che aggiunge alla lista (presente all'intenro della formd i protocollazione)
   * l'elemento scelto
   *
   * @param agdUo
   */
  addAgdUoScelto = (agdUo : any, close? : boolean) : void => {
    let elementoTrovato = this.listAgdUoScelti.find((x) => {return x.codicePadre == agdUo.codicePadre && x.codice == agdUo.codice});
    if(elementoTrovato == undefined) {
        this.listAgdUoScelti.push(agdUo);
    }
    else{
        this.listAgdUoScelti = this.listAgdUoScelti.filter(x => {return !(x.codicePadre == agdUo.codicePadre && x.codice == agdUo.codice)});
    }

    if(close)
    {
        this.dynamicModal.closeModal();
    }

    this.utilityCompetenze.convertListAgdUoSceltiToCompetenzeForm(this.listAgdUoScelti, this.competenze);
    this.updateCompetenze.emit(this.competenze);
    this.competenze.markAsDirty();
  }

}

import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { timeout } from 'rxjs/operators';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { AllegatoObj } from './../../../model/AllegatoObj';
import { RequestService } from './../../../service/requestService/request.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-viewer-form-protocollazione',
  templateUrl: './viewer-form-protocollazione.component.html',
  styleUrls: ['./viewer-form-protocollazione.component.scss']
})
export class ViewerFormProtocollazioneComponent implements OnInit {


  @Input()
  objParameterComponent : any;

  url : string = "";

  allegato : AllegatoObj = new AllegatoObj() ;

  listaFirmatari : any = [];

  showDatiFirma : boolean = false;
  showPrevew : boolean = true;
  loading : boolean = true;

  downloadUrl : any = "";
  previewNotAvaible : boolean = false;

  constructor(private sanitizer : DomSanitizer) { }

  ngOnInit(): void {

  }

  get downloadUrlOriginFile(){
    if(!!this.allegato)
      return environment.url + "downloadDocumentoProtocollo/" +
        this.allegato.pk?.annoProt + "/" +
        this.allegato.pk?.numProt + "/" +
        this.allegato.pk?.progr;

    return "";
  }

  ngAfterViewInit() : void {
    this.allegato = this.objParameterComponent.allegato;
    //console.log("ciao ciao ciao ", this.allegato)
    this.listaFirmatari = this.allegato?.signer;

    this.url = this.allegato?.url;
    this.downloadUrl = this.url

    if(!!this.allegato && !!this.allegato.pk && !!this.allegato.url)
    {
      this.downloadUrl = this.allegato.converted ? this.downloadUrlOriginFile : this.url;
    }
    else
    {
      this.downloadUrl = URL.createObjectURL(this.objParameterComponent.file);
      this.downloadUrl = this.sanitizer.bypassSecurityTrustUrl(this.downloadUrl);
    }

    //Commentato perchè utilizzato solo nel caso in cui i file li prendiamo
    // direttamente dalla cache del browser mentre i file vengono invece
    // caricati sempre anche durante le preview
    /*

    */



    this.loading = !this.checkUrl();
    setTimeout(()=>{ this.previewNotAvaible = true }, 3000);

  }

  isZip(fileName : string){
    return fileName?.split(".").slice(-1)[0].toLowerCase() == "zip";
  }

  /**
   * Apre e chiude i dati del firmatario all'interno della preview
   *
   * @param stato : se true apre i dati firmatario, altrimenti li nasconde
   */
  showFirmatario(stato : boolean) : void{
    this.showDatiFirma = stato;
    this.showPrevew = !stato;
  }

  checkUrl() : boolean
  {
    return this.url != undefined && this.url != null && this.url != "";
  }

  checkSigner() : boolean{
    return this.listaFirmatari != null && this.listaFirmatari.length > 0;
  }


}

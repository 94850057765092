<div id="dettaglio-email" class="d-flex flex-column h-100 justify-content-between" *ngIf="!searchInProgress; else loading">
    <!--Dati della mail-->
    <!--**************************************************************-->
    <div class="d-flex flex-column info-mail p-1" [ngClass]="{'minimize-info-mail' : !showInfoMobile}">

        <div class="d-flex justify-content-between">
            <small>{{mail.dataInvio30 | dataStringTransform}}</small>
            <i class="cursor-pointer icon-{{showInfoMobile ? 'up' : 'down'}}-open " (click)="showInfoMobile = !showInfoMobile"></i>
        </div>

        <span class="mittente">{{mail.mittente}}</span>
        <span class="oggetto">{{mail.oggetto}}</span>
        <small class="d-flex flex-column">
            <span>
                A: {{mail.destinatario}}
            </span>
            <span *ngIf="false">
                Cc:
            </span>
        </small>
    </div>

    <!--**************************************************************-->


     <!--Allegati-->
     <div *ngIf="mail.listaAllegati.length > 0" class="container-allegati-mail d-flex w-100 align-items-center">
        <div *ngFor="let allegato of mail.listaAllegati; let i = index" class="container-file d-flex align-items-center m-2 allegato border cursor-pointer"
        [ngClass]="{'border-primary' : allegato.active}" (click)="selectAllegato(allegato)">
            <i class="{{getIconByName(allegato.nome)}}"></i>
            <div class="d-flex flex-column">
                <small class="cut-text" title="{{allegato.nome}}">{{allegato.nome}}</small>
                <small class="text-icon-disabled">{{allegato.formato}}</small>
            </div>

        </div>
    </div>


    <span *ngIf="showAllegato" class="text-primary-archipro cursor-pointer mt-2 font-weight-bold" (click)="showDettaglio()"><i class="icon-lef-big"></i>Torna alla mail</span>
    <!-- Corpo della mail -->
    <div *ngIf="!showAllegato" id="body-mail" class="d-flex w-100 border rounded p-3 mt-2 overflow-auto" [innerHTML]="mail.bodyMail"></div>

    <!-- Viewer allegato -->
    <div *ngIf="showAllegato" id="allegatoViewer" class="d-flex border rounded mt-2 h-100 overflow-auto">
        <ng2-pdfjs-viewer
            [pdfSrc]="allegatoSelezionato.url"
            [download]="false"
            [print]="false"
            [openFile]="false"
            [viewBookmark]="false"
            style="display: block; height: 100%;"
            locale='it-IT'
            class="flex-fill"
        ></ng2-pdfjs-viewer>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<section class="d-flex flex-column w-100 h-100">
    <section id="header-page" class="border-bottom p-3 align-items-center">
        <div class="d-flex text-primary-archipro justify-content-between align-items-center pt-3 pb-2">
            <h4 class="">
                <span class="cursor-pointer" (click)="breadCrumb.back()">Elenco {{globalService.GLOBAL.PRO_LABEL_RIFERIMENTI}} </span>
                <span *ngIf="breadCrumb.scegliRiferimento" class="breadCrumbTitle">
                    <i class="icon-right-open ml-2 mr-2"></i>
                    <span class="cursor-pointer" [ngClass]="{'breadcrumb-active' : breadCrumb.scegliRiferimento}">Aggiungi {{globalService.GLOBAL.PRO_LABEL_RIFERIMENTI}} </span>
                </span>
            </h4>
            <div class="spaced-input d-flex">
                <button type="button" class="close d-flex" data-dismiss="modal" aria-label="Close">
                    <h4 aria-hidden="true">&times;</h4>
                </button>
            </div>
        </div>
    </section>

    <!--Elenco dei riferimenti da riportare-->
    <section *ngIf="breadCrumb.checkAllFalse()" class="w-100 h-100 d-flex flex-column p-3">
        <div class="d-flex justify-content-end">
            <button type="button" class="btn bg-primary-archipro text-white" data-dismiss="modal" (click)="breadCrumb.setScegliRiferimento()">Aggiungi {{globalService.GLOBAL.PRO_LABEL_RIFERIMENTI}}</button>
        </div>
        <div *ngIf="listaRiferimentiSelezionata.length > 0; else listaVuota" class="h-100 d-flex flex-column">

            <div class="d-flex w-100">
                <table class="table table-striped border">
                    <thead class="">
                        <tr class="text-primary-archipro ">
                            <th class="size-badge-notifica text-center pl-2">Tipo</th>

                            <th scope="col" *ngIf="size>=3">
                                <span class="d-flex cursor-pointer">Descrizione</span>
                            </th>
                            <th scope="col">Azioni</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let riferimento of listaRiferimentiSelezionata; let i = index" class="lista-colonne">

                            <td class="pl-2 size-badge-notifica align-middle text-center">
                                <span *ngIf="riferimento.tipoRiferimento == 'D'" title="Documento" class="text-primary-archipro font-weight-bold">D</span>
                                <span *ngIf="riferimento.tipoRiferimento == 'P'" title="Pratica" class="text-primary-archipro font-weight-bold">P</span>
                                <span *ngIf="riferimento.tipoRiferimento == 'E'" title="Ente" class="text-primary-archipro font-weight-bold">E</span>
                                <span *ngIf="riferimento.tipoRiferimento == 'PR'" title="Ente" class="text-primary-archipro font-weight-bold">PR</span>
                            </td>

                            <td *ngIf="riferimento.tipoRiferimento != 'E'; else viewDescrEnte" class="" title="{{riferimento.descrizione}}">
                                {{riferimento.descrizione}}
                            </td>
                            <ng-template #viewDescrEnte>
                                <td class="" title="{{riferimento.descrizione}}">
                                    {{riferimento.descrizioneView}}
                                </td>
                            </ng-template>

                            <td  class="action-icon d-flex align-items-center" ClickStopPropagation>
                                <div class="border container-icon" >
                                    <i class="icon-trash text-primary-archipro" title="Rimuovi" (click)="rimuoviRiferimento(riferimento.tipoRiferimento, riferimento)"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <ng-template #listaVuota>
            <h2 class="h-100 d-flex justify-content-center align-items-center">Nessun elemento ancora selezionato</h2>
        </ng-template>
        <div class="d-flex mt-3 justify-content-end">
            <button type="button" class="btn bg-primary-archipro text-white" data-dismiss="modal" (click)="azioneRiporta()">Riporta</button>
        </div>
    </section>

    <section *ngIf="breadCrumb.scegliRiferimento" class="p-3">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Selezionare uno o più elementi da associare al documento</h6>

            <div class="form-group mb-2">
                <!--<label for="intervallo" class="text-primary-archipro">N° righe</label>-->
                <select class="form-control" [(ngModel)]="tipoElencoRiferimenti">
                    <option class="text-primary-archipro placeholder-select" disabled>Tipo riferimento</option>
                    <option [ngValue]="0">Documenti</option>
                    <option [ngValue]="1" *ngIf="globalService.GLOBAL.PRO_URL_PROCEDO">Pratiche</option>
                    <option [ngValue]="2">Enti</option>
                    <option [ngValue]="3">Persone</option>
                </select>
            </div>

        </div>
        <div class="d-flex container-riferimenti mt-3 h-100 w-100 ">

            <!--PROTOCOLLI-->
            <div class="h-100 w-100" *ngIf="tipoElencoRiferimenti == 0">
                <app-protocolli [listaRiferimentiSelezionata]="listaRiferimentiSelezionata" [rimuoviRiferimento]="rimuoviRiferimento" [size]="size"></app-protocolli>
            </div>

            <!--PRATICHE-->
            <div class="h-100 w-100" *ngIf="tipoElencoRiferimenti == 1">
                <app-pratiche [listaRiferimentiSelezionata]="listaRiferimentiSelezionata" [rimuoviRiferimento]="rimuoviRiferimento" [size]="size"></app-pratiche>
            </div>

            <!--ENTI-->
            <div class="h-100 w-100" *ngIf="tipoElencoRiferimenti == 2 || tipoElencoRiferimenti == 3">
                <app-enti [referenteTipo]="tipoElencoRiferimenti" [listaRiferimentiSelezionata]="listaRiferimentiSelezionata" [rimuoviRiferimento]="rimuoviRiferimento" [size]="size"></app-enti>
            </div>

        </div>

    </section>



</section>













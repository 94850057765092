<div class="d-flex flex-column h-100 w-100 bg-form-color overflow-auto" [ngClass]="{'p-3' : !modalMode}">
  <section id="header-page" *ngIf="!modalMode">
    <div class="d-flex text-primary-archipro justify-content-between align-items-center border-bottom pb-2">
        <h4 class="">Fascicoli / Fascicoli collegati</h4>
    </div>
  </section>
  <section id="body-page" class="d-flex flex-column">
    <section class="dettagli-documento-viewer d-flex flex-column h-100" *ngIf="showForm;else loading">
      <div class="d-flex justify-content-between">
          <span class="title-section-dettaglio">Fascicolo selezionato</span>
          <i (click)="openSection('sectionInfoFascicolo')" class="cursor-pointer icon-{{checkSectionOpened('sectionInfoFascicolo') ? 'up' : 'down'}}-open"></i>
      </div>
      <div class="dropdown-divider"></div>
      <div class="d-flex" *ngIf="checkSectionOpened('sectionInfoFascicolo')">

          <div class="info-titolario d-flex flex-column card-shadow border p-3 mb-2 bg-white w-100" *ngIf="fascicoloInfo != null">
            <div class="d-flex">
                <span class="mr-2">
                    <b>Titolario: </b>{{fascicoloInfo.titolario}} - {{fascicoloInfo.descrTitolario}}
                </span>
            </div>
            <div class="d-flex">
                <span class="mr-2" >
                    <b>Fascicolo: </b> {{fascicoloInfo.numFascVis}} / {{fascicoloInfo.fascPK?.annoFascicolo}} (<b>Progr:</b> {{fascicoloInfo.progrAnno}} / {{fascicoloInfo.fascPK?.annoFascicolo}}) -
                    <div *ngIf="GLOBALI.PRO_PG_INIZIO_FASCICOLO && fascicoloInfo.primoProtocollo"><b>Primo protocollo:</b> {{fascicoloInfo.primoProtocollo}}</div>
                    <div *ngIf="GLOBALI.PRO_SOGGETTO && fascicoloInfo.soggetto != null && fascicoloInfo.soggetto != undefined && fascicoloInfo.soggetto != ''"><b>Soggetto:</b> {{fascicoloInfo.soggetto}} </div>
                    <b>Oggetto:</b> {{fascicoloInfo.oggetto}}
                </span>
            </div>
            <div class="d-flex">
                <span>
                    <b> In carico a: </b> {{fascicoloInfo.codRice.descrizione}} ({{fascicoloInfo.codRice.codice}})
                    <ng-template  [ngIf]="fascicoloInfo.codRice.uo != null">{{fascicoloInfo.codRice.uo.descrizione}} ({{fascicoloInfo.codRice.uo.codice}}) </ng-template>
                </span>
            </div>

        </div>


      </div>

      <div class="d-flex justify-content-between">
          <span class="title-section-dettaglio">Fascicoli collegati</span>
          <i (click)="openSection('sectionFascicoliUniti')" class="cursor-pointer icon-{{checkSectionOpened('sectionFascicoliUniti') ? 'up' : 'down'}}-open"></i>
      </div>
      <div class="dropdown-divider"></div>
      <div class="d-flex" *ngIf="checkSectionOpened('sectionFascicoliUniti')">
          <ng-container *ngIf="!loadingFascicoliUniti; else loadingFascicoli">
              <div class="d-flex flex-column w-100" *ngIf="listaFascicoliUniti.length > 0;else nessunFascicoloEstratto">
                  <div class="d-flex align-items-center file-element spaced-input border bg-white" *ngFor="let fascicolo of listaFascicoliUniti; let i = index;">
                      <div class="d-flex w-100">
                          <div class="ml-2 title-file-upload cut-text" title="{{fascicolo.descrTitolario}}">
                              <span [ngClass]="{'text-danger' : false}">{{fascicolo.getFullTitolarioAndFascicoloString()}}</span>
                          </div>
                      </div>

                      <div class="d-flex w-100" *ngIf="GLOBALI.PRO_SOGGETTO">
                          <div class="ml-2 title-file-upload">
                              <span [ngClass]="{'text-danger' : false}">{{fascicolo.soggetto}}</span>
                          </div>
                      </div>

                      <div class="d-flex w-100">
                          <div class="ml-2 title-file-upload">
                              <span [ngClass]="{'text-danger' : false}">{{fascicolo.oggetto}}</span>
                          </div>
                      </div>

                      <div class="d-flex w-100">
                          <div class="ml-2 title-file-upload">
                              <span [ngClass]="{'text-danger' : false}">{{fascicolo.dataAperturaFascicolo | dataStringTransform}}</span>
                          </div>
                      </div>

                      <div class="d-flex action-icon-protocol spaced-input pt-1">
                          <div class="container-icon mt-0 bg-white" *ngIf="!modalMode">
                              <i class="cursor-pointer icon-folder-open-empty" (click)="apriFascicolo(fascicolo)" title="Apri fascicolo"></i>
                          </div>

                          <div class="container-icon empty mt-0 bg-white"></div>
                          <div class="container-icon empty mt-0 bg-white"></div>
                          <div class="container-icon empty mt-0 bg-white"></div>
                      </div>
                  </div>
              </div>
              <ng-template #nessunFascicoloEstratto>
                  <div class="d-flex w-100">
                      <div class="d-flex w-100 file-element spaced-input border bg-white">
                          <div class="d-flex w-100 ml-2 title-file-upload">
                              <h4>Nessun fascicolo da visualizzare</h4>
                          </div>
                      </div>
                  </div>
              </ng-template>
          </ng-container>
          <ng-template #loadingFascicoli>
              <div class="h-100 w-100">
                  <app-loading [text]="''"></app-loading>
              </div>
          </ng-template>
      </div>

      <form class="w-100" [formGroup]="formObj">
          <fieldset class="w-100" [disabled]="disableForm">
              <div class="d-flex flex-column w-100">
                  <div class="d-flex justify-content-between">
                      <span class="title-section-dettaglio">Fascicolo da unire</span>
                      <i (click)="openSection('sectionClassificazione')" class="cursor-pointer icon-{{checkSectionOpened('sectionClassificazione') ? 'up' : 'down'}}-open"></i>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="d-flex flex-column" *ngIf="checkSectionOpened('sectionClassificazione')">
                      <app-classificazione-fascicolazione #classificazioneComponent
                          [classificazioneRequired]="true"
                          [titolo1]="titolo1"
                          [titolo2]="titolo2"
                          [titolo3]="titolo3"
                          [titolo4]="titolo4"
                          [titolo5]="titolo5"
                          [titolo6]="titolo6"
                          [numeroFascicolo]="numFascVis"
                          [annoFascicolo]="annoFascicolo"
                          [dynamicModal]="dynamicModal"
                          [optionModal]="optionModal"
                          [justChecked]="justChecked"
                          [forcedRiportaFascicolo]="true"
                          [hiddenOpenNavigazione]="modalMode"
                          [titolarioSelezionato]="titolarioSelezionato"
                          [fascicoloSelezionato]="fascicoloSelezionato"
                          (checkClassificazioneClicked)="setCheckClassificazioneClicked($event)"
                          (exportTitolarioSelezionato)="setTitolarioSelezionato($event)"
                          (exportFascicoloSelezionato)="setFascicoloSelezionato($event)"
                          ></app-classificazione-fascicolazione>
                  </div>
              </div>
          </fieldset>
          <div class="d-flex spaced-input pb-2 justify-content-end">
              <button type="button" class="btn bg-primary-archipro text-white mt-3" (click)="onSubmit()">Collega</button>
          </div>
      </form>
  </section>
  <ng-template #loading>
      <div class="w-100 h-100">
          <app-loading [text]="'Caricamento in corso'"></app-loading>
      </div>
  </ng-template>
  </section>
</div>

<app-modal #dynamicModalComponent></app-modal>

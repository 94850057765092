import { Component, Input, OnInit, HostListener, ViewChild } from '@angular/core';
import { Utility, downloadFileByUrl, openNewTab } from "src/app/Utility/Utility";
import { SCREEN_SIZE } from "src/app/service/resize/screen-size.enum";
import { RequestService } from './../../../../../../service/requestService/request.service';
import { ListaDocNavigazioneRequest } from '../../../../../../model/ListaDocNavigazioneRequest';
import { ResizeService } from './../../../../../../service/resize/resize.service';
import { NavigazioneComponent } from '../../navigazione.component';
import { ViewerComponent } from './../../../../../sub-component/viewer/viewer.component';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';
import { OptionModal } from 'src/app/component/sub-component/modal/OptionModal';
import { ModalComponent } from 'src/app/component/sub-component/modal/modal.component';
import { ElementPdfViewerComponent } from 'src/app/component/sub-component/element-pdf-viewer/element-pdf-viewer.component';
import { ListaEstrattiComponent } from 'src/app/component/sub-component/lista-estratti/lista-estratti.component';

declare var $: any;

@Component({
  selector: "app-documenti",
  templateUrl: "./documenti.component.html",
  styleUrls: ["./documenti.component.scss"],
})
export class DocumentiComponent implements OnInit {

  //****************************************************** */
  //Variabili ulitilizzate per dichiarare e inizializzare la modale
  //****************************************************** */3
  @ViewChild('dynamicModalComponent')
  dynamicModal : ModalComponent;
  optionModal : OptionModal = new OptionModal();

  @Input()
  titolarioSelezionato: any;

  @Input()
  fascicoloSelezionato: any;

  @Input()
  apriDettaglioDocumentiPerModal : (documento : any) => void;

  //Variabile di input utilizzata quando questa lista non viene utilizzata nella navigazione
  @Input() modalModeInput : boolean = false;

  listDoc: any = [];

  objRequest: ListaDocNavigazioneRequest = new ListaDocNavigazioneRequest();

  searchInProgress: boolean = false;

  size: SCREEN_SIZE;

  tipoDoc : string = "T";

  documentoViewer : any = {};
  @ViewChild('viewer')
  vieweObj : ViewerComponent;

  //Variabili utilizzate per la paginazione
  //************************************* */
  totRecords: number;
  numPage: number = 0;
  currentPage: number = 1;
  numRow: number = 10;
  listObjPage: any = [];
  elementA: number;
  elementDa: number;
  //************************************* */

  GLOBALI : GlobalObj;

  constructor(private requestService : RequestService, private resizeSvc : ResizeService, private globalService : GlobalService) {
    this.GLOBALI = this.globalService.GLOBAL;
    this.size = this.resizeSvc.onResize();
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }


  ngOnInit(): void {
    this.objRequest.numTitolo = this.fascicoloSelezionato.fascPK.numTitolo;
    this.objRequest.annoFascicolo = this.fascicoloSelezionato.fascPK.annoFascicolo;
    this.objRequest.numeroFascicolo = this.fascicoloSelezionato.fascPK.numFascicolo;

    this.listaDocNavigazione();
    this.getPraticaFascicolo();
  }

  get modalMode() : boolean
  {
    return NavigazioneComponent.modalMode;
  }

  /**
   * Metodo utilizzato dalla paginazione per richiedere al servere la lista
   *
   * @param page : numero della pagina cliccata
   */
  changePage(page: number) : void{
    this.currentPage = page;
    this.listaDocNavigazione();
  }
  /**
   * Questo Metodo viene utilizzata per calcolare il numero di pagine che la lista ha calcolata
   * in base al numero di record della lista e al opzione di numero di righe selezionata dall'utente
   */
  buildPaginationObject() : void{
    this.numPage = Utility.calculateNumberOfPages(this.totRecords, this.numRow);
    this.listObjPage = Utility.buildPaginationObject(
      this.numPage,
      this.currentPage
    );
    this.elementA = this.currentPage * this.numRow - (this.numRow - this.listDoc.length);
    this.elementDa = this.currentPage * this.numRow - (this.numRow - 1);
  }

  /**
   * Metodo utilizzato per settare nell'oggetto di richiesta solo i campi relativi alla paginazione
   * essendo una cosa esterna ai filtri
   */
  buildDocRequestPaginationField() : void{
    this.objRequest.setNumRow(this.numRow);
    this.objRequest.setCurrentPage(this.currentPage);
  }

  getPraticaFascicolo() : void{
    if(this.GLOBALI.PRO_FASC_SU_ITER && !this.fascicoloSelezionato?.numeroPratica && !this.fascicoloSelezionato?.annoPratica){

      this.requestService.postRequest("getPraticaFascicolo", this.fascicoloSelezionato,
        success => {
          this.fascicoloSelezionato.numeroPratica = success.obj.numeroPratica;
          this.fascicoloSelezionato.annoPratica = success.obj.annoPratica;
        })

    }
  }


  listaDocNavigazione() : void{
    this.buildDocRequestPaginationField();
    this.searchInProgress = true;
    this.requestService.postRequest("searchDocumenti", this.objRequest,
      (response) => {
        this.listDoc = response.obj.list;
        this.totRecords = response.obj.totRecords;
        this.buildPaginationObject();
        this.searchInProgress = false;
      },
      () => {
        this.searchInProgress = false;
        alert("qualcosa non va");
      }
    );
  }

  openDettaglioDocumento(documento : any) : void{

    /**
     * nel caso in cui è in una modale, non deve aprire un'altra modale per il viewer ma deve chiamare il componente apposito
     */
    if(NavigazioneComponent.modalMode || this.modalModeInput)
    {
      this.apriDettaglioDocumentiPerModal(documento);
    }
    else
    {
      this.documentoViewer = documento;

      $('#viewerDettaglioDocumentiNavigazione').modal({
        keyboard: false
      });

      setTimeout(()=>{ this.vieweObj.ngOnInit(); }, 100);
    }
  }


      /**
     * Metodo che avendo selezionato il titolario e fascicolo stampa la carpetta
     */
      stampaCarpettaFascicolo() : void{
        if(this.fascicoloSelezionato){
          let payload = {
            annoFascicolo : this.fascicoloSelezionato.fascPK.annoFascicolo,
            numeroFascicolo : this.fascicoloSelezionato.fascPK.numFascicolo,
            numTitolo : this.fascicoloSelezionato.fascPK.numTitolo
          };

          this.requestService.postRequest("stampaCarpettaFascicolo", payload,
            success => {

              if(this.modalMode || this.modalModeInput)
              {
                downloadFileByUrl(success.obj.url);
              }
              else
              {
                this.optionModal = new OptionModal();

                this.optionModal.titolo = "Carpetta fascicolo";
                this.optionModal.setSizeModal("90");
                this.optionModal.maxSizeHeightModal = true;
                this.optionModal.showSaveButton = false;

                //Opzioni per settare il component da caricare dinamicamente
                //*******************************************************
                this.optionModal.setLoadDynamicComponent(true);
                this.optionModal.dynamicComponnet = ElementPdfViewerComponent;
                this.optionModal.objParameterComponent = {
                  url : success.obj.url,
                  downloadFile : true
                }

                this.dynamicModal.initModal(this.optionModal);
                this.dynamicModal.openModal();
              }
            }
          );
        }
      }

           /**
     * Metodo che avendo selezionato il titolario e fascicolo stampa IL DIARIO DEL FASCICOLO
     */
     stampaDiarioFascicolo() : void{
      if(this.fascicoloSelezionato){
        let payload = {
          annoFascicolo : this.fascicoloSelezionato.fascPK.annoFascicolo,
          numeroFascicolo : this.fascicoloSelezionato.fascPK.numFascicolo,
          numTitolo : this.fascicoloSelezionato.fascPK.numTitolo
        };

        this.requestService.postRequest("stampaDiarioFascicolo", payload,
          success => {
            if(this.modalMode || this.modalModeInput)
              {
                downloadFileByUrl(success.obj.url);
              }
              else
              {
                this.optionModal = new OptionModal();

                this.optionModal.titolo = "Diario fascicolo";
                this.optionModal.setSizeModal("90");
                this.optionModal.maxSizeHeightModal = true;
                this.optionModal.showSaveButton = false;

                //Opzioni per settare il component da caricare dinamicamente
                //*******************************************************
                this.optionModal.setLoadDynamicComponent(true);
                this.optionModal.dynamicComponnet = ElementPdfViewerComponent;
                this.optionModal.objParameterComponent = {
                  url : success.obj.url,
                  downloadFile : true
                }

                this.dynamicModal.initModal(this.optionModal);
                this.dynamicModal.openModal();
              }
          }
        );
      }
    }

      showEstratti(doc){
        this.optionModal = new OptionModal();

        this.optionModal.titolo = "Estratti (" + doc.docNumString + ")" ;
        this.optionModal.setSizeModal("lg");
        this.optionModal.showSaveButton = false;
        this.optionModal.showCloseButton = true;

        //Opzioni per settare il component da caricare dinamicamente
        //*******************************************************
        this.optionModal.setLoadDynamicComponent(true);
        this.optionModal.dynamicComponnet = ListaEstrattiComponent;
        this.optionModal.objParameterComponent = {numProt : doc.pk.numProt, annoProt : doc.pk.annoProt}

        this.dynamicModal.initModal(this.optionModal);
        this.dynamicModal.openModal();
      }

    goToGestionePratica(){
      openNewTab("/Procedo/#/pratiche/gestioneAtto/" + this.fascicoloSelezionato.numeroPratica + "/" + this.fascicoloSelezionato.annoPratica);
    }
}

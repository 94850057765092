<div class="d-flex spaced-input">
    <div class="form-group w-100 " [ngClass]="{'text-danger' : checkControlName('competenze')}">
        <label>Competenza/Conoscenza*</label>
        <select id="idCompetenza" class="form-control" [formControl]="competenza" [ngClass]="{'border-danger' : checkControlName('competenze')}">
            <option [ngValue]="'competenza'" selected>Competenza</option>
            <option [ngValue]="'conoscenza'">Conoscenza</option>
        </select>
        <div *ngIf="checkControlName('competenze')">
            <small *ngIf="competenze.errors.required" id="oggettoRequired" class="form-text">Inserire almeno un elemento</small>
            <small *ngIf="competenze.errors.soloUnaCompetenza" id="oggettoRequired" class="form-text">Non è possibile inserire più di una competenza</small>
        </div>

    </div>
    <div class="form-group w-100">
        <label>{{getLabelAgd()}}</label>
        <input [readOnly]="cognome.value != ''" type="text" class="form-control" id="agd" [formControl]="agd" aria-describedby="agdRequired" placeholder="" >
    </div>
    <div class="form-group w-100">
        <label>{{getLabelUo()}}</label>
        <input [readOnly]="cognome.value != ''" type="text" class="form-control" id="uo" [formControl]="uo" aria-describedby="uoRequired" placeholder="" >
    </div>
    <div class="form-group w-100">
        <label>Cognome</label>
        <input type="text" class="form-control" id="Cognome" [formControl]="cognome" aria-describedby="CognomeRequired" placeholder="" >
    </div>
    <div class="d-flex action-icon-protocol spaced-input pt-2">
        <div class="border container-icon">
            <i class="cursor-pointer icon-target" (click)="verificaCompetenzaConoscenza()" title="Verifica {{getLabelAgd()}}/{{getLabelUo()}} e Cognome"></i>
        </div>
        <div class="border container-icon" (click)="openListAgdUo()" *ngIf="!hiddenOpenSearch">
            <i class="cursor-pointer icon-search" title="Scegli {{getLabelAgd()}}/{{getLabelUo()}}"></i>
        </div>
        <div class="container-icon empty"></div>
        <div class="container-icon empty"></div>
        <div class="container-icon empty"></div>
    </div>
</div>

<!--container lista competenza-->
<div class="container-list-flusso w-100 d-flex flex-column container-file border"  *ngIf="listAgdUoScelti.length > 0">

    <div class="d-flex align-items-center file-element spaced-input border bg-white" *ngFor="let element of listAgdUoScelti; let i = index;" [ngClass]="{'border-danger' : checkControlName('competenze')}">
        <b class="icona-competenza-lista">{{element.activeC ? "C" : "CC"}}</b>
        <div class="ml-2 title-file-upload">
            <span>{{element.descrizionePadre ? element.descrizionePadre + " - " : ""}} {{element.descrizionePadre ? element.descrizioneRidotta : element.descrizione}} ({{element.codice}})</span>
        </div>

        <div class="d-flex align-items-center ml-auto spaced-input container-icon-file">
            <i class="cursor-pointer icon-trash" title="Elimina" (click)="eliminaAgdUoScelto(element)"></i>
        </div>
    </div>
</div>

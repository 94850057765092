import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/service/requestService/request.service';

@Component({
  selector: 'app-courtesy-page',
  templateUrl: './courtesy-page.component.html',
  styleUrls: ['./courtesy-page.component.scss']
})
export class CourtesyPageComponent implements OnInit {

  text : string = "Logout effettuato con successo!";
  typePage : TypeCourtesyPage;

  constructor(private router : Router, private requestService : RequestService) {

  }

  ngOnInit(): void {
    if(this.router.url.includes(TypeCourtesyPage.NO_ACCESS))
    {
      this.typePage = TypeCourtesyPage.NO_ACCESS;
      this.text = "Non sei autorizzato ad accedere ad ArchiPRO!";
    }
    else
    {
      this.typePage = TypeCourtesyPage.LOGOUT_SUCCESS;
      this.text = "Logout effettuato con successo!"
    }

    if(this.typePage == TypeCourtesyPage.NO_ACCESS){

      setTimeout(() => { this.requestService.getRequestClient("redirectAfterNoAccess", {}).subscribe(
        (success) => {}
      )  }, 3000)
    }
  }

}

enum TypeCourtesyPage{
  NO_ACCESS = "noAccess",
  LOGOUT_SUCCESS = "logoutSuccess"
}

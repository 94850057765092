import { Component, Input, OnInit } from '@angular/core';
import { downloadFileByUrl } from 'src/app/Utility/Utility';

@Component({
  selector: 'app-element-pdf-viewer',
  templateUrl: './element-pdf-viewer.component.html',
  styleUrls: ['./element-pdf-viewer.component.scss']
})
export class ElementPdfViewerComponent implements OnInit {

  @Input() url : string;

  @Input() page : number = 0;

  @Input() downloadFile : boolean = false;

  @Input() objParameterComponent : any;

  download : (url : string) => void = downloadFileByUrl;

  constructor() { }

  ngOnInit(): void {
    if(this.objParameterComponent != null)
    {
      this.url = this.objParameterComponent.url;
      this.page = !!this.objParameterComponent.page ? this.objParameterComponent.page : 0;
      this.downloadFile = this.objParameterComponent.downloadFile;
    }
  }

}

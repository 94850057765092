<section class="h-100 w-100 elenco-agd-uo-container">

    <div class="filtri-agd-uo d-flex spaced-input mb-2">
        <div class="form-group w-100">
            <label>{{getLabelAgd()}}</label>
            <input type="text" class="form-control" [(ngModel)]="filtroAgdUo.descrAgd">
        </div>
        <div class="form-group w-100">
            <label>{{getLabelUo()}}</label>
            <input type="text" class="form-control" [(ngModel)]="filtroAgdUo.descrUO">
        </div>
        <div class="form-group d-flex align-items-end">
            <button type="button" (click)="cercaListAgdUo()" class="btn bg-primary-archipro text-white ">Cerca</button>
        </div>
    </div>

    <div class="lista-agd-uo" *ngIf="listAgdUo.length > 0; else noList">
        <app-elenco-agd-uo-treeview *ngIf="!riportaAgdUo" [listAgdUo]="listAgdUo" [addAgdUoScelto]="addAgdUoScelto" [show]="true" [uo]="false" ></app-elenco-agd-uo-treeview>


        <app-elenco-agd-uo-treeview *ngIf="riportaAgdUo" [listAgdUo]="listAgdUo" [riporta]="true" (riportaAgdUo)="riportaAgdUo($event)" [show]="true" [uo]="false" ></app-elenco-agd-uo-treeview>
    </div>
    <ng-template #noList>
        <h4>Non ci sono elementi con i filtri inseriti</h4>
    </ng-template>


</section>

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestService } from 'src/app/service/requestService/request.service';

@Component({
  selector: 'app-elimina-protocollo',
  templateUrl: './elimina-protocollo.component.html',
  styleUrls: ['./elimina-protocollo.component.scss']
})
export class EliminaProtocolloComponent implements OnInit {

  @Input() objParameterComponent : any;

  loading : boolean = false;
  annullamentoForm : FormGroup;
  justChecked : boolean = false;
  statusMessage : string;

  constructor(private formBuilder : FormBuilder, private requestService : RequestService) {

    this.annullamentoForm = this.formBuilder.group({
      numProt : new FormControl(),
      annoProt : new FormControl(),
      motivoAnnullamento : new FormControl("", [Validators.required, Validators.maxLength(1000)])
    });
  }

  ngOnInit(): void {
    if(!!this.objParameterComponent)
    {
      this.annoProt.setValue(this.objParameterComponent.annoProt);
      this.numProt.setValue(this.objParameterComponent.numProt);
      this.motivoAnnullamento.setValue(this.objParameterComponent.motivoAnnullamento);
    }

  }

  get numProt(){
    return this.annullamentoForm.get("numProt");
  }

  get annoProt(){
    return this.annullamentoForm.get("annoProt");
  }

  get motivoAnnullamento(){
    return this.annullamentoForm.get("motivoAnnullamento");
  }

  /**
   * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
   *
   * field : nome del campo da verificare
   *
   * @returns il valore true se il campo non è valido
   */
  checkControlName(field) : boolean{
    return this.justChecked && !this.annullamentoForm.controls[field].valid;
  }

  checkControlNameAndMaxLength(field) : boolean{
    return this.checkControlName(field) || this.annullamentoForm.controls[field].errors?.maxlength;
  }

  annullaProtocollo(){
    this.justChecked = true;

    if(this.annullamentoForm.valid)
    {
      this.loading = true;
      this.requestService.postRequest("annullaProtocollo", this.annullamentoForm.getRawValue(),
        (success) => {

          this.statusMessage = "Il protocollo è stato annullato con successo";
          this.loading = false;
        }
      );
    }


  }

}

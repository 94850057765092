export class Riferimenti{
    tipoRiferimento : string = null;
    numPratica : number = 0;
    annoPratica : number = 0;
    numProtocollo : number = 0;
    annoProtocollo : number = 0;
    codRef : number = 0;
    descrizione : string = null;

    constructor(obj? : any){
        if(obj){
            this.tipoRiferimento = obj.tipoRiferimento;
            this.numPratica = obj.numPratica;
            this.annoPratica = obj.annoPratica;
            this.numProtocollo = obj.numProtocollo;
            this.annoProtocollo = obj.annoProtocollo;
            this.codRef = obj.codRef;
            this.descrizione = obj.descrizione;
        }
    }
}

<div class="h-100" *ngIf="getInfoFinished; else caricamento" style="overflow: auto;">
  <div [ngClass]="{ 'extLoginContainerDesk' : height > 830}">
      <div class="container d-flex align-items-center container-login">
        <div class="flex-column align-items-center justify-content-center  pt-5 w-100">
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex w-100" [ngClass]="{'justify-content-center' : !infoArchiPro?.mostraLogoSidi}">
              <div *ngIf="infoArchiPro?.mostraLogoSidi" class="d-flex flex-column w-50 align-items-start justify-content-center">
                <img [src]="srcLogo" [class]="classLogo" style="z-index: 1000;">

              </div>
              <div class="d-flex flex-column align-items-end" [ngClass]="{'w-50' : infoArchiPro?.mostraLogoSidi }">
                <img src="/ArchiPRO/assets/img/logo/LogoArchipro.gif" class="img-size" style="z-index: 1000;">
              </div>
            </div>
            <div class="archiproTitle d-flex flex-column w-100" [ngClass]="[size == 0 ? 'align-items-start' : 'align-items-center']">
              <h3 [ngClass]="{'text-mobile' : size == 0 }">{{infoArchiPro.titolo}}</h3>
            </div>
             <div *ngIf="userPassError" class="alert bg-custom-danger w-100 mb-4 mt-4" role="alert">
              <span class="text-white"> {{errorMessage}}</span>
            </div>
          </div>

          <fieldset *ngIf="!disalbeForm">

            <div class="btn-group btn-group-toggle w-100 border" data-toggle="buttons" *ngIf="false">
              <label class="btn btn-primary-archipro active w-50">
                <input type="radio" name="options" id="option1" autocomplete="off" [checked]="!loginModSpid" (click)="changeModLogin(false)"> Credenziali
              </label>
              <label class="btn btn-primary-archipro w-50">
                <input type="radio" name="options" id="option2" autocomplete="off" [checked]="loginModSpid" (click)="changeModLogin(true)"> Spid
              </label>
            </div>


            <div class="credential-mod mt-2" *ngIf="!loginModSpid; else spidMod">
              <form class="w-100" [formGroup]="loginForm" (ngSubmit)="doLogin()" >
                <div class="form-group"  [ngClass]="{'text-danger' : checkControlName('username')}">
                  <label for="username">Username</label>
                  <input autofocus type="text" class="form-control" id="username" formControlName="username" aria-describedby="usernameRequired" placeholder="Username" [ngClass]="{'border-danger' : checkControlName('username')}">
                  <small *ngIf="checkControlName('username')" id="usernameRequired" class="form-text">Inserisci l'username</small>
                </div>
                <div class="form-group" [ngClass]="{'text-danger' : checkControlName('password')}">
                  <label for="exampleInputPassword1">Password</label>
                  <input type="password" class="form-control" formControlName="password" aria-describedby="passwordRequired" placeholder="Password" [ngClass]="{'border-danger' : checkControlName('password')}">
                  <small *ngIf="checkControlName('password')" id="passwordRequired" class="form-text">Inserisci la password</small>
                </div>
                <button type="submit" class="btn bg-primary-archipro text-white w-100 mt-3">Login</button>
              </form>
            </div>

            <ng-template #spidMod>
              <div class="spid-mod mt-2">

                <div class="info-spid mt-3 mb-3">
                  <b>SPID</b> è il sistema di accesso che consente di utilizzare, con un'identità digitale unica, i servizi offerti da <b>ArchiPRO</b>
                  <br>
                  <a href="http://www.spid.gov.it" class="badge badge-primary-archipro" target="_blank">Info su SPID</a>
                </div>
                <div id="spidContainer" class="form-group w-100" [ngClass]="{'text-danger' : false}">

                  <form name="spid_idp_access" action="#" method="post" #form>
                    <input type="hidden" name="param_001" value="" />
                    <input type="hidden" name="param_002" value="" />
                    <input type="hidden" name="param_003" value="" />
                    <input type="hidden" name="idp_selected" [value]=idpSel />

                    <a href="#" class="italia-it-button italia-it-button-size-s button-spid" spid-idp-button="#spid-idp-button-small-post" aria-haspopup="true" aria-expanded="false">
                      <span class="italia-it-button-icon"><img src="/ArchiPRO/assets/img/spid/spid-ico-circle-bb.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-ico-circle-bb.png'; this.onerror=null;" alt="" /></span>
                      <span class="italia-it-button-text">Entra con SPID</span>
                    </a>
                    <div id="spid-idp-button-small-post" class="spid-idp-button spid-idp-button-tip spid-idp-button-relative">
                      <ul id="spid-idp-list-small-root-post" class="spid-idp-button-menu" aria-labelledby="spid-idp">
                        <li class="spid-idp-button-link" data-idp="arubaid">
                          <button class="idp-button-idp-logo" name="aruba_id" type="submit" (click)="setIDP(form,'aruba_id')">
                            <span class="spid-sr-only">Aruba ID</span><img class="spid-idp-button-logo" src="/ArchiPRO/assets/img/spid/spid-idp-arubaid.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-idp-arubaid.png'; this.onerror=null;" alt="Aruba ID" />
                          </button>
                        </li>
                        <li class="spid-idp-button-link" data-idp="infocertid">
                          <button class="idp-button-idp-logo" name="infocert_id" type="submit" (click)="setIDP(form,'infocert_id')">
                            <span class="spid-sr-only">Infocert ID</span><img class="spid-idp-button-logo" src="/ArchiPRO/assets/img/spid/spid-idp-infocertid.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-idp-infocertid.png'; this.onerror=null;" alt="Infocert ID" />
                          </button>
                        </li>
                        <li class="spid-idp-button-link" data-idp="intesaid">
                          <button class="idp-button-idp-logo" name="intesa_id" type="submit" (click)="setIDP(form,'intesa_id')">
                            <span class="spid-sr-only">Intesa ID</span><img class="spid-idp-button-logo" src="/ArchiPRO/assets/img/spid/spid-idp-intesaid.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-idp-intesaid.png'; this.onerror=null;" alt="Intesa ID" />
                          </button>
                        </li>
                        <li class="spid-idp-button-link" data-idp="namirialid">
                          <button class="idp-button-idp-logo" name="namirial_id" type="submit" (click)="setIDP(form,'namirial_id')">
                            <span class="spid-sr-only">Namirial ID</span><img class="spid-idp-button-logo" src="/ArchiPRO/assets/img/spid/spid-idp-namirialid.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-idp-namirialid.png'; this.onerror=null;" alt="Namirial ID" />
                          </button>
                        </li>
                        <li class="spid-idp-button-link" data-idp="posteid">
                          <button class="idp-button-idp-logo" name="poste_id" type="submit" (click)="setIDP(form,'poste_id')">
                            <span class="spid-sr-only">Poste ID</span><img class="spid-idp-button-logo" src="/ArchiPRO/assets/img/spid/spid-idp-posteid.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-idp-posteid.png'; this.onerror=null;" alt="Poste ID" />
                          </button>
                        </li>
                        <li class="spid-idp-button-link" data-idp="sielteid">
                          <button class="idp-button-idp-logo" name="sielte_id" type="submit" (click)="setIDP(form,'sielte_id')">
                            <span class="spid-sr-only">Sielte ID</span><img class="spid-idp-button-logo" src="/ArchiPRO/assets/img/spid/spid-idp-sielteid.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-idp-sielteid.png'; this.onerror=null;" alt="Sielte ID" />
                          </button>
                        </li>
                        <li class="spid-idp-button-link" data-idp="spiditalia">
                          <button class="idp-button-idp-logo" name="spiditalia" type="submit" (click)="setIDP(form,'spiditalia')">
                            <span class="spid-sr-only">SPIDItalia Register.it</span><img class="spid-idp-button-logo" src="/ArchiPRO/assets/img/spid/spid-idp-spiditalia.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-idp-spiditalia.png'; this.onerror=null;" alt="SpidItalia" />
                          </button>
                        </li>
                        <li class="spid-idp-button-link" data-idp="timid">
                          <button class="idp-button-idp-logo" name="tim_id" type="submit" (click)="setIDP(form,'tim_id')">
                            <span class="spid-sr-only">Tim ID</span><img class="spid-idp-button-logo" src="/ArchiPRO/assets/img/spid/spid-idp-timid.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-idp-timid.png'; this.onerror=null;" alt="Tim ID" />
                          </button>
                        </li>
                        <li class="spid-idp-button-link" data-idp="lepidaid">
                          <button class="idp-button-idp-logo" name="lepida_id" type="submit" (click)="setIDP(form,'lepida_id')">
                            <span class="spid-sr-only">Lepida ID</span><img class="spid-idp-button-logo" src="/ArchiPRO/assets/img/spid/spid-idp-lepidaid.svg" onerror="this.src='/ArchiPRO/assets/img/spid/spid-idp-lepidaid.png'; this.onerror=null;" alt="Lepida ID" />
                          </button>
                        </li>
                        <!--li class="spid-idp-button-link" data-idp="agidid">                <button class="idp-button-idp-logo" name="agid_id" type="submit"><span class="">Agid Validator</span></button>            </li-->
                        <li class="spid-idp-support-link" data-spidlink="info">
                            <a href="https://www.spid.gov.it">Maggiori informazioni</a>
                          </li>
                          <li class="spid-idp-support-link" data-spidlink="rich">
                            <a href="https://www.spid.gov.it/richiedi-spid">Non hai SPID?</a>
                          </li>
                          <li class="spid-idp-support-link" data-spidlink="help">
                            <a href="https://www.spid.gov.it/serve-aiuto">Serve aiuto?</a>
                          </li>
                          </ul>
                        </div>
                      </form>

              </div>
              </div>
            </ng-template>

          </fieldset>
      </div>
    </div>

    <div *ngIf="infoArchiPro?.mostraLoghi" class="otherLogoContainer pt-5 d-flex w-100" [ngClass]="[size == 0 ? 'flex-column' : 'flex-row']">
      <div class="d-flex flex-column align-items-start" [ngClass]="[size == 0 ? 'w-100 ' : 'w-50']">
        <img src="/ArchiPRO/assets/img/logo/HomeLogoDOCAREA.gif" class="m-2">
      </div>
      <div class="d-flex flex-column" [ngClass]="[size == 0 ? 'w-100 align-items-start' : 'w-50 align-items-end']">
        <div class="d-flex align-items-end h-100">
          <img src="/ArchiPRO/assets/img/logo/agid.png" class="m-2 aigd-image cursor-pointer" title="La suite Pianeta PA è qualificata come SaaS" (click)="agidLink()">
        </div>
      </div>
    </div>




  </div>
  <app-footer  *ngIf="!disalbeForm" [infoArchiPro]="infoArchiPro" style="z-index: 1000;"></app-footer>



</div>

  <!--PARTE DELLA MODAL -->
  <ng-template #content let-c="close" let-d="dismiss" style="z-index: 4000;">
    <div class="modal-header">
        <h4 class="modal-title text-primary-archipro" id="modal-basic-title">{{modale.titolo}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="!multiAooUo">{{modale.body}}</div>
        <app-seleziona-uo-aoo *ngIf="multiAooUo" [user]="user"></app-seleziona-uo-aoo>

    </div>
    <div class="modal-footer">
        <button [disabled]="multiAooUo && user.codUOAD == null" type="button" class="btn bg-primary-archipro text-white" (click)="continueFunction()">Continua</button>
        <button type="button" class="btn bg-primary-archipro text-white" (click)="d('Cross click')">Annulla</button>
    </div>
  </ng-template>


  <ng-template #loading let-c="close" let-d="dismiss">
    <div class="modal-body">
      <app-loading [text]="''"></app-loading>

    </div>
  </ng-template>

<ng-template #caricamento>
  <app-loading [text]="'CARICAMENTO IN CORSO'"></app-loading>
</ng-template>

import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-elenco-agd-uo-treeview',
  templateUrl: './elenco-agd-uo-treeview.component.html',
  styleUrls: ['./elenco-agd-uo-treeview.component.scss']
})
export class ElencoAgdUoTreeviewComponent implements OnInit {

  @Input()
  descrizionePadre : string = "";

  @Input()
  codicePadre : string = "";

  @Input()
  listAgdUo : any;

  @Input()
  riporta : boolean = false;

  @Input()
  addAgdUoScelto : (agd:any) => void;

  @Output()
  riportaAgdUo : EventEmitter<any> = new EventEmitter();


  /**
   * Variabile utilizzata per dire se è il livello dove vengono visualizzati gli uo oppure no (il primo livello dei figli)
   */
  @Input()
  uo : boolean;

  /**
   * Variabile utilizzata per capire se far vedere il sottolivello oppure no.
   *
   * Alla prima inizializzazione del componente deve essere per forza true
   */
  @Input()
  show : boolean;

  constructor() { }

  ngOnInit(): void {
    if(this.descrizionePadre != "")
    {
      this.listAgdUo.forEach(element => {
        element.descrizionePadre = this.descrizionePadre;
      });
    }

    if(this.codicePadre != "")
    {
      this.listAgdUo.forEach(element => {
        element.codicePadre = this.codicePadre;
      });
    }
  }


  /**
   * Funzione che indica se un elemento ha figli (quindi un sottolivello) oppure no
   *
   * @param element : Elemento dove è stato effettuato il click
   * @returns : booleano che indica se c'è un sottolivello dell'elemento
   */
    hasChild(element)
    {
      return element.children && element.children.length >= 1;
    }


    /**
     * Funzione utilizzata per aprire e chiudere un sottolivello.
     * La funzione, oltre che cambiare la variabile per visualizzare o no il sottolivello,
     * si occupa di cambiare l'icona del folder (da aperta a chiusa e viceversa)
     *
     * @param element : Elemento dove è stato effettuato il click
     */
    changeShowElement(element)
    {
      if(this.hasChild(element))
      {
        element.show = !element.show
        //element.icona = element.show ? "icon-folder-open-empty" : "icon-folder-close";
      }
    }

    /**
     *
     *
     * @param element
     * @param competenza
     */
    activeCompetenza(element: any, competenza : string) : void{
      element["active" + competenza] = !element["active" + competenza];

      this.addAgdUoScelto(element);
    }

}



import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from 'src/app/service/alertservice/alert.service';
import { TypeAlert } from './TypeAlert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() id : number;
  @Input() text : string;
  @Input() type : TypeAlert;

  constructor(public alertService : AlertService) { }

  ngOnInit() {
  }

}

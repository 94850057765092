import { LabelFlussoMap } from "src/app/model/LabelFlussoMap";

export class GlobalObj{
    PRO_COLLOCAZIONE_FISICA : boolean;
    PRO_SOGGETTO : boolean;
    PRO_PROCEDIM : boolean;
    PRO_VEDI_CARTACEO : boolean;
    PRO_MODIF_ALLEGATI : boolean;
    PRO_VEDI_SCANSIONE : boolean;
    PRO_MULTICOMPETENZA : boolean;
    PRO_CODICE_ENTE : string;
    PRO_CODICE_ENTE_2 : string;
    PRO_PROVINCIA : string;
    PRO_SRV : string;
    PRO_DES_AOO : string
    PRO_TIPO_PRINTER : string; //Per dymo o zebra o "GENERICA"
    PRO_PROG_UFFICIO : boolean;
    PRO_DATA_EFFETT : boolean;
    PRO_GESTIONE_RIFERIMENTI : boolean;
    PRO_LABEL_RIFERIMENTI : string = "riferimenti";
    PRO_GESTIONE_SCADENZE : boolean;
    PRO_COMPLETA_PROTOCOLLO : boolean;
    PRO_PDF_TITOLARIO : boolean;
    PRO_LEMMI : boolean;
    PRO_INVIA_AUTO_FASC_VUOTO : boolean;
    PRO_RICH_AUTO_INVIO_FASC : boolean;
    PRO_PROG_FLUSSO : boolean;
    PRO_URL_PROCEDO : boolean;
    PRO_MAXFILE_UPLOAD : boolean;
    PRO_LABEL_UFFICIO_PERSONA : boolean = false;
    PRO_AG : number = 0;

    PRO_CAMPI_MODIFICA_PROTOCOLLI : string = null;

    /**
     * Questa globale serve per dire che ci sono campi obbligatori oltre quelli di default
     * (oggetto, files, flusso, mittente/referenti e competenze). Se la stringa è null
     * o vuota vuol dire che nessun campo oltre quelli di default deve essere required.
     * All'interno della stringa vanno i nomi dei campi separati da virgola.
     * (per quanto riguarda il titolario che comprende i campi titolo1, titolo2, titolo3, titolo4, titolo5, titolo6, numeroFascicolo e annofascicolo
     * basterà come nome la stringa "titolario")
     */
    PRO_CAMPI_OBBLIGATORI : string = null;

    PRO_AUTOCONOSCENZA_PROTOCOLLO : boolean = false;
    PRO_MOSTRA_LOGHI : boolean = true;

    PRO_LDAP : boolean = false;

    PRO_LABEL_FLUSSO : string = "";
    PRO_TIPO_PRINTER_LABEL : string = "ArchiPRO_36x89.xml";
    //PRO_LABEL_FLUSSO : LabelFlussoMap = new LabelFlussoMap();

    PRO_SOTTOFASCICOLO : boolean = false

    PRO_INVIA_CUSTOM_MAIL : boolean = false;
    PRO_FASC_SU_ITER : boolean = false;
    PRO_REF_MULTIPLO_ENTRATA : boolean = false;

    PRO_WSANAGRAFICA_GRUPPI_SIDI : boolean = true;
    PRO_STORICIZZA_REF : boolean = false;

    PRO_PG_INIZIO_FASCICOLO : boolean = false;

    PRO_NOTEDOC : boolean = false;

    PRO_REDIRECT_LOGOUT : string = "login";

    /**
     * globale utilizzata per disattivare/attivare controllo su inserisci documento
     * che limita l'inserimento dei protocollo all'anno corrente o superiore
     *
     * Default = true
     */
    PRO_CHECK_PG_ANNO : boolean = true;

    constructor(obj? : any){
        if(obj)
        {
            this.PRO_COLLOCAZIONE_FISICA = obj.PRO_COLLOCAZIONE_FISICA;
            this.PRO_SOGGETTO = obj.PRO_CODICE_ENTE == 'p_cei' ? !obj.PRO_SOGGETTO:obj.PRO_SOGGETTO;
            this.PRO_PROCEDIM = obj.PRO_PROCEDIM;
            this.PRO_VEDI_CARTACEO = obj.PRO_VEDI_CARTACEO;
            this.PRO_MODIF_ALLEGATI = obj.PRO_MODIF_ALLEGATI;
            this.PRO_VEDI_SCANSIONE = obj.PRO_VEDI_SCANSIONE;
            this.PRO_MULTICOMPETENZA = obj.PRO_MULTICOMPETENZA;
            this.PRO_CODICE_ENTE = obj.PRO_CODICE_ENTE;
            this.PRO_CODICE_ENTE_2 = obj.PRO_CODICE_ENTE_2;
            this.PRO_PROVINCIA = obj.PRO_PROVINCIA;
            this.PRO_SRV = obj.PRO_SRV;
            this.PRO_DES_AOO = obj.PRO_DES_AOO;
            this.PRO_TIPO_PRINTER = obj.PRO_TIPO_PRINTER;
            this.PRO_PROG_UFFICIO = obj.PRO_PROG_UFFICIO;
            this.PRO_DATA_EFFETT = obj.PRO_DATA_EFFETT;
            this.PRO_GESTIONE_RIFERIMENTI = obj.PRO_GESTIONE_RIFERIMENTI;
            this.PRO_GESTIONE_SCADENZE = obj.PRO_GESTIONE_SCADENZE;
            this.PRO_COMPLETA_PROTOCOLLO = obj.PRO_COMPLETA_PROTOCOLLO;
            this.PRO_PDF_TITOLARIO = obj.PRO_PDF_TITOLARIO;
            this.PRO_LEMMI = obj.PRO_LEMMI;
            this.PRO_INVIA_AUTO_FASC_VUOTO = obj.PRO_INVIA_AUTO_FASC_VUOTO;
            this.PRO_RICH_AUTO_INVIO_FASC = obj.PRO_RICH_AUTO_INVIO_FASC;
            this.PRO_PROG_FLUSSO = obj.PRO_PROG_FLUSSO;
            this.PRO_URL_PROCEDO = obj.PRO_URL_PROCEDO;
            this.PRO_MAXFILE_UPLOAD = obj.PRO_MAXFILE_UPLOAD;
            this.PRO_LABEL_UFFICIO_PERSONA = obj.PRO_LABEL_UFFICIO_PERSONA;
            this.PRO_CAMPI_MODIFICA_PROTOCOLLI = obj.PRO_CAMPI_MODIFICA_PROTOCOLLI;
            this.PRO_CAMPI_OBBLIGATORI = obj.PRO_CAMPI_OBBLIGATORI;
            this.PRO_AUTOCONOSCENZA_PROTOCOLLO = obj.PRO_AUTOCONOSCENZA_PROTOCOLLO;
            this.PRO_LABEL_FLUSSO = obj.PRO_LABEL_FLUSSO;
            this.PRO_AG = obj.PRO_AG;
            this.PRO_LDAP = obj.PRO_LDAP;
            this.PRO_TIPO_PRINTER_LABEL = obj.PRO_TIPO_PRINTER_LABEL ? obj.PRO_TIPO_PRINTER_LABEL : "ArchiPRO_36x89.xml";
            this.PRO_SOTTOFASCICOLO = obj.PRO_SOTTOFASCICOLO;
            this.PRO_INVIA_CUSTOM_MAIL = obj.PRO_INVIA_CUSTOM_MAIL;
            this.PRO_FASC_SU_ITER = obj.PRO_FASC_SU_ITER;
            this.PRO_MOSTRA_LOGHI = obj.PRO_MOSTRA_LOGHI;
            this.PRO_REF_MULTIPLO_ENTRATA = obj.PRO_REF_MULTIPLO_ENTRATA;
            this.PRO_WSANAGRAFICA_GRUPPI_SIDI = obj.PRO_WSANAGRAFICA_GRUPPI_SIDI;
            this.PRO_LABEL_RIFERIMENTI = obj.PRO_LABEL_RIFERIMENTI ? obj.PRO_LABEL_RIFERIMENTI : "riferimenti";
            this.PRO_STORICIZZA_REF = obj.PRO_STORICIZZA_REF;
            this.PRO_PG_INIZIO_FASCICOLO = obj.PRO_PG_INIZIO_FASCICOLO ? obj.PRO_PG_INIZIO_FASCICOLO : false;
            this.PRO_NOTEDOC = obj.PRO_NOTEDOC;
            this.PRO_CHECK_PG_ANNO = obj.PRO_CHECK_PG_ANNO != undefined ? obj.PRO_CHECK_PG_ANNO : true;
        }
    }

    get MAP_LABEL_FLUSSO(){

      let obj : LabelFlussoMap = new LabelFlussoMap();

      if(this.PRO_LABEL_FLUSSO)
      {
        Object.keys(obj).map(
          (key, index) => {
            obj[key] = this.PRO_LABEL_FLUSSO?.split(";")[index];
          }
        );
      }
      else
      {
        obj.E = "Mittente";
        obj.I = "Mittente";
        obj.U = "Destinatario";
      }
      return obj;
    }

}


<ng-container *ngIf="!!statusMessage; else formTemplate">
    <h5 class="text-center">{{statusMessage}}</h5>
</ng-container>

<ng-template #formTemplate>
    <form *ngIf="!loading; else loadingState" class="d-flex flex-column" [formGroup]="annullamentoForm" (ngSubmit)="annullaProtocollo()">

        <div class="form-group w-100" [ngClass]="{'text-danger' : checkControlNameAndMaxLength('motivoAnnullamento')}">
            <label>Motivo*</label>
            <textarea class="form-control" placeholder="" formControlName="motivoAnnullamento" id="motivoAnnullamento" rows="6" [ngClass]="{'border-danger' : checkControlNameAndMaxLength('motivoAnnullamento')}"></textarea>
            <small *ngIf="checkControlNameAndMaxLength('motivoAnnullamento')" id="motivoRequired" class="form-text">
              <ng-container *ngIf="motivoAnnullamento.errors?.required">Motivo obbligatorio</ng-container>
              <ng-container *ngIf="motivoAnnullamento.errors?.maxlength">Non è possibile superare il massimo numero di caratteri consentito (1000)</ng-container>
            </small>
        </div>

        <button type="submit" class="ml-auto btn bg-primary-archipro text-white mt-3">Conferma</button>
    </form>

    <ng-template #loadingState>
        <app-loading></app-loading>
    </ng-template>
</ng-template>



import { Component, HostListener, Input, OnInit } from '@angular/core';
import { RequestService } from 'src/app/service/requestService/request.service';
import { ResizeService } from 'src/app/service/resize/resize.service';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';

@Component({
  selector: 'app-diario',
  templateUrl: './diario.component.html',
  styleUrls: ['./diario.component.scss']
})
export class DiarioComponent implements OnInit {

  @Input() documentoInfo : any;
  @Input() objParameterComponent : any;

  searchInProgress : boolean = false;

  listaOperazioni : any[] = [];

  size : SCREEN_SIZE;

  constructor(private resizeSvc : ResizeService, private requestService : RequestService) {
    this.size = this.resizeSvc.onResize();
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }


  ngOnInit(): void {
    if(!this.documentoInfo) this.documentoInfo = this.objParameterComponent;
    this.getListaOperazioni();
  }

  getListaOperazioni(){

    this.searchInProgress = true;

    this.requestService.postRequest("movimentiStatoNP", {annoProtocollo: this.documentoInfo.pk.annoProt, numeroProtocollo: this.documentoInfo.pk.numProt},
      (response) => {
        this.listaOperazioni = response.obj.list;

      this.listaOperazioni.map(element => {
        element.listaDestinatari = [{desRice : element.desRice, codRice : element.codRice, desRiceUO : element.desRiceUO, codRiceUO : element.codRiceUO}];
      });


        this.searchInProgress = false;
      },
      () => {
        this.searchInProgress = false;
        alert("qualcosa non va");
      }//dataMov, oraMov, codOper
    );
  }

}

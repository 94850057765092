<div class="w-100 h-100 d-flex flex-column"  >
    <ng2-pdfjs-viewer style="height: 99%;"
    pdfSrc="{{url}}"
    [page]="page"
    >
    </ng2-pdfjs-viewer>

    <div class="w-100 d-flex justify-content-around  mt-3" *ngIf="downloadFile">


      <a class="d-flex flex-column align-items-center text-primary-archipro cursor-pointer" style="font-size: 20px;" (click)="download(url)">
          <i class="icon-download"></i>
          <span>Download</span>
      </a>

  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Collocazione } from 'src/app/model/Collocazione';
import { RequestService } from 'src/app/service/requestService/request.service';


@Component({
  selector: 'app-albero-collocazione',
  templateUrl: './albero-collocazione.component.html',
  styleUrls: ['./albero-collocazione.component.scss']
})
export class AlberoCollocazioneComponent implements OnInit {

  collocazione : Collocazione = new Collocazione();

  listObj : any = [];

  @Input()
  modalMode : boolean;

  @Input()
  objParameterComponent : any = null;

  @Input()
  riportaCollocazione : (collocazione:any) => void;

  constructor(private requestService : RequestService) { }

  ngOnInit(): void {
    if(this.objParameterComponent)
    {
      this.modalMode = this.objParameterComponent.modalMode;
      this.riportaCollocazione = this.objParameterComponent.riportaCollocazione;
      this.collocazione = this.objParameterComponent.collocazioneFromForm;

      if(this.collocazione == null || !this.collocazione.col1)
      {
        this.collocazione = new Collocazione()}
    }
    this.filtraListaCollocazione();
  }

  buildViewDescriptionTitolario(titolarioPadre : any, lista : any[]) : void{
    lista.map(titolario => {
      let tempString = "";
      if(!!titolarioPadre)
      {
        tempString = titolarioPadre.viewDescription.replace(titolarioPadre.valore + " - ", "");
        tempString = tempString ? (tempString + " - ") : "";
      }

      titolario.viewDescription = titolario.valore + " - " + tempString + titolario.description.replace(titolario.valore + " - ", "")
      if(!!titolario.children){
        this.buildViewDescriptionTitolario(titolario, titolario.children)
      }
    })
  }

  /**
   * Metodo che prende l'albero di collocazione
   */
  filtraListaCollocazione() : void{
    this.listObj = [];
    this.requestService.postRequest("searchCollocazione",this.collocazione,
      (response) => {
        this.listObj = response.obj.list;
        this.buildViewDescriptionTitolario(null, this.listObj)


      }
    );
    //this.listObj = testCollocazione.response.obj.list;
  }

  /**
   * Metodo che non fa altro che settare i valori di collocazione a 0 per ricevere tutto l'albero di collocazione
   */
  ripristinaCollocazione() : void{
    this.collocazione = new Collocazione();
    this.filtraListaCollocazione();
  }

  riportaTitolario = () : void => {

  }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OptionModal } from 'src/app/component/sub-component/modal/OptionModal';
import { ModalComponent } from 'src/app/component/sub-component/modal/modal.component';
import { FascicoliRequest } from 'src/app/model/FascicoliRequest';
import { Fascicolo } from 'src/app/model/Fascicolo';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { NavigazioneComponent } from '../../ricerche/navigazione/navigazione.component';
import { Titolario } from 'src/app/model/Titolario';
import { RequestService } from 'src/app/service/requestService/request.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/service/alertservice/alert.service';
import { TypeAlert } from 'src/app/service/alertservice/alert/TypeAlert';
import { ClassificazioneFascicolazioneComponent } from 'src/app/component/sub-component/forms/classificazione-fascicolazione/classificazione-fascicolazione.component';

@Component({
  selector: 'app-fascicoli-uniti',
  templateUrl: './fascicoli-uniti.component.html',
  styleUrls: ['./fascicoli-uniti.component.scss']
})
export class FascicoliUnitiComponent implements OnInit {

  //****************************************************** */
  //Variabili ulitilizzate per dichiarare e inizializzare la modale
  //****************************************************** */
  @ViewChild('dynamicModalComponent')
  dynamicModal : ModalComponent;
  optionModal : OptionModal = new OptionModal();
  //****************************************************** */
  //****************************************************** */

  @ViewChild("classificazioneComponent") classificazioneComponent : ClassificazioneFascicolazioneComponent;

  @Input() modalMode : boolean = false;
  @Input() fascicoloInfo : any = null;

  showForm : boolean = true;
  justChecked : boolean = false;
  disableForm : boolean = false;


  titolarioSelezionato : any = null;
  fascicoloSelezionato : any = null;
  checkClassificazioneClicked : boolean = false;

  loadingFascicoliUniti : boolean = false
  listaFascicoliUniti : any[] = []

  GLOBALI : GlobalObj;

  mapSectionOpened : any = {
    sectionInfoFascicolo : true,
    sectionFascicoliUniti : true,
    sectionClassificazione : true
  };

  formObj : FormGroup;

  titolo1 = new FormControl("");
  titolo2 = new FormControl("");
  titolo3 = new FormControl("");
  titolo4 = new FormControl("");
  titolo5 = new FormControl("");
  titolo6 = new FormControl("");
  //numeroFascicolo = new FormControl("");
  numFascVis = new FormControl("");
  annoFascicolo = new FormControl("");

  constructor(private globalService : GlobalService,
    private fb : FormBuilder,
    private requestService : RequestService,
    private router : Router,
    private alertService : AlertService
    ) {
    this.GLOBALI = this.globalService.GLOBAL;

    if(this.modalMode)
    {

    }
    else if(this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state != undefined){
      this.fascicoloInfo = this.router.getCurrentNavigation().extras.state;
    }

    this.formObj = this.fb.group({});
  }

  ngOnInit(): void {
    this.getListaFascicoliUniti();
  }

  /**
   * Funzione utilizzata per aprire e chiudere le sezioni
   *
   * @param idSection : id della sezione da chiudere/aprire (l'id deve essere lo stesso che viene inserito nella mapSectionOpened)
   */
  openSection(idSection : string) : void
  {
    this.mapSectionOpened[idSection] = !this.mapSectionOpened[idSection]
  }

  /**
   * Funzione che ritorna lo stato di una sezione (true aperto, false chiuso)
   *
   * @param idSection   : id della sezione da verificare
   * @returns           : torna il valore inserito nella mapSectionOpened
   */
  checkSectionOpened(idSection : string) : boolean{
    return this.mapSectionOpened[idSection];
  }

  setCheckClassificazioneClicked($event){
    this.checkClassificazioneClicked = $event;
  }

  setTitolarioSelezionato($event){
    this.titolarioSelezionato = $event;
  }

  setFascicoloSelezionato($event){
    this.fascicoloSelezionato = $event;
  }


    /**
   * Metodo che chiama il servizio getListaFascicoliUniti e riempe la lista degli estratti
   */
    getListaFascicoliUniti() : void{
      this.loadingFascicoliUniti = true;

      let payload : any = {
        numeroFascicolo : this.fascicoloInfo.fascPK.numFascicolo,
        annoFascicolo : this.fascicoloInfo.fascPK.annoFascicolo,
        numTitolo : this.fascicoloInfo.fascPK.numTitolo
      }

      this.requestService.postRequest("getListaFascicoliUniti", payload,
        success => {
          this.listaFascicoliUniti = success.obj.list.map(element => new Fascicolo().buildFromEstratti(element));
          this.loadingFascicoliUniti = false;
        }
      )
    }

  onSubmit(){
    let payload = {
      capostipite  : {
        annoFascicolo: this.fascicoloInfo.fascPK.annoFascicolo,
        numFascVis: this.fascicoloInfo.numFascVis,
        numeroFascicolo: this.fascicoloInfo.fascPK.numFascicolo
      },
      fascicoloDaUnire : {
        annoFascicolo: this.fascicoloSelezionato.fascPK.annoFascicolo,
        numFascVis: this.fascicoloSelezionato.numFascVis,
        numeroFascicolo: this.fascicoloSelezionato.fascPK.numFascicolo
      }
    }

    for(let i = 1; i <= 6; i++){
      payload.capostipite["titolo"+i] = this.fascicoloInfo.titolario.split(".")[i-1];
      payload.fascicoloDaUnire["titolo"+i] = this.fascicoloSelezionato.titolario.split(".")[i-1];
    }

    this.requestService.postRequest("unisciFascicolo", payload,
      (success) => {
        this.alertService.addAlert("Fascicoli collegati correttamente", TypeAlert.success);
        this.getListaFascicoliUniti();
        this.classificazioneComponent.deleteAllFiledClassificazione();
      },
      () => {
        this.alertService.addAlert("Qualcosa è andato storto", TypeAlert.danger);
      }

    )

  }




  /**
   * Apre il fasicolo selezionato nella navigazione per vedere quali documenti ci sono al suo interno
   * @param fascicolo
   */
  apriFascicolo(fascicolo : Fascicolo)
  {
    this.optionModal = new OptionModal();
    this.optionModal.showHeaderModal = false;
    this.optionModal.additionalClassModalBody = "p-0";

    this.optionModal.setSizeModal("90");
    this.optionModal.maxSizeHeightModal = true;
    this.optionModal.showSaveButton = false;

    let titolarioFromForm = new FascicoliRequest();
    titolarioFromForm.titolo1 = fascicolo.titolario.titolo1;
    titolarioFromForm.titolo2 = fascicolo.titolario.titolo2;
    titolarioFromForm.titolo3 = fascicolo.titolario.titolo3;
    titolarioFromForm.titolo4 = fascicolo.titolario.titolo4;
    titolarioFromForm.titolo5 = fascicolo.titolario.titolo5;
    titolarioFromForm.titolo6 = fascicolo.titolario.titolo6;
    titolarioFromForm.annoFascicolo = fascicolo.annoFascicolo;
    titolarioFromForm.numeroFascicolo = fascicolo.numeroFascicolo;

    //Opzioni per settare il component da caricare dinamicamente
    //******************************************************* */
    this.optionModal.setLoadDynamicComponent(true);
    this.optionModal.dynamicComponnet = NavigazioneComponent;
    this.optionModal.objParameterComponent = {
      modalMode : true,
      autoNext : false,
      titolarioFromForm : titolarioFromForm
    }

    this.dynamicModal.initModal(this.optionModal);
    this.dynamicModal.openModal();
  }

}

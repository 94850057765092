import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrivaniaComponent } from './component/moduli/scrivania-module/scrivania.component';
import { FooterComponent } from './component/sub-component/footer/footer.component';
import { NavBarComponent } from './component/sub-component/nav-bar/nav-bar.component';
import { HttpClientModule } from '@angular/common/http';
import { SelezionaUoAooComponent } from './component/sub-component/seleziona-uo-aoo/seleziona-uo-aoo.component';
import { SidebarComponent } from './component/sub-component/sidebar/sidebar.component';
import { ResizeService } from './service/resize/resize.service';
import { CustomDateParserFormatterService } from './service/CustomDateParserFormatter/custom-date-parser-formatter.service';
import { CustomDatePickerI18nService, I18n } from './service/datePickerI8nLanguage/custom-date-picker-i18n.service';
import { SharedModule } from './component/moduli/shared/shared.module';
import { ViewerExtComponent } from './component/viewer-ext/viewer-ext.component';
import { ElencoAgdUoPerUtenteComponent } from './component/sub-component/elenco-agd-uo-per-utente/elenco-agd-uo-per-utente.component';
import { CookieService } from 'ngx-cookie-service';
import { ViweDocComponent } from './component/viwe-doc/viwe-doc.component';
import { AccessWebSidiComponent } from './component/access-web-sidi/access-web-sidi.component';
import { CourtesyPageComponent } from './component/courtesy-page/courtesy-page.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ScrivaniaComponent,
    FooterComponent,
    NavBarComponent,
    SelezionaUoAooComponent,
    SidebarComponent,
    ViewerExtComponent,
    ElencoAgdUoPerUtenteComponent,
    ViweDocComponent,
    AccessWebSidiComponent,
    CourtesyPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [
    ResizeService,
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService},
    {provide: NgbDatepickerI18n, useClass: CustomDatePickerI18nService},
    I18n,
    //CanDeactivateGuardService,
    CookieService
  ],
  //exports: [LoadingComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="d-flex flex-column w-100 bg-white pt-1" >

  <form class="d-flex spaced-input mt-3 w-100" (ngSubmit)="getList()">
      <div class="form-group mb-0 w-100">
          <input type="text" class="form-control" placeholder="Cerca" [(ngModel)]="docRequest.descrizione" name="fullText">
      </div>

      <button type="submit" class="btn justify-content-center d-flex bg-primary-archipro text-white">
          <i class="icon-search"></i>Cerca
      </button>
  </form>



  <div *ngIf="listRiferimenti.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">

      <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
          <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
      </diV>
      <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end'}}"  *ngIf="numPage > 1" >
          <nav aria-label="Page navigation example">
              <ul class="pagination mb-0">
                  <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                      <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                          <span *ngIf="size>=3" >Precedente</span>
                          <i *ngIf="size<3" class="icon-left-open"></i>
                      </div>
                    </li>
                  <li  *ngFor="let page of listObjPage" class="page-item">
                    <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                      <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                          <span *ngIf="size>=3" >Prossimo</span>
                          <i *ngIf="size<3" class="icon-right-open"></i>
                      </div>
                  </li>
              </ul>
            </nav>
      </div>
  </div>
  <!--Lista-->
  <div class="d-flex w-100 h-100">
      <!--##############################################################################-->
      <!--################################ Tabella #####################################-->
      <!--##############################################################################-->

      <div *ngIf="listRiferimenti.length > 0; else nessunRisultato" class="mt-1 w-100">
          <table class="table table-striped border">
              <thead class="">
                  <tr class="text-primary-archipro">
                      <th class="size-badge-notifica"></th>
                      <th>
                          <span class="d-flex cursor-pointer">{{referenteTipo == 3 ? "Nominativo" : "Descrizione"}}</span>
                      </th>
                      <th scope="col" *ngIf="size>=3">
                          <span class="d-flex cursor-pointer">Tipo - Codice</span>
                      </th>
                  </tr>
              </thead>
              <tbody *ngIf="!searchInProgress; else loadingList">

                  <tr *ngFor="let referente of listRiferimenti; let i = index" class="lista-colonne" (click)="addToGeneralList(referente)">
                      <td class="size-badge-notifica">
                          <i *ngIf="referente.active" class="icon-ok text-success"></i>
                      </td>
                      <td class="titolario-column">
                          ({{mapCodTipoReferente[referente.referenteTipo]}}) {{referente.descrizione}} <span *ngIf="size<3"> ({{mapCodTipoReferente[referente.referenteTipo]}} - {{referente.referenteCodice}}) </span>
                       </td>

                       <td *ngIf="size>=3">
                           {{mapCodTipoReferente[referente.referenteTipo]}} - {{referente.referenteCodice}}
                       </td>

                  </tr>
              </tbody>
              <ng-template #loadingList>
                  <tbody>
                      <tr>
                          <td colspan="100%">
                              <app-loading class="w-100 h-100 d-flex justify-content-center align-items-center"></app-loading>
                          </td>
                      </tr>
                  </tbody>
              </ng-template>
          </table>

          <div *ngIf="listRiferimenti.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">

              <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
                  <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
              </diV>
              <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end'}}"  *ngIf="numPage > 1" >
                  <nav aria-label="Page navigation example">
                      <ul class="pagination mb-0">
                          <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                              <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                                  <span *ngIf="size>=3" >Precedente</span>
                                  <i *ngIf="size<3" class="icon-left-open"></i>
                              </div>
                            </li>
                          <li  *ngFor="let page of listObjPage" class="page-item">
                            <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                          </li>
                          <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                              <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                                  <span *ngIf="size>=3" >Prossimo</span>
                                  <i *ngIf="size<3" class="icon-right-open"></i>
                              </div>
                          </li>
                      </ul>
                    </nav>
              </div>
          </div>

      </div>

      <ng-template #nessunRisultato >

          <div class="h-100 w-100 d-flex justify-content-center align-items-center" *ngIf="!searchInProgress; else loading">
              <h2>Imposta i criteri di ricerca</h2>
          </div>

      </ng-template>
  </div>

  <ng-template #loading>
      <div class="d-flex justify-content-center align-items-center h-100 w-100">
          <app-loading></app-loading>
      </div>

  </ng-template>
</div>

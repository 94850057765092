<section id="documenti-navigazione" class="d-flex flex-column">

    <!--Infomazioni sul titolario scelto nella precedente pagina-->

    <section class="d-flex mt-3 mb-3 flex-column">
        <div class="info-titolario d-flex flex-column card-shadow border p-3">
            <span class="d-flex justify-content-between">
              <span class="d-flex"><b class="mr-2">Titolario: </b> {{titolarioSelezionato.description}}</span>
              <div class="d-flex">
                <i class="cursor-pointer icon-print text-primary-archipro" style="margin-top: -8px;" (click)="stampaCarpettaFascicolo()" title="Stampa carpetta fascicolo"></i>

                <div  class="composed-icon-pedice composed-icon-pedice--more-top text-primary-archipro" (click)="stampaDiarioFascicolo()" title="Stampa diario fascicolo">
                  <i class="icon-print" ></i>
                  <i class="icon-book pedice" style="font-size: 14px;bottom: 2px"></i>
                </div>
              </div>
            </span>
            <span class="mr-2" *ngIf="GLOBALI.PRO_COLLOCAZIONE_FISICA && fascicoloSelezionato.collocazione != null">
                <b>Collocazione: </b>
                {{fascicoloSelezionato.collocazione}} - {{fascicoloSelezionato.descrCollocazione}}
            </span>
            <span class="mr-2">
                <b>Fascicolo: </b> {{fascicoloSelezionato.numFascVis}} / {{fascicoloSelezionato.fascPK.annoFascicolo}}
                 (<b>Progr:</b> {{fascicoloSelezionato.progrAnno}} / {{fascicoloSelezionato.fascPK.annoFascicolo}}) del {{fascicoloSelezionato.dataApertura | dataStringTransform}} <ng-container *ngIf="fascicoloSelezionato.dataMessaAtti">(<b>Messo agli atti:</b> {{fascicoloSelezionato.dataMessaAtti | dataStringTransform}}) </ng-container>
                <div *ngIf="GLOBALI.PRO_PG_INIZIO_FASCICOLO && fascicoloSelezionato.primoProtocollo"><b>Primo protocollo:</b> {{fascicoloSelezionato.primoProtocollo}}</div>
                <div *ngIf="GLOBALI.PRO_SOGGETTO && fascicoloSelezionato.soggetto != null && fascicoloSelezionato.soggetto != undefined && fascicoloSelezionato.soggetto != ''"><b>Soggetto:</b> {{fascicoloSelezionato.soggetto}} </div>
                <br>
                <b>Oggetto:</b> {{fascicoloSelezionato.oggetto}}
            </span>
            <span class="mr-2" *ngIf="fascicoloSelezionato.note">
              <b>Note: </b>
              {{fascicoloSelezionato.note}}
            </span>
            <span *ngIf="fascicoloSelezionato.codRice">
                <b> In carico a: </b> {{fascicoloSelezionato.codRice?.descrizione}} ({{fascicoloSelezionato.codRice?.codice}})
                <ng-container *ngIf="fascicoloSelezionato.codRice.uo">{{fascicoloSelezionato.codRice.uo.descrizione}} ({{fascicoloSelezionato.codRice.uo.codice}})</ng-container>
            </span>

            <span class="d-flex" *ngIf="fascicoloSelezionato?.numeroPratica && fascicoloSelezionato?.annoPratica">
              <b> Pratica: </b> <div (click)="goToGestionePratica()" class="cursor-pointer effetto-link">{{fascicoloSelezionato.numeroPratica}} /  {{fascicoloSelezionato.annoPratica}}</div>
            </span>
            <!--<span>
                <b> Oggetto: </b> {{fascicoloSelezionato.oggetto}}
            </span>-->
        </div>

        <div class="d-flex mt-3 justify-content-end">


            <select class="form-control w-25" [(ngModel)]="tipoDoc"  (ngModelChange)="objRequest.tipoDoc = tipoDoc;">
                <option class="text-primary-archipro placeholder-select">Filtro documenti</option>
                <option [ngValue]="'T'">Tutti</option>
                <option [ngValue]="'P'">Protocollati</option>
                <option [ngValue]="'NP'">Non protocollati</option>
            </select>


            <div class="d-flex align-items-center ml-2" style="padding-right: 0px !important;" (click)="listaDocNavigazione()">
                <button type="button"  class="w-100 btn justify-content-center d-flex bg-primary-archipro text-white">
                    <i class="icon-search"></i>Cerca
                </button>
            </div>



        </div>

    </section>





    <div *ngIf="listDoc.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">

        <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
            <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
        </diV>
        <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end max-width-340'}}"  *ngIf="numPage > 1" >
            <nav aria-label="Page navigation example">
                <ul class="pagination mb-0">
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                            <span *ngIf="size>=3" >Precedente</span>
                            <i *ngIf="size<3" class="icon-left-open"></i>
                        </div>
                      </li>
                    <li  *ngFor="let page of listObjPage" class="page-item">
                      <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                    </li>
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                            <span *ngIf="size>=3" >Prossimo</span>
                            <i *ngIf="size<3" class="icon-right-open"></i>
                        </div>
                    </li>
                </ul>
              </nav>
        </div>
    </div>


    <!--Lista fascicoli-->
    <div *ngIf="listDoc.length > 0; else nessunRisultato">

        <table class="table table-striped border" *ngIf="size > 2; else mobile">
            <thead>
                <tr class="text-primary-archipro">
                    <th class="size-badge-notifica"></th>
                    <th scope="col"> <!--(click)="clickOrderColumn(objRequest.order == 1 ? 2 : 1)"-->
                        <span class="d-flex cursor-pointer">N° / Anno <i [ngClass]="{'icon-up' : objRequest.order == 1, 'icon-down' : objRequest.order == 2}"></i></span>
                    </th>


                    <th scope="col"> <!--(click)="clickOrderColumn(objRequest.order == 7 ? 8 : 7)"-->
                        <span class="d-flex cursor-pointer">Data <i [ngClass]="{'icon-up' : objRequest.order == 7, 'icon-down' : objRequest.order == 8}"></i></span>
                    </th>

                       <!--<th scope="col">Inviato da</th>-->
                    <th scope="col" class="d-flex justify-content-center" style="border: none"> <!--(click)="clickOrderColumn(objRequest.order == 15 ? 16 : 15)"-->
                        <span class="d-flex cursor-pointer">Flusso <i [ngClass]="{'icon-up' : objRequest.order == 15, 'icon-down' : objRequest.order == 16}"></i></span>
                    </th>


                    <th scope="col"> <!--(click)="clickOrderColumn(objRequest.order == 7 ? 8 : 7)"-->
                        <span class="d-flex cursor-pointer">Oggetto <i [ngClass]="{'icon-up' : objRequest.order == 7, 'icon-down' : objRequest.order == 8}"></i></span>
                    </th>

                    <th scope="col"> <!--(click)="clickOrderColumn(objRequest.order == 7 ? 8 : 7)"-->
                        <span class="d-flex cursor-pointer">Mittente/Destinatario <i [ngClass]="{'icon-up' : objRequest.order == 7, 'icon-down' : objRequest.order == 8}"></i></span>
                    </th>

                    <th *ngIf="!modalMode" scope="col">Azioni</th>
                </tr>
            </thead>
            <tbody *ngIf="!searchInProgress; else loadingList">

                <tr *ngFor="let doc of listDoc; let i = index" class="lista-colonne">
                    <td class="size-badge-notifica">
                        <div class="d-flex h-100 justify-content-start align-items-center">
                            <i [style.visibility]="!doc.isDocPrincipaleP7M && (doc.firmatario == null || doc.firmatario == '') ? 'hidden' : 'visible'" title="{{doc.firmatario == null || doc.firmatario == '' ? 'Firmato' : doc.firmatario}}" class="icon-{{doc.isDocPrincipaleP7M ? 'doc-certificate' : 'pencil'}}"></i>
                            <i [style.visibility]="!doc.security.riservato ? 'hidden' : 'visible'" title="Riservato" class="icon-lock "></i>
                            <i *ngIf="modalMode" [style.visibility]="!doc.estratti ? 'hidden' : 'visible'" title="Estratto" class="icon-doc-alt"></i>
                        </div>

                    </td>
                    <td class="numero-column">
                        <span >{{doc.docNumString}}</span>
                    </td>

                    <td scope="row" class="cut-text">
                        {{doc.dataProt | dataStringTransform}}
                    </td>

                    <td scope="row" class="text-center">
                        {{doc.provDest}}
                    </td>

                    <td scope="row" class="cut-text oggetto-column">
                        {{doc.oggetto}}
                    </td>

                    <td scope="row" class="cut-text oggetto-column">
                        {{doc.referenteExt}}
                    </td>

                    <td class="action-icon d-flex align-items-center numero-column" ClickStopPropagation>

                        <div class="container-icon">
                            <i class="icon-doc-text cursor-pointer" title="Dettaglio" (click)="openDettaglioDocumento(doc)"></i>
                        </div>

                        <div *ngIf="!modalMode && !modalModeInput && doc.estratti" class="container-icon" (click)="showEstratti(doc)">
                            <i class="icon-doc-alt cursor-pointer" title="Estratto" ></i>
                        </div>
                    </td>
                </tr>
            </tbody>
            <ng-template #loadingList>
                <tbody>
                    <tr>
                        <td colspan="100%">
                            <app-loading class="w-100 h-100 d-flex justify-content-center align-items-center"></app-loading>
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>

    <ng-template #nessunRisultato >

        <div class="h-100 w-100 d-flex justify-content-center align-items-center" *ngIf="!searchInProgress; else loading">
            <h2>Non è stato trovato nessun risultato con i filtri selezionati</h2>
        </div>

    </ng-template>

    <ng-template #loading>
        <app-loading></app-loading>
    </ng-template>

    <ng-template #mobile>
        <div id="accordionDocumentiNavigazione" class="mt-3" *ngIf="!searchInProgress; else loading">
            <div class="card card-list-mobile" *ngFor="let doc of listDoc; let i = index">
                <div class="card-header" [attr.id]="'doc_mobile_' + i + '_' + 'heading'">
                    <div class="mb-0 d-flex">
                        <div class="position-relative">

                        </div>
                        <button class="btn w-100 p-0" data-toggle="collapse" [attr.data-target]="'#' + 'doc_mobile_' + i + '_'" aria-expanded="true" [attr.aria-controls]="doc.docNumString">
                                <div class="d-flex w-100 justify-content-between">
                                    <h6 class="mt-1 d-flex align-items-center">
                                        {{doc.docNumString}}
                                        <i *ngIf="doc.isDocPrincipaleP7M || doc.firmatario != null" title="{{doc.firmatario == null || doc.firmatario == '' ? 'Firmato' : doc.firmatario}}" class="icon-{{doc.isDocPrincipaleP7M ? 'doc-certificate' : 'pencil'}} text-icon-disabled"></i>
                                        <i *ngIf="doc.security.riservato" title="Riservato" class="icon-lock-filled text-icon-disabled"></i>
                                    </h6>
                                    <div class="d-flex align-items-center">
                                        <small>{{doc.dataProt | dataStringTransform}}</small>
                                    </div>
                                </div>
                                <div class="d-flex flex-column mb-1 text-left">
                                    <span class="oggetto-mobile cut-text">
                                        {{doc.oggetto}}
                                    </span>

                                </div>
                                <div class="d-flex text-muted cut-text ">
                                    <small class="agd-uo-list-mobile w-100 d-flex justify-content-between">
                                        <span>{{doc.referenteExt}}</span>
                                        <span>{{doc.provDest == "E" ? "Entrata" : (doc.provDest == "U" ? "Uscita" : "Interno")}}</span>
                                    </small>
                                </div>

                        </button>
                        <div class="position-relative">
                            <div class="dropdown d-flex align-items-center justify-content-center h-100 position-absolute" style="right: -16px;">
                                <span [attr.id]="'action-dropdown-' + 'doc_mobile_' + i + '_'" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="icon-ellipsis-vert" title="Azioni"></i>
                                </span>

                                <div class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="'action-dropdown-' + 'doc_mobile_' + i + '_'">
                                    <h6 class="dropdown-header">Azioni</h6>

                                    <div class="dropdown-divider"></div>


                                    <a class="dropdown-item cursor-pointer" (click)="openDettaglioDocumento(doc)">
                                      <i class="icon-doc-text cursor-pointer" ></i>
                                      <span class="ml-1">Apri</span>
                                    </a>

                                    <a class="dropdown-item cursor-pointer d-flex">
                                        <div class="position-relative cursor-pointer container-icon" title="Movimenti">
                                            <i class="icon-folder-close"></i>
                                            <i class="icon-switch position-absolute" style="left: 5px;font-size: 8px;top: 7px;"></i>
                                        </div>
                                        <span class="ml-1">Movimenti</span>
                                    </a>

                                    <a *ngIf="doc.estratti" class="dropdown-item cursor-pointer" (click)="showEstratti(doc)">
                                        <i class="icon-doc-alt cursor-pointer" title="Estratto" ></i>
                                        <span class="ml-1">Estratto</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div [attr.id]="'doc_mobile_' + i + '_'" class="collapse {{i == 0 ? 'show' : ''}}" [attr.aria-labelledby]="'doc_mobile_' + i + '_' + 'heading'" data-parent="#accordionDocumentiNavigazione">
                    <div class="card-body">

                        <div class="d-flex flex-column" style="font-size: 14px;">
                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Numero :</span>
                                <span class="w-100 text-left h-100">{{doc.docNumString}}</span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Data :</span>
                                <span class="w-100 text-left h-100">{{doc.dataProt | dataStringTransform}}</span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Flusso :</span>
                                <span class="w-100 text-left h-100">{{doc.provDest == "E" ? "Entrata" : (doc.provDest == "U" ? "Uscita" : "Interno")}}</span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">Oggetto :</span>
                                <span class="w-100 text-left h-100">{{doc.oggetto}}</span>
                            </div>

                            <div class="d-flex column-element-mobile">
                                <span class="w-25 font-weight-bold h-100 name-column-mobile">{{doc.provDest == "E" ? "Mittente" : "Destinatario"}} :</span>
                                <span class="w-100 text-left h-100">{{doc.referenteExt}}</span>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-template>



    <div *ngIf="listDoc.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">

        <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
            <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
        </diV>
        <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end max-width-340'}}"  *ngIf="numPage > 1" >
            <nav aria-label="Page navigation example">
                <ul class="pagination mb-0">
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                            <span *ngIf="size>=3" >Precedente</span>
                            <i *ngIf="size<3" class="icon-left-open"></i>
                        </div>
                      </li>
                    <li  *ngFor="let page of listObjPage" class="page-item">
                      <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                    </li>
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                            <span *ngIf="size>=3" >Prossimo</span>
                            <i *ngIf="size<3" class="icon-right-open"></i>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</section>

  <!-- Modal Viewer-->
  <app-viewer #viewer [documentoViewer]="documentoViewer" [idViewer]="'viewerDettaglioDocumentiNavigazione'"></app-viewer>

  <app-modal #dynamicModalComponent ></app-modal>

<section class="p-3 h-100 d-flex flex-column">
    <div class="filtri d-flex align-items-center spaced-input w-100">

        <div *ngIf="showDettagliGruppo">
            <i (click)="showList()"  title="Torna alla lista" class="icon-left-big text-primary-archipro cursor-pointer"></i>
        </div>

        <div class="w-100">
            <div class="form-group mb-0 w-100">
                <input type="text" class="form-control" placeholder="Nome gruppo" [(ngModel)]="docRequest.nome" name="descrizione">
            </div>
        </div>

        <div class="action-icon-protocol d-flex align-items-center spaced-input">

            <div class="container-icon mt-0">
                <i title="Cerca" class="icon-search" (click)="ricercaGruppi()"></i>
            </div>
        </div>
    </div>

    <div *ngIf="showAlert" class="mt-3 mb-3 alert alert-{{esitoAlert ? 'success' : 'danger'}}" role="alert">
        {{esitoAlert ? "Gruppo eliminato con successo" : "Errore nell'eliminazione del gruppo"}}
    </div>

    <div *ngIf="!searchInProgress; else loading" class="d-flex {{showDettagliGruppo ? 'flex-column' : ''}} h-100 w-100 mt-3">
        <div *ngIf="!showDettagliGruppo; else dettaglioGruppoReferenti" class="lista-gruppi w-100">
            <table *ngIf="listGruppi.length > 0; else nessunElemento" class="table table-striped border">
                <thead>
                    <tr class="text-primary-archipro">



                        <th scope="col">
                            <span class="d-flex">Nome</span>
                        </th>

                        <th scope="col">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let gruppo of listGruppi; let i = index" class="lista-colonne">

                        <td>
                            {{gruppo.nome}}
                        </td>
                        <td  class="action-icon d-flex align-items-center" ClickStopPropagation>
                            <div class="border container-icon" >
                                <i class="icon-info text-primary-archipro" title="Dettaglio gruppo" (click)="dettaglioGruppo(gruppo)"></i>
                            </div>

                            <div class="border container-icon" >
                                <i class="icon-export horizontal-speculate text-primary-archipro " title="Riporta gruppo" (click)="riportaGruppo(gruppo)"></i>
                            </div>

                            <div class="border container-icon" *ngIf="globalService.GLOBAL.PRO_WSANAGRAFICA_GRUPPI_SIDI">
                                <i class="icon-trash text-primary-archipro" title="Rimuovi dall'elenco" (click)="deleteGruppo(gruppo)"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <ng-template #dettaglioGruppoReferenti>
            <h4>{{gruppoSelezionato.nome}}</h4>
            <div  class="dettaglio-gruppo">
                <table class="table table-striped border">
                    <thead>
                        <tr class="text-primary-archipro">
                            <th scope="col">
                                <span class="d-flex">Denominazione</span>
                            </th>

                            <th scope="col" style="min-width: 130px;">
                                <span class="d-flex">Tipo - Codice </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let referente of gruppoSelezionato.referenti; let i = index" class="lista-colonne">

                            <td>
                               ({{getTipoRef(referente.referenteTipo)}}) {{referente.descrizione}}
                            </td>
                            <td>
                                {{getTipoRef(referente.referenteTipo)}} - {{referente.referenteCodice}}
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </ng-template>


        <ng-template #nessunElemento>
            <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                <h3>Nessun gruppo da visualizzare</h3>
            </div>

        </ng-template>
    </div>
    <ng-template #loading>
        <div class="h-100 w-100">
            <app-loading [text]="'Ricerca in corso'"></app-loading>
        </div>
    </ng-template>
</section>

import { AbstractControl, FormArray, FormGroup } from "@angular/forms";

/**
 * Classe che contiene al suo interno dei validatori customizzati
 */
export class CustomValidators{

    /**
     * Validatore che controlla se almeno 1 dei valori della lista è per competenza
     *
     * @param arrayCompetenze   :   array da controllare
     * @returns                 :   Torna l'oggetto di errore se non c'è la competenza
     */
    static competenzaObbligatoria(arrayCompetenze: AbstractControl): { [key: string]: boolean } | null{
        let status : boolean = false;


        (arrayCompetenze as FormArray).controls.forEach(element => {
            if(element.value.hasOwnProperty("activeC") && element.value.activeC)
            {
               status = true;
            }
        });

        if(!status)
            return {competenzaObbligatoria : true};
        else
            return null;
    }


    static checkFormatValidNgbDate(date : AbstractControl): { [key: string]: boolean } | null{
      if(!!date.value && (typeof date.value === "string" || date.value?.year < 1900 || String(date.value?.year).length !== 4))
      {
        return {dateFormatIncorrect : true}
      }

      return null;
    }

    /**
     * Validatore che controlla se l'anno passato è maggiore o uguale all'anno corrente
     *
     * @param date              :   oggetto ngbModel della data dove prendere l'anno da controllare
     * @returns                 :   Torna l'oggetto di errore se non c'è la competenza
     */
    static beforeCurrentYear(date : AbstractControl): { [key: string]: boolean } | null{

      if(date.value?.year >= new Date().getFullYear())
          return {yearGreaterCurrentYear : true};
      else
          return null;
    }


    /**
     * Validatore che controllo se c'è più di una competenza quando da globale non è permessa la multicolpetenza
     *
     * @param arrayCompetenze    :   array da controllare
     * @returns                  :   Torna l'oggetto di errore se non c'è la competenza
     */
    static noMultiCompetenza(arrayCompetenze: AbstractControl): { [key: string]: boolean } | null{
        let count : number = 0;

        (arrayCompetenze as FormArray).controls.forEach(element => {
            if(element.value.hasOwnProperty("activeC") && element.value.activeC)
            {
               count++;
            }
        });

        if(count > 1)
            return {soloUnaCompetenza : true};
        else
            return null;
    }

    static ConfirmedValidator(controlName: string, matchingControlName: string){
      return (formGroup: FormGroup) => {
          const control = formGroup.controls[controlName];
          const matchingControl = formGroup.controls[matchingControlName];
          if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
              return;
          }
          if (control.value !== matchingControl.value) {
              matchingControl.setErrors({ confirmedValidator: true });
          } else {
              matchingControl.setErrors(null);
          }
      }
    }

    static notEqualOldAndNewPassword(controlName: string, matchingControlName: string){
      return (formGroup: FormGroup) => {
          const control = formGroup.controls[controlName];
          const matchingControl = formGroup.controls[matchingControlName];
          if (matchingControl.errors && !matchingControl.errors.notEqualOldAndNewPassword) {
              return;
          }
          if (control.value === matchingControl.value) {
              matchingControl.setErrors({ notEqualOldAndNewPassword: true });
          } else {
              matchingControl.setErrors(null);
          }
      }
    }

}

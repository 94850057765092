<div class="w-100">
    <div class="form-group w-100 mb-0" [ngClass]="{'text-danger' :  checkControlName()}">
        <label class="d-flex" *ngIf="!labelInPlaceHolder">{{labelSelect}}<ng-container *ngIf="requiredSymbol">*</ng-container></label>
        <select class="form-control select-size" [formControl]="formField" [ngClass]="{'border-danger' :  checkControlName(), 'disabled-select': disabled}">
            <option [ngValue]="''" *ngIf="labelInPlaceHolder" class="text-primary-archipro placeholder-select" selected disabled>{{labelSelect}}</option>
            <option *ngIf="emptyValueEnabled" [ngValue]="''"></option>
            <option *ngFor="let option of listOption" [ngValue]="option.value">{{option.description}}</option>
        </select>
        <small *ngIf="checkControlName()" id="oggettoRequired" class="form-text">{{textErroreView}}</small>

    </div>
</div>

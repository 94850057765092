<section id="fascicoli-navigazione" class="d-flex flex-column">

    <!--Infomazioni sul titolario scelto nella precedente pagina-->
    <section class="info-titolario d-flex mt-3 mb-3 card-shadow border p-3">
        <span class="mr-2">
            <b>Titolario: </b>
            {{titolarioSelezionato.viewDescription ? titolarioSelezionato.viewDescription : titolarioSelezionato.description}}
        </span>

    </section>

    <div class="d-flex flex-column w-100 {{size <= 3 ? 'border-bottom' : ''}} align-items-end">
        <span *ngIf="size <= 3" class="text-primary-archipro cursor-pointer" (click)="showTabFilter = !showTabFilter"><i class="icon-{{!showTabFilter ? 'down' : 'up'}}-open"></i></span>
        <!--Parte di ricerca-->
        <fieldset *ngIf="size >3 || showTabFilter" [disabled]="searchInProgress" class="w-100 d-flex spaced-input" [ngClass]="{'flex-column': size <= 3}">
            <div class="d-flex w-100 spaced-input">
                <div class="form-group p-1 mb-0 w-100" style="padding-left: 0px !important;">
                    <input type="text" class="form-control" placeholder="N°" [(ngModel)]="objRequest.numFascVis">
                </div>

                <div class="form-group p-1 mb-0 w-100" style="padding-left: 0px !important;">
                    <input type="text" class="form-control" placeholder="Anno" [(ngModel)]="objRequest.annoFascicolo">
                </div>
            </div>

            <div class="form-group p-1 mb-0 w-100" style="padding-left: 0px !important;" *ngIf="GLOBALI.PRO_SOGGETTO">
                <input type="text" class="form-control" placeholder="Soggetto" [(ngModel)]="objRequest.soggetto">
            </div>

            <div class="form-group p-1 mb-0 w-100" style="padding-left: 0px !important;">
                <input type="text" class="form-control" placeholder="Oggetto" [(ngModel)]="objRequest.oggetto">
            </div>



            <div class="form-group p-1 mb-0 w-100" style="padding-left: 0px !important;">
                <input type="text" class="form-control" placeholder="Note" [(ngModel)]="objRequest.note">
            </div>

            <div class="d-flex align-items-center p-1" style="padding-right: 0px !important;"  [ngClass]="{'w-100' : size < 3}">
                <button type="button" (click)="getListaFascicoliFiltrata()" class="w-100 btn justify-content-center d-flex bg-primary-archipro text-white">
                    <i class="icon-search"></i>Cerca
                </button>

                <button type="button" (click)="openFormFascicolo(true, null)" class="w-100 btn justify-content-center d-flex bg-primary-archipro text-white ml-1">
                    <i class="icon-plus"></i>Nuovo
                </button>
            </div>
        </fieldset>
    </div>



    <div *ngIf="listFasc.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">

        <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
            <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
        </diV>
        <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end max-width-340'}}"  *ngIf="numPage > 1" >
            <nav aria-label="Page navigation example">
                <ul class="pagination mb-0">
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                            <span *ngIf="size>=3" >Precedente</span>
                            <i *ngIf="size<3" class="icon-left-open"></i>
                        </div>
                      </li>
                    <li  *ngFor="let page of listObjPage" class="page-item">
                      <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                    </li>
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                            <span *ngIf="size>=3" >Prossimo</span>
                            <i *ngIf="size<3" class="icon-right-open"></i>
                        </div>
                    </li>
                </ul>
              </nav>
        </div>
    </div>


    <!--Lista fascicoli-->


    <div *ngIf="listFasc.length > 0; else nessunRisultato">

        <table class="table table-striped border" *ngIf="size > 2; else mobile">
            <thead>
                <tr class="text-primary-archipro">
                <th class="size-badge-notifica"></th>
                  <th scope="col">
                      <span class="d-flex cursor-pointer">N° / Anno </span>
                  </th>

                  <th scope="col">
                      <span class="d-flex cursor-pointer"><span *ngIf="GLOBALI.PRO_SOGGETTO">Soggetto-</span>Oggetto </span>
                  </th>

                  <th scope="col">
                      <span class="d-flex cursor-pointer">In carico a </span>
                  </th>

                  <th scope="col">Azioni</th>
                </tr>
            </thead>
            <tbody *ngIf="!searchInProgress; else loadingList">

                <tr *ngFor="let fasc of listFasc; let i = index" class="lista-colonne">

                  <td class="size-badge-notifica">
                    <div class="d-flex h-100 justify-content-start align-items-center text-primary-archipro pl-2">

                      <div class="dropdown" *ngIf="GLOBALI.PRO_SOTTOFASCICOLO">
                        <span [style.visibility]="!fasc.fascPadre ? 'hidden' : 'visible'" title="Fascicolo padre" id="sottoFascicoloDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="icon-archive" title="Info fascicolo padre"></i>
                        </span>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sottoFascicoloDropdown">
                            <!--<h6 class="dropdown-header">Il mio profilo</h6>-->
                            <div *ngIf="fasc.fascPadre" class="dropdown-information d-flex flex-column text-primary-archipro">

                                <div class="righe-info">
                                    <b>Numero: </b>{{fasc.fascPadre.numFascVis}} / {{fasc.fascPadre.fascPK.annoFascicolo}}
                                </div>

                                <div *ngIf="GLOBALI.PRO_SOGGETTO && fasc.fascPadre.soggetto" class="righe-info">
                                  <b>Soggetto: </b>{{fasc.fascPadre.soggetto}}
                                </div>

                                <div class="righe-info">
                                   <b>Oggetto: </b>{{fasc.fascPadre.oggetto}}
                                </div>
                            </div>
                        </div>
                      </div>

                      <div  [style.visibility]="!fasc.dataMessaAtti ? 'hidden' : 'visible'" class="composed-icon-pedice" title="Messo agli atti">
                        <i class="icon-folder-close" ></i>
                        <i class="icon-lock-filled pedice" style="font-size: 14px;"></i>
                      </div>
                    </div>
                  </td>

                  <td class="numero-column">
                    <span>{{fasc.numFascVis}} / {{fasc.fascPK.annoFascicolo}}</span>
                  </td>

                  <td scope="row" class="oggetto-column">
                      <div class="d-flex flex-column">
                          <div class="cut-text" title="{{fasc.soggetto}}" class="cut-text" *ngIf="GLOBALI.PRO_SOGGETTO">{{fasc.soggetto}}</div>
                          <div class="cut-text" title="{{fasc.oggetto}}" class="cut-text">{{fasc.oggetto}} </div>
                      </div>
                  </td>

                  <td scope="row" class="incaricoa-column">
                      <div class="d-flex flex-column">
                          <div class="cut-text" >{{fasc.codRice.descrizione}} ({{fasc.codRice.codice}})</div>
                          <div class="cut-text" *ngIf="fasc.codRice.uo != null">{{fasc.codRice.uo.descrizione}} ({{fasc.codRice.uo.codice}})</div>
                      </div>
                  </td>

                  <td class="action-icon d-flex align-items-center numero-column">

                      <div *ngIf="modalMode && riportaFascicolo != undefined && !titolarioSelezionato.bloccato && !fasc.dataMessaAtti && ((GLOBALI.PRO_SOTTOFASCICOLO && !fasc.fascPadre) || !(GLOBALI.PRO_SOTTOFASCICOLO) || forcedRiporta)" class="container-icon" title="Riporta su form" (click)="riportaFascicoloLocal(fasc)">
                          <i class="icon-export horizontal-speculate cursor-pointer"></i>
                      </div>

                      <div class="container-icon">
                        <i class="icon-folder-open-empty cursor-pointer" title="Apri" (click)="apriListaDocumentiChild(fasc)"></i>
                      </div>

                      <ng-container *ngIf="!fasc.dataMessaAtti">
                        <div class="container-icon" *appPermission="'modificaFascicolo'">
                          <i class="icon-edit cursor-pointer" title="Modifica" (click)="openFormFascicolo(false, fasc)"></i>
                        </div>


                        <div class="dropdown d-flex align-items-center justify-content-center h-100">
                          <span [attr.id]="'action-dropdown-' + 'nav-fascicoli' + i" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="icon-ellipsis-vert" title="Azioni"></i>
                          </span>

                          <div class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="'action-dropdown-' + 'nav-fascicoli' + i">
                              <h6 class="dropdown-header">Azioni</h6>

                              <div class="dropdown-divider"></div>


                              <a (click)="apriMovimentiFascicolo(fasc)" class="dropdown-item cursor-pointer">
                                <i class="icon-switch text-primary-archipro"></i>
                                <span class="ml-1">Movimenti</span>
                              </a>

                              <a *ngIf="!modalMode" (click)="trasmettiFascicolo(fasc)" class="dropdown-item cursor-pointer">
                                <i class="icon-export text-primary-archipro"></i>
                                <span class="ml-1">Trasmetti fascicolo</span>
                              </a>

                              <a *ngIf="!fasc.dataMessaAtti && !modalMode && hasCompetenza(fasc)" (click)="trasmettiAgliAtti(fasc)" class="d-flex dropdown-item cursor-pointer">
                                <div class="composed-icon-pedice cursor-pointer text-primary-archipro">
                                  <i class="icon-folder-close" title="Collega fascicoli"></i>
                                  <i class="icon-export pedice" style="font-size: 12px;bottom: -2px"></i>
                                </div>
                                <span class="ml-1">Trasmetti agli atti</span>
                              </a>

                              <a *ngIf="GLOBALI.PRO_SOTTOFASCICOLO && !fasc.fascPadre" (click)="openFormFascicolo(true, fasc)" class="d-flex dropdown-item cursor-pointer">
                                <div class="composed-icon-pedice cursor-pointer text-primary-archipro">
                                  <i class="icon-folder-close" title="Collega fascicoli"></i>
                                  <i class="icon-plus pedice" style="font-size: 12px;bottom: -2px"></i>
                                </div>
                                <span class="ml-1">Crea sottofascicolo</span>
                              </a>


                          </div>
                        </div>
                      </ng-container>

                  </td>
                </tr>
            </tbody>
            <ng-template #loadingList>
                <tbody>
                    <tr>
                        <td colspan="100%">
                            <app-loading class="w-100 h-100 d-flex justify-content-center align-items-center"></app-loading>
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>

    <ng-template #nessunRisultato>
        <div class="h-100 w-100 d-flex justify-content-center align-items-center" *ngIf="!searchInProgress; else loading">
            <h2>Non è stato trovato nessun risultato con i filtri selezionati</h2>
        </div>
    </ng-template>

    <ng-template #loading>
        <app-loading></app-loading>
    </ng-template>



    <ng-template #mobile>
        <div id="accordion" class="mt-3" *ngIf="!searchInProgress; else loading">
            <div class="card card-list-mobile" *ngFor="let fasc of listFasc; let i = index">
              <div class="card-header" [attr.id]="cleanIdNumAnnoProt(fasc.fascPK.numFascicolo + '/' + fasc.fascPK.annoFascicolo) + 'heading'">
                <div class="mb-0 d-flex">
                    <div class="position-relative">

                    </div>
                  <button class="btn w-100 p-0" data-toggle="collapse" [attr.data-target]="'#' + cleanIdNumAnnoProt(fasc.fascPK.numFascicolo + '/' + fasc.fascPK.annoFascicolo)" aria-expanded="true" [attr.aria-controls]="fasc.fascPK.numFascicolo + '/' + fasc.fascPK.annoFascicolo">
                        <div class="d-flex w-100 justify-content-between">
                            <h6 class="mt-1 d-flex align-items-center">
                                {{fasc.numFascVis + '/' + fasc.fascPK.annoFascicolo}}
                            </h6>
                            <div class="d-flex align-items-center">
                              <div  [style.visibility]="!fasc.dataMessaAtti ? 'hidden' : 'visible'" class="composed-icon-pedice text-primary-archipro" title="Messo agli atti">
                                <i class="icon-folder-close" ></i>
                                <i class="icon-lock-filled pedice" style="font-size: 14px;"></i>
                              </div>
                              <div class="dropdown" *ngIf="GLOBALI.PRO_SOTTOFASCICOLO">
                                <span [style.visibility]="!fasc.fascPadre ? 'hidden' : 'visible'" title="Fascicolo padre" id="sottoFascicoloDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="icon-archive text-primary-archipro" title="Info fascicolo padre"></i>
                                </span>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sottoFascicoloDropdown">
                                    <!--<h6 class="dropdown-header">Il mio profilo</h6>-->
                                    <div *ngIf="fasc.fascPadre" class="dropdown-information d-flex flex-column text-primary-archipro">

                                        <div class="righe-info">
                                            <b>Numero: </b>{{fasc.fascPadre.numFascVis}} / {{fasc.fascPadre.fascPK.annoFascicolo}}
                                        </div>

                                        <div *ngIf="GLOBALI.PRO_SOGGETTO && fasc.fascPadre.soggetto" class="righe-info">
                                          <b>Soggetto: </b>{{fasc.fascPadre.soggetto}}
                                        </div>

                                        <div class="righe-info">
                                           <b>Oggetto: </b>{{fasc.fascPadre.oggetto}}
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column mb-1 text-left">
                            <small class="cut-text" *ngIf="GLOBALI.PRO_SOGGETTO">
                                {{fasc.soggetto}}
                            </small>
                            <span class="oggetto-mobile cut-text">
                                {{fasc.oggetto}}
                            </span>

                        </div>
                        <div class="d-flex text-muted cut-text ">
                            <small class="agd-uo-list-mobile w-100 d-flex justify-content-between">
                                <span>{{fasc.codRice.codice}} - {{fasc.codRice.uo.codice}}</span>
                            </small>
                        </div>

                  </button>
                  <div class="position-relative">
                    <div class="dropdown d-flex align-items-center justify-content-center h-100 position-absolute" style="right: -16px;">
                        <span [attr.id]="'action-dropdown-' + cleanIdNumAnnoProt(fasc.fascPK.numFascicolo + '/' + fasc.fascPK.annoFascicolo)" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="icon-ellipsis-vert" title="Azioni"></i>
                        </span>

                        <div class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="'action-dropdown-' + cleanIdNumAnnoProt(fasc.fascPK.numFascicolo + '/' + fasc.fascPK.annoFascicolo)">
                            <h6 class="dropdown-header">Azioni</h6>

                            <div class="dropdown-divider"></div>

                            <a class="dropdown-item cursor-pointer" (click)="apriListaDocumentiChild(fasc)">
                                <i class="icon-folder-open-empty"></i>
                                <span class="ml-1">Apri fascicolo</span>
                            </a>

                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div [attr.id]="cleanIdNumAnnoProt(fasc.fascPK.numFascicolo + '/' + fasc.fascPK.annoFascicolo)" class="collapse {{i == 0 ? 'show' : ''}}" [attr.aria-labelledby]="cleanIdNumAnnoProt(fasc.fascPK.numFascicolo + '/' + fasc.fascPK.annoFascicolo) + 'heading'" data-parent="#accordion">
                <div class="card-body">

                    <div class="d-flex flex-column" style="font-size: 14px;">
                        <div class="d-flex column-element-mobile">
                            <span class="w-25 font-weight-bold h-100 name-column-mobile">Numero :</span>
                            <span class="w-100 text-left h-100">{{fasc.fascPK.numFascicolo + '/' + fasc.fascPK.annoFascicolo}}</span>
                        </div>

                        <div class="d-flex column-element-mobile" *ngIf="GLOBALI.PRO_SOGGETTO">
                            <span class="w-25 font-weight-bold h-100 name-column-mobile">Soggetto :</span>
                            <span class="w-100 text-left h-100">{{fasc.soggetto}}</span>
                        </div>

                        <div class="d-flex column-element-mobile">
                            <span class="w-25 font-weight-bold h-100 name-column-mobile">Oggetto :</span>
                            <span class="w-100 text-left h-100">{{fasc.oggetto}}</span>
                        </div>

                        <div class="d-flex column-element-mobile">
                            <span class="w-25 font-weight-bold h-100 name-column-mobile">In carico a :</span>
                            <span class="w-100 text-left h-100">{{fasc.codRice.codice}} - {{fasc.codRice.uo.codice}} <span *ngIf="fasc.codRice.uo != null">{{fasc.codRice.uo.descrizione}} ({{fasc.codRice.uo.codice}})</span></span>
                        </div>

                        <div class="d-flex column-element-mobile" *ngIf="fasc.fascPadre">
                          <span class="w-25 font-weight-bold h-100 name-column-mobile">Fascicolo padre :</span>
                          <span class="w-100 text-left h-100">
                            {{fasc.fascPadre.numFascVis}} / {{fasc.fascPadre.fascPK.annoFascicolo}}
                            <ng-container *ngIf="GLOBALI.PRO_SOGGETTO"> - {{fasc.fascPadre.soggetto}} </ng-container>
                            - {{fasc.fascPadre.oggetto}}
                          </span>
                      </div>

                </div>
              </div>
            </div>
          </div>
        </div>
    </ng-template>





    <div *ngIf="listFasc.length > 0;" class=" w-100 d-flex justify-content-end mt-2 mb-2" [ngClass]="{'justify-content-center flex-column' : size<3}">

        <diV class="mr-2 d-flex w-100 align-items-center justify-content-{{size<3 ? 'center' : 'end'}}" [ngClass]="{'justify-content-center' : size<3}">
            <span class="text-primary-archipro risultat-totali-class">{{elementDa}} - {{elementA}} di {{totRecords}} risultati</span>
        </diV>
        <div class="d-flex align-items-center justify-content-{{size<3 ? 'center' : 'end max-width-340'}}"  *ngIf="numPage > 1" >
            <nav aria-label="Page navigation example">
                <ul class="pagination mb-0">
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == 1}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage - 1)">
                            <span *ngIf="size>=3" >Precedente</span>
                            <i *ngIf="size<3" class="icon-left-open"></i>
                        </div>
                      </li>
                    <li  *ngFor="let page of listObjPage" class="page-item">
                      <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && page.clickable && changePage(page.index)" [ngClass]="{'active_page text-white' : currentPage == page.index}">{{page.value}}</div>
                    </li>
                    <li class="page-item" [ngClass]="{'disabled' : currentPage == numPage}">
                        <div class="page-link text-primary-archipro cursor-pointer" (click)="!searchInProgress && changePage(currentPage + 1)">
                            <span *ngIf="size>=3" >Prossimo</span>
                            <i *ngIf="size<3" class="icon-right-open"></i>
                        </div>
                    </li>
                </ul>
              </nav>
        </div>
    </div>

</section>
<app-modal #dynamicModalComponent ></app-modal>

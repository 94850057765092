<div class="d-flex flex-column">
    <div class="d-flex spaced-input" >
        <div class="form-group w-100">
            <label>Collocazione fisica</label>
            <div class="d-flex input-classificazione spaced-input">
                <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [formControl]="collocazione1">
                <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [formControl]="collocazione2">
                <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [formControl]="collocazione3">
                <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [formControl]="collocazione4">
                <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [formControl]="collocazione5">
                <input type="text" [readonly]="showEditCollocazione" class="form-control text-center" placeholder="" aria-label="" aria-describedby="basic-addon1" maxlength="3" [formControl]="collocazione6">
            </div>
        </div>
        <div class="d-flex pt-2 action-icon-protocol spaced-input w-100">
            <div class="d-flex spaced-input" *ngIf="!disalbeForm;else showPulsantiClassificazione">
                <div class="border container-icon">
                    <i *ngIf="!waitCheckCollocazione && !showEditCollocazione" class="cursor-pointer icon-target" title="Verifica" (click)="checkCollocazione()"></i>
                    <i *ngIf="showEditCollocazione" class="cursor-pointer icon-edit" title="Modifica" (click)="editCollocazione()"></i>
                    <div *ngIf="waitCheckCollocazione" class="loader-mini-spinner"></div>
                </div>
                <div class="border container-icon">
                    <i class="cursor-pointer icon-search" (click)="openNavigazioneCollocazione()" title="Cerca"></i>
                </div>
            </div>
            <ng-template #showPulsantiClassificazione>
                <div class="container-icon empty"></div>
                <div class="container-icon empty"></div>
            </ng-template>

            <div class="container-icon empty"></div>
            <div class="container-icon empty"></div>
            <div class="container-icon empty"></div>
        </div>
        <div class="d-flex w-100"></div>
        <div class="d-flex w-100"></div>
    </div>
    <div class="d-flex" *ngIf="collocazioneSelezionata != null">
        <div class="info-titolario d-flex flex-column card-shadow border p-3 mb-2 bg-white w-100">
            <div class="d-flex" *ngIf="collocazioneSelezionata != null">
                <span class="mr-2">
                    <b>Collocazione: </b>{{collocazioneSelezionata.viewDescription ? collocazioneSelezionata.viewDescription : collocazioneSelezionata.description}}
                </span>
            </div>
        </div>
    </div>
</div>

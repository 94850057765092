export class BreadcrumbObj{
  showElencoDocumenti : boolean = true;
  showFascicoliUniti : boolean = false;
  showDocumento : boolean = false;
  showMovimenti : boolean = false;
  showTrasmetti : boolean = false;
  showModificaFascicolo : boolean = false;

  setShowElencoDocumenti() : void{
    this.showElencoDocumenti = true;
    this.showFascicoliUniti = false;
    this.showDocumento = false;
    this.showMovimenti = false;
    this.showTrasmetti = false;
    this.showModificaFascicolo = false;
  }

  setShowFascicoliUniti() : void{
    this.showElencoDocumenti = false;
    this.showFascicoliUniti = true;
    this.showDocumento = false;
    this.showMovimenti = false;
    this.showTrasmetti = false;
    this.showModificaFascicolo = false;
  }

  setShowDocumento() : void{
    this.showElencoDocumenti = false;
    this.showFascicoliUniti = false;
    this.showDocumento = true;
    this.showMovimenti = false;
    this.showTrasmetti = false;
    this.showModificaFascicolo = false;
  }

  setShowMovimenti() : void{
    this.showElencoDocumenti = false;
    this.showFascicoliUniti = false;
    this.showDocumento = false;
    this.showMovimenti = true;
    this.showTrasmetti = false;
    this.showModificaFascicolo = false;
  }

  setShowTrasmetti() : void{
    this.showElencoDocumenti = false;
    this.showFascicoliUniti = false;
    this.showDocumento = false;
    this.showMovimenti = false;
    this.showTrasmetti = true;
    this.showModificaFascicolo = false;
  }

  setShowModificaFascicolo() : void{
    this.showElencoDocumenti = false;
    this.showFascicoliUniti = false;
    this.showDocumento = false;
    this.showMovimenti = false;
    this.showTrasmetti = false;
    this.showModificaFascicolo = true;
  }
}

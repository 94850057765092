import { Component, Input, OnInit, HostListener } from '@angular/core';
import { RequestService } from './../../../../service/requestService/request.service';
import { getLabelAgd, getLabelUo } from './../../../../Utility/Utility';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { DettaglioDocumentoRequest } from './../../../../model/DettaglioDocumentoRequest';
import { ResizeService } from './../../../../service/resize/resize.service';

@Component({
  selector: 'app-elenco-movimenti',
  templateUrl: './elenco-movimenti.component.html',
  styleUrls: ['./elenco-movimenti.component.scss']
})
export class ElencoMovimentiComponent implements OnInit {

  getLabelAgd : () => string = getLabelAgd;
  getLabelUo : () => string = getLabelUo;

  size : SCREEN_SIZE;

  @Input()
  objParameterComponent : any;

  @Input()
  dettaglioDocumentoRequest : DettaglioDocumentoRequest;

  @Input()
  fascicolo : any;

  @Input() fascicoliMode : boolean = false;

  docNumString : string = "";

  tipoElencoMovimenti : string = "competenza";

  tempTipoElencoMovimenti : string = this.tipoElencoMovimenti;

  searchInProgress : boolean = false;

  typeKeyString : string = "docMovPK";

  /**
   * variabile utilizzata per nominare le colonne della tabella a seconda del tipo di movimenti che si vuole visualizzare
   *
   * - competenza;
   * - conoscenza;
   */
  theadNameColumn : any = {
    competenza : ["Operazione", "Data", "Ora", "Da", "A", "Motivo", "Eseguito da"],
    conoscenza : ["Dal", "Al", getLabelAgd()+"-"+getLabelUo(), "Eseguito da"]
  }

  mapNameController : any = {};

  mapNameDocumento : any = {
    competenza : "movimentiCompetenzaDocumento",
    conoscenza : "movimentiConoscenzaDocumento"
  }

  mapNameFascicolo : any = {
    competenza : "movimentiCompetenzaFascicolo",
    conoscenza : "movimentiConoscenzaFascicolo"
  }

  listaMovimenti : any = [];

  constructor(private requestService : RequestService, private resizeSvc: ResizeService) {
    this.size = this.resizeSvc.onResize();
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  ngOnInit(): void {
    if(this.objParameterComponent != null)
    {
      if(this.objParameterComponent.fascicoloMode){
        this.fascicoliMode = true;
        this.typeKeyString = "fascMovPK"
        this.fascicolo = this.objParameterComponent.fascicolo;
      }
      else
      {
        this.dettaglioDocumentoRequest = this.objParameterComponent.dettaglioDocumentoRequest;
        this.docNumString = this.objParameterComponent.docNumString;
      }
    }

    if(this.fascicoliMode){
      this.typeKeyString = "fascMovPK"
    }

    this.mapNameController = this.fascicoliMode ? this.mapNameFascicolo : this.mapNameDocumento

    this.getListaMovimenti();
  }

  getListaMovimenti()
  {

    let payload = this.fascicoliMode ? this.fascicolo : this.dettaglioDocumentoRequest;

    this.searchInProgress = true;
    this.tipoElencoMovimenti = this.tempTipoElencoMovimenti;
    this.requestService.postRequest(this.mapNameController[this.tipoElencoMovimenti], payload,
      (response) => {
        this.listaMovimenti = [];
        let temp = response.obj.list;


        if(this.tipoElencoMovimenti == "conoscenza")
        {
          this.listaMovimenti = temp;
        }
        else
        {

          temp.forEach(element => {
            if(!this.listaMovimenti.find(elementToFind => this.compareMovimenti(element, elementToFind)))
            {
              this.listaMovimenti.push(element)
            }
          });

          this.listaMovimenti.map(element => {
            element.listaDestinatari = [{desRice : element.desRice, codRice : element.codRice, desRiceUO : element.desRiceUO, codRiceUO : element.codRiceUO}];

            let a = temp.filter(tempElement => this.compareMovimenti(element, tempElement) && !(element.codRice == tempElement.codRice && element.codRiceUO == tempElement.codRiceUO))
                a.forEach(tempElement => {
                  element.listaDestinatari.push({desRice : tempElement.desRice, codRice : tempElement.codRice, desRiceUO : tempElement.desRiceUO, codRiceUO : tempElement.codRiceUO})
                });
          });
        }

        this.searchInProgress = false;
      },
      () => {
        this.searchInProgress = false;
        alert("qualcosa non va");
      }//dataMov, oraMov, codOper
    );
  }



  compareMovimenti = (element, listElement)  => {
    return listElement.codOper == element.codOper
      && listElement[this.typeKeyString].dataMov == element[this.typeKeyString].dataMov
      && listElement[this.typeKeyString].oraMov == element[this.typeKeyString].oraMov;
  }

}

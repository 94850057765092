import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ControlValueAccessor } from '@angular/forms';
import { checkIfRequired } from 'src/app/Utility/UtilityForm';

import {NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'app-textinput',
  templateUrl: './textinput.component.html',
  styleUrls: ['./textinput.component.scss']
})
export class TextinputComponent implements OnInit {

  @Input() textErroreView : string = "Campo obbligatorio";
  @Input() labelTextarea : string = "";
  @Input() type : string = "text";
  @Input() justChecked : boolean = true;
  @Input() labelInPlaceHolder : boolean = false;
  @Input() formField : FormControl;
  @Input() disabled : boolean = false;
  @Input() maxlength : number = 4000;
  @Input() labelInfoDx : string;
  @Input() smallInput : boolean = false;

  @Input() modelcustom : any = {};
  @Output() modelcustomChange : EventEmitter<any> = new EventEmitter<any>();

  requiredSymbol : boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.realoadValidation();
  }

  /**
   * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
   *
   * field : nome del campo da verificare
   *
   * @returns il valore true se il campo non è valido
   */
  checkControlName() : boolean{
    if(this.formField)
      return this.justChecked && !this.formField.valid;
    else
      return false
  }

  realoadValidation(){
    this.requiredSymbol = !!this.formField && checkIfRequired(this.formField);
  }

}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AlberoCollocazioneComponent } from 'src/app/component/moduli/ricerche/albero-collocazione/albero-collocazione.component';
import { Collocazione } from 'src/app/model/Collocazione';
import { RequestService } from 'src/app/service/requestService/request.service';
import { ModalComponent } from '../../modal/modal.component';
import { OptionModal } from '../../modal/OptionModal';

@Component({
  selector: 'app-collocazione-fisica',
  templateUrl: './collocazione-fisica.component.html',
  styleUrls: ['./collocazione-fisica.component.scss']
})
export class CollocazioneFisicaComponent implements OnInit {

  @Input() collocazione1 : FormControl;
  @Input() collocazione2 : FormControl;
  @Input() collocazione3 : FormControl;
  @Input() collocazione4 : FormControl;
  @Input() collocazione5 : FormControl;
  @Input() collocazione6 : FormControl;
  @Input() disalbeForm : boolean = false;
  @Input() justChecked : boolean = true;
  @Input() dynamicModal: ModalComponent;
  @Input() optionModal: OptionModal;

  @Output() checkedCollocazione : EventEmitter<boolean> = new EventEmitter<boolean>();

  showEditCollocazione : boolean = false;
  waitCheckCollocazione : boolean = false;
  collocazioneSelezionata : any = null;

  objParameterComponent : any;


  constructor(private requestService : RequestService) { }

  ngOnInit(): void {
  }

  /**
   * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
   *
   * field : nome del campo da verificare
   *
   * @returns il valore true se il campo non è valido
   */
   checkControlName(field) : boolean{
    return this.justChecked && !this[field].valid;
  }

  checkCollocazione() : void{
    this.waitCheckCollocazione = true;
    let collocazione : Collocazione = new Collocazione();
    this.collocazioneSelezionata = null;


    //1 elemento come risposta
    let valid : boolean = true;
    for(let i = 1; i <= 6; i++)
    {
      this["collocazione"+i].setValue((this["collocazione"+i].value == "" || this["collocazione"+i].value == undefined || this["collocazione"+i].value == null) ? 0 : this["collocazione"+i].value);
      valid = valid && !this.checkControlName("collocazione"+i);
      collocazione["col"+i] = this["collocazione"+i].value;
    }


    if(valid)
    {

      this.requestService.postRequest("searchCollocazione", collocazione,
        (response) => {
          let listCollocazione = response.obj.list;
          if(listCollocazione.length == 0)
          {
            this.optionModal = new OptionModal();
            this.optionModal.titolo = "Verifica Collocazione";
            this.optionModal.textBodyModal = "Non è stato trovato alcun valore con la collocazione inserita";

            this.optionModal.showSaveButton = false;
            this.optionModal.showCloseButton = true;
            this.optionModal.setClassSizeMaxHeightModalBody("m");

            this.dynamicModal.initModal(this.optionModal);
            this.dynamicModal.openModal();
            this.waitCheckCollocazione = false;
          }
          else if (listCollocazione.length == 1 && listCollocazione[0].terminale)
          {
            let collocazione = listCollocazione[0];
            this.setTitolarioFromNavigazioneObj(collocazione, "collocazione");
            this.waitCheckCollocazione = false;
            this.showEditCollocazione = true;

            this.checkedCollocazione.emit(true)

            this.collocazioneSelezionata = collocazione;
          }
          else
          {
            this.waitCheckCollocazione = false;
            this.openNavigazioneCollocazione();
          }

        },
        () => {
          this.waitCheckCollocazione = false;
        }
      );

    }
  }

  setTitolarioFromNavigazioneObj(object : any, type : string){
    let titolarioValue = object.valore.split(".");
    for(let i = 0; i < 6; i ++)
    {
      this[type + (i+1)].setValue(i < titolarioValue.length ? titolarioValue[i] : 0);
    }
  }

  openNavigazioneCollocazione(): void{

    let collocazioneFromForm : Collocazione = null;
    if(this.collocazione1.value != "")
    {
      collocazioneFromForm = new Collocazione();
      for(let i = 1; i <= 6; i++)
      {
        collocazioneFromForm["col"+i] = this["collocazione"+i].value;
      }
    }

    this.objParameterComponent = {
      modalMode : true,
      riportaCollocazione : this.riportaCollocazione, //Funzione di riporto per collocazione
      collocazioneFromForm : collocazioneFromForm
    }

    this.optionModal = new OptionModal();
    this.optionModal.titolo = "Collocazione fisica";


    this.optionModal.setSizeModal("90");
    this.optionModal.maxSizeHeightModal = true;
    this.optionModal.showSaveButton = false;

    this.optionModal.objParameterComponent = this.objParameterComponent;

    //Opzioni per settare il component da caricare dinamicamente
    //******************************************************* */
    this.optionModal.setLoadDynamicComponent(true);
    this.optionModal.dynamicComponnet = AlberoCollocazioneComponent;

    this.dynamicModal.initModal(this.optionModal);
    this.dynamicModal.openModal();

  }

  /**
   * Funzione passata alla modale dinamica della navigazione per permettere di riportare indietro e settare i campi riguardanti il titolario
   *
   * @param titolario : elemento selezionato e da riportare
   */
   riportaCollocazione = (collocazione : any) : void =>{

    this.setTitolarioFromNavigazioneObj(collocazione, "collocazione");

    //Set altre info da titolario
    this.collocazioneSelezionata = collocazione;
    this.checkedCollocazione.emit(true);

    this.showEditCollocazione = true;

    this.dynamicModal.closeModal();
  }

  editCollocazione() : void{
    this.showEditCollocazione = false;
    this.collocazioneSelezionata = null;
    this.checkedCollocazione.emit(false);
  }

}

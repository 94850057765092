<section class="h-100 w-100 d-flex flex-column">
  <!--Infomazioni sul titolario scelto nella precedente pagina-->
  <section class="info-titolario d-flex mt-3 mb-3 card-shadow border p-3 flex-column">
    <span class="mr-2">
        <b>Numero: </b>
        {{documentoInfo.docNumString}}
    </span>

    <span class="mr-2">
      <b>Oggetto: </b>
      {{documentoInfo.oggetto}}
  </span>

  </section>


  <div *ngIf="!searchInProgress; else loading"  class="w-100 d-flex flex-column h-100">

      <div *ngIf="size > 2; else mobile" class="w-100 h-100">
          <table *ngIf="listaOperazioni.length > 0; else nessunRisultato" class="table table-striped border">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ora</th>
                  <th>Autore</th>
                  <th>Destinatario</th>
                  <th>Stato</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let operazione of listaOperazioni">



                      <td>
                        {{operazione.data | dataStringTransform}}
                      </td>

                      <td>
                        {{operazione.ora}}
                      </td>

                      <td>
                        {{operazione.desTras}} <ng-container *ngIf="operazione.codTrasUO"> - {{operazione.desTrasUO}} </ng-container>
                      </td>

                      <td>
                        <div class="d-flex flex-column">
                          <span *ngFor="let destinatario of operazione.listaDestinatari">
                            {{destinatario.desRice}}  <ng-container *ngIf="destinatario.codRiceUO != -1">{{destinatario.desRiceUO}}</ng-container>
                          </span>
                        </div>
                      </td>

                      <td>
                        {{operazione.codOper}}
                      </td>

                      <td>
                        {{operazione.motivo}}
                      </td>

                  </tr>
              </tbody>

          </table>
      </div>
      <ng-template #mobile>

          <div id="accordionMovimenti">

              <div class="card" *ngFor="let operazione of listaOperazioni;  let i = index">
                <div class="card-header" [attr.id]="'movimento' + i + 'heading'">
                  <h5 class="mb-0">
                    <button class="btn w-100 p-0 d-flex flex-column" data-toggle="collapse" [attr.data-target]="'#collapse' + i + 'movimento'" aria-expanded="true" [attr.aria-controls]="'collapse' + i + 'movimento'">
                      <div class="d-flex w-100 justify-content-between align-items-center">
                          <h6 class="mt-1 d-flex align-items-center">{{operazione.codOper}}</h6>
                          <small class=""> {{operazione.data | dataStringTransform}} {{operazione.ora}}</small>
                      </div>
                      <small >{{operazione.desTras}} ({{operazione.codTras}}) <ng-container *ngIf="operazione.codTrasUO"> - {{operazione.desTrasUO}} ({{operazione.codTrasUO}})</ng-container></small>
                    </button>


                  </h5>
                </div>

                <div [attr.id]="'collapse' + i + 'movimento'" class="collapse {{i == 0 ? 'show' : ''}}" [attr.aria-labelledby]="'movimento' + i + 'heading'" data-parent="#accordionMovimenti">
                  <div class="card-body">
                      <div class="d-flex flex-column" style="font-size: 14px;">
                          <div class="d-flex column-element-mobile">
                              <span class="w-25 font-weight-bold h-100 name-column-mobile">Data :</span>
                              <span class="w-100 text-left h-100">{{operazione.data | dataStringTransform}} - {{operazione.ora}}</span>
                          </div>

                          <div class="d-flex column-element-mobile">
                              <span class="w-25 font-weight-bold h-100 name-column-mobile">Autore :</span>
                              <span class="w-100 text-left h-100">{{operazione.desTras}}  <ng-container *ngIf="operazione.codTrasUO"> - {{operazione.desTrasUO}} </ng-container></span>
                          </div>

                          <div class="d-flex column-element-mobile">
                              <span class="w-25 font-weight-bold h-100 name-column-mobile">Destinatario :</span>
                              <span class="w-100 text-left h-100">
                                <div class="d-flex flex-column">
                                  <span *ngFor="let destinatario of operazione.listaDestinatari">
                                    {{destinatario.desRice}}  <ng-container *ngIf="destinatario.codRiceUO != -1">{{destinatario.desRiceUO}} </ng-container>
                                  </span>
                                </div>
                              </span>
                          </div>

                          <div class="d-flex column-element-mobile">
                              <span class="w-25 font-weight-bold h-100 name-column-mobile">A :</span>
                              <div class="d-flex flex-column w-100 text-left h-100">
                                <span *ngFor="let destinatario of operazione.listaDestinatari" >
                                    {{destinatario.desRice}}  {{destinatario.desRiceUO}}
                                </span>
                              </div>
                          </div>

                          <div class="d-flex column-element-mobile">
                              <span class="w-25 font-weight-bold h-100 name-column-mobile">Stato :</span>
                              <span class="w-100 text-left h-100">{{operazione.codOper}}</span>
                          </div>

                          <div class="d-flex column-element-mobile">
                              <span class="w-25 font-weight-bold h-100 name-column-mobile">Note :</span>
                              <span class="w-100 text-left h-100">{{operazione.motivo}}</span>
                          </div>
                      </div>


                  </div>
          </div>
              </div>
            </div>

      </ng-template>



      <ng-template #nessunRisultato >
          <div class="h-100 w-100 d-flex justify-content-center align-items-center" *ngIf="!searchInProgress; else loading">
              <h2>Nessuna operazione da visualizzare</h2>
          </div>
      </ng-template>
  </div>

  <ng-template #loading>
      <app-loading></app-loading>
  </ng-template>


</section>
